import { authorizedApiRequest } from '@/services/ApiService';

export const namespaced = true;

export const state = {
  active: null,
};

export const mutations = {
  setActive(state, payload) {
    state.active = payload;
  },
};

export const actions = {
  fetchActive: (context) => {
    return authorizedApiRequest({
      method: 'GET',
      endpoint: 'api/v2/loggedUser/creditPackage/valid',
    })
      .then((resp) => {
        context.commit('setActive', resp);

        return resp;
      })
      .catch((err) => {
        context.commit('setActive', null);

        throw err;
      });
  },
  cancelSubscription: (context) => {
    return authorizedApiRequest({
      method: 'POST',
      endpoint: 'api/v1/users/disablepackage',
    })
      .then((resp) => {
        context.dispatch('user/fetchProfile', null, { root: true });

        return resp;
      })
      .catch((err) => {
        throw err;
      });
  },
};

export const getters = {
  active: (state) => {
    return state.active;
  },
  currency: (state, getters, rootState, rootGetters) => (currency) => {
    return rootGetters['currencies/currentCurrency'](currency);
  },
  currentlyExhausted: (state, getters) => {
    const currentlyExhausted = getters['documentsPerPeriod'] - state.active?.current_credits;

    return currentlyExhausted || 0;
  },
  currentPackage: (state, getters, rootState, rootGetters) => {
    return rootGetters['subscriptionPackages/currentPackage'](getters['active']?.credit_package_id);
  },
  documentsLeft: (state) => {
    return state.active?.current_credits || 0;
  },
  documentsPerPeriod: (state, getters) => {
    return getters['currentPackage']?.credit || 0;
  },
  hasActiveSubscription: (state) => {
    return state.active !== null;
  },
  price: (state, getters) => {
    const currentPackage = getters['currentPackage'];
    let price = currentPackage?.price;

    if ('undefined' === typeof price || 'undefined' === typeof currentPackage) {
      return null;
    }

    if (price % 10 > 0) {
      price = price.toFixed(0);
    }

    return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${
      getters['currency'](currentPackage?.currency)?.symbol
    }`;
  },
  renewalDate: (state) => {
    return state.active?.expires_at;
  },
};
