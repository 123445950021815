<script setup>
import { computed } from 'vue';
import { hasFeatureFlag } from '@/common/reusable/featureFlagsChecker';

const props = defineProps({
  signIdentity: {
    type: Object,
    required: true,
  },
});

const previewState = computed(() =>
  hasFeatureFlag(props.signIdentity, 'isCompleted')
    ? {
        icon: 'mdi-check-circle',
        color: 'green',
      }
    : {
        icon: 'mdi-close',
        color: 'blue',
      },
);
</script>

<template>
  <v-col class="d-flex col-auto py-1 pr-2">
    <div
      class="d-flex align-center sign-identity-state"
      :class="`sign-identity-state__${previewState.color} sign-identity-state__${previewState.color}--background`"
    >
      <v-icon
        :class="`sign-identity-state__${previewState.color}`"
        class="sign-identity-state__icon align-self-start"
        small
      >
        {{ previewState.icon }}
      </v-icon>

      <span>{{ signIdentity.fullname }}</span>
    </div>
  </v-col>
</template>

<style scoped lang="scss">
.sign-identity-state {
  width: fit-content;
  padding: 4px;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: bold;

  &__icon {
    padding-right: 2px;
  }

  &__blue {
    color: #1367d4 !important;

    &--background {
      background-color: #d1effe;
      border: 1px solid #75c3fc;
    }
  }

  &__green {
    color: #24831d !important;

    &--background {
      background-color: #eafbd9;
      border: 1px solid #ace989;
    }
  }
}
</style>
