import { onMounted, onUnmounted, ref } from 'vue';

export function useKeyboard() {
  const initialHeight = window.innerHeight;
  const isKeyboardOpen = ref(false);

  function updateKeyboardState() {
    isKeyboardOpen.value = window.innerHeight < initialHeight;
  }

  onMounted(() => {
    window.addEventListener('resize', updateKeyboardState);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateKeyboardState);
  });

  return { isKeyboardOpen };
}
