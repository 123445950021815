<template>
  <v-row
    class="fill-height ma-0"
    align="center"
    justify="center"
  >
    <v-progress-circular
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-row>
</template>

<script>
export default {
  name: 'ImageLoader',
};
</script>

<style lang="scss" scoped></style>
