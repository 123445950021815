const routes = [
  {
    path: '/account',
    name: 'account',
    component: () => import('@/features/account/views/Account.vue'),
  },
  {
    path: '/account/signature',
    alias: '/signature',
    name: 'accountSignature',
    component: () => import('@/features/account/views/AccountSignature.vue'),
  },
];

routes.forEach((route) => {
  route.meta = {
    authRequired: true,
    layout: () => import('@/layouts/EmptyLayout.vue'),
  };
});

export default routes;
