<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>ico-N/ico-24-kontakty.purple</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
      id="ico-N/ico-24-kontakty.purple"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Group-6"
        transform="translate(3.000000, 2.000000)"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          d="M0.134724015,19.1538462 C3.2265725,18.5354119 5.04866991,18.0470741 5.60101624,17.6888328 C6.15336257,17.3305915 6.29506076,16.3573908 6.02611083,14.7692308"
          id="Path-25"
        ></path>
        <path
          d="M11.9039548,19.1538462 C14.9958033,18.5354119 16.8179007,18.0470741 17.370247,17.6888328 C17.9225933,17.3305915 18.0642915,16.3573908 17.7953416,14.7692308"
          id="Path-25-Copy"
          transform="translate(14.915703, 16.961538) scale(-1, 1) translate(-14.915703, -16.961538) "
        ></path>
        <path
          d="M7.38461538,15.4615385 C5.95011326,15.0825014 4.90320641,14.0319589 4.24389485,12.3099109 C3.25492751,9.72683882 2.77081762,5.45054223 3.36212327,3.46153846 C3.95342892,1.4725347 6.05976799,-1.48883162e-14 9.09997809,0 C12.1401882,1.3117712e-14 13.7467414,2.08690062 14.5044887,3.46153846 C15.2622359,4.83617631 14.806481,10.3097455 13.5835272,12.6923077 C12.7682247,14.2806825 11.7562045,15.2037594 10.5474667,15.4615385"
          id="Path-32"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoContact',
};
</script>
