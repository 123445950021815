import unauthorizedSignBackground from '@/app/assets/images/unauthorized_sign_background.jpg';
import signiLogo from '@/app/assets/logos/signi_logo.png';
import { hexToRgbaStyle } from '@/common/reusable/colorFunctions';
import { internalApiRequest } from '@/shared/services/apiService';
import { defineStore } from 'pinia';

export const useBrandingStore = defineStore('branding', {
  state: () => ({
    branding: null,
  }),
  actions: {
    fetchBrandingSettings({ authorization, authToken }) {
      if (this.branding) {
        return null;
      }

      return internalApiRequest({
        headers: {
          Authorization: `${authorization} ${authToken}`,
        },
        method: 'GET',
        path: 'api/v1/contract/branding',
      }).then((resp) => {
        this.branding = resp;
      });
    },
  },
  getters: {
    brandingBackground: (state) => {
      return state.branding?.background || unauthorizedSignBackground;
    },
    brandingLayerColor: (state) => {
      return hexToRgbaStyle(state.branding?.layerColor || '#ffffff', 0.9);
    },
    brandingLogo: (state) => {
      return state.branding?.logo || signiLogo;
    },
    brandingPrimaryColor: (state) => {
      return state.branding?.primaryColor || '#5e239e';
    },
    brandingPrimaryTextColor: (state) => {
      return state.branding?.textColor || '#000000';
    },
  },
});
