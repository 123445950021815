import { useStore } from '@/store';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useDocumentStore = defineStore('document', () => {
  const vuexStore = useStore();

  const activePageIndex = ref(0);

  const currentPageNumber = computed(() => activePageIndex.value + 1);
  const numberOfDocumentPages = computed(() => vuexStore.getters['pdfDocument/numberOfPages']);

  function setCurrentPageNumber(pageNumber) {
    activePageIndex.value = pageNumber - 1;
  }

  function reset() {
    activePageIndex.value = 0;
  }

  return {
    activePageIndex,
    currentPageNumber,
    numberOfDocumentPages,
    setCurrentPageNumber,
    reset,
  };
});
