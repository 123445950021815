<template>
  <svg
    width="17px"
    height="10px"
    viewBox="0 0 17 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="SYN010---DSKTP---Připojit---6"
        transform="translate(-162.000000, -258.000000)"
        stroke="#8E97A4"
      >
        <g
          id="arr/arr-16-2/disabled"
          transform="translate(163.000000, 259.000000)"
        >
          <g id="arr/arr-16-2">
            <polyline
              id="Path-2"
              stroke-width="2"
              fill-rule="nonzero"
              points="0 0 7.64648078 7.64648078 15.2929616 0"
            ></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoArrowDownGrey',
};
</script>
