import { defineStore } from 'pinia';

export const useTemplateFieldsStore = defineStore('templateFields', {
  state: () => ({
    completedTemplates: [],
    contractTemplates: [],
    templateFields: [],
    nestedTemplateFields: [],
  }),
  actions: {
    resetHiddenFields(fieldId) {
      for (let field of this.templateFields) {
        if (field.id == fieldId) {
          field.nestedHidden = false;
          field.value = null;
        }
      }
    },
    resetTemplateFieldsStore() {
      this.templateFields = [];
      this.nestedTemplateFields = [];
      this.contractTemplates = [];
      this.completedTemplates = [];
    },
    setCompletedTemplates(contractId) {
      const foundId = this.completedTemplates?.find((templateId) => templateId == contractId);

      if (!foundId) {
        return this.completedTemplates.push(parseInt(contractId));
      }
    },
    setContractTemplates(contractTemplates) {
      this.contractTemplates = contractTemplates;
    },
    setHiddenOptionField(parentId) {
      const nestedFieldToHide = this.nestedTemplateFields?.find((field) => field.parentId == parentId);

      const fieldToHide = this.templateFields?.find((field) => field.id == parentId);

      if (!nestedFieldToHide && !fieldToHide) {
        return;
      }

      if (nestedFieldToHide) {
        (this.templateFields?.find((field) => field?.id == nestedFieldToHide?.childrenId) || []).nestedHidden = true;

        return;
      }

      if (fieldToHide) {
        (this.templateFields?.find((field) => field.id == parentId) || []).nestedHidden = true;

        return;
      }
    },
    setNestedTemplateField(nestedField) {
      if (this.nestedTemplateFields.some((field) => field?.parentId == nestedField?.parentId)) {
        return;
      }

      this.nestedTemplateFields.push(nestedField);
    },
    setTemplateFieldsData(templateFields) {
      this.templateFields = [];
      this.templateFields = [...templateFields?.map((field) => ({ ...field, value: null, nestedHidden: false }))];
    },
    updateTemplateFieldData(templateAnswersFields) {
      this.templateFields?.forEach((templateField) => {
        const currentValue = templateAnswersFields?.find(
          (answerField) => `${answerField.id}` === `${templateField.id}`,
        );

        if (currentValue) {
          templateField.value = currentValue?.value;
        }
      });
    },
  },
  getters: {
    areAllTemplatesCompleted() {
      return this.getCompletedTemplates?.length == this.getAllContractTemplates?.length;
    },
    counterpartyInputsCount: (state) => {
      return state.templateFields.filter((field) => 'counterparty' === field.fillingType)?.length;
    },
    getAllContractTemplates() {
      return this.contractTemplates;
    },
    filledInputs: (state) => {
      return state.templateFields?.filter((field) => field?.value);
    },
    filledInputsCount(state) {
      return state.filledInputs?.length + state.hiddenFieldsCount;
    },
    getCompletedTemplates() {
      return this.completedTemplates;
    },
    getNextUnfinishedTemplate() {
      return this.contractTemplates?.find((template) => !this.completedTemplates?.includes(template?.id))?.id;
    },
    getTemplateFieldsData: (state) => {
      return state.templateFields;
    },
    hiddenFieldsCount: (state) => {
      return state.templateFields?.filter((field) => field?.nestedHidden)?.length || 0;
    },
    isTemplateCompleted(state) {
      if (!this.templateFields.length) {
        return true;
      }

      let filledRequiredFields = state.filledInputs.filter((filledFields) => !filledFields?.optional);

      return (
        state.requiredFieldsCount <= state.filledInputsCount &&
        state.requiredFieldsCount === filledRequiredFields?.length
      );
    },
    requiredFieldsCount: (state) => {
      return state.templateFields.filter((field) => !field?.optional && !field?.nestedHidden)?.length;
    },
  },
});
