export const isLoading = (state) => {
  return state.isLoading;
};

export const isReady = (state) => {
  return !state.isLoading;
};

export const hasError = (state) => {
  return !!state.error;
};

export const error = (state) => {
  return state.error;
};

/**
 * Account profile
 */
export const profile = (state) => {
  return state.profile;
};

export const avatar = (state) => {
  if ('undefined' === typeof state.profile?.avatar_url || !state.profile?.avatar_url) {
    return 'images/user.svg';
  }

  return state.profile.avatar_url;
};

/**
 * Account Signature
 */
export const signature = (state) => {
  return state.signature;
};
