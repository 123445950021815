<template>
  <div class="dashboard-notifications">
    <DashboardNotification
      v-for="notification in dashboardNotifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted } from 'vue';
import { useAppNotificationsStore } from '@/features/dashboard/stores/appNotifications';
import DashboardNotification from '@/features/dashboard/components/DashboardNotification';

export default defineComponent({
  name: 'DashboardNotifications',
  components: {
    DashboardNotification,
  },
  setup() {
    const appNotificationsStore = useAppNotificationsStore();

    const dashboardNotifications = computed(() => appNotificationsStore.notifications);

    onMounted(() => {
      appNotificationsStore.fetchAppNotifications();
    });

    return {
      dashboardNotifications,
    };
  },
});
</script>

<style lang="scss" scoped></style>
