<template>
  <Loader v-if="isLoading" />

  <v-card
    v-else
    class="settings__box pa-0 ma-0"
    elevation="0"
    rounded="lg"
  >
    <v-card-title>
      <h2 class="title font-weight-medium mb-0">
        {{ $t('verification.settings.bank_id') }}
      </h2>
    </v-card-title>

    <v-card-text>
      <v-form class="mx-auto">
        <v-row
          align="center"
          justify="center"
          no-gutters
        >
          <v-col class="col-12">
            <v-text-field
              :value="callbackUrl"
              label="Callback url"
              outlined
              readonly
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row
          align="center"
          justify="center"
          no-gutters
        >
          <v-col class="col-12">
            <v-text-field
              v-model="workspaceBankIdSettings.clientId"
              label="Client id"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row
          align="center"
          justify="center"
          no-gutters
        >
          <v-col class="col-12">
            <v-text-field
              v-model="workspaceBankIdSettings.clientSecret"
              label="Client secret"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-col class="col-12 px-0 text-center">
          <v-row
            align="center"
            :justify="hasCustomBankId ? 'space-between' : 'center'"
            no-gutters
          >
            <v-col cols="auto">
              <v-btn
                color="primary"
                :loading="isSending"
                :disabled="!isFilledForm"
                x-large
                min-width="14rem"
                @click="onSaveBankIdSettings"
              >
                {{ $t('general.save') }}
              </v-btn>
            </v-col>

            <v-col
              v-if="hasCustomBankId"
              cols="auto"
            >
              <v-btn
                x-large
                min-width="14rem"
                color="error"
                :loading="isDeleting"
                outlined
                @click="onDeleteBankIdSettings"
                >{{ $t('general.delete') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { getErrorMessage } from '@/common/reusable/errorResponse.js';
import { environment } from '@/config/environment';
import Loader from '@/components/Loader';

export default {
  name: 'BankIdSettings',
  components: {
    Loader,
  },
  data() {
    return {
      loaded: false,
      defaultWorkspaceBankIdSettings: {},
      workspaceBankIdSettings: {},
      submitted: false,
      submittedDelete: false,
    };
  },
  computed: {
    isLoading() {
      return !this.loaded;
    },
    isSending() {
      return this.submitted;
    },
    isDeleting() {
      return this.submittedDelete;
    },
    isFilledForm() {
      return this.workspaceBankIdSettings.clientId !== '' && this.workspaceBankIdSettings.clientSecret !== '';
    },
    callbackUrl() {
      return `${environment.getApiUrl()}/api/public/v1/bankId/callback`;
    },
    hasCustomBankId() {
      return (
        Object.keys(this.defaultWorkspaceBankIdSettings).filter(
          (key) => this.defaultWorkspaceBankIdSettings[key] !== '',
        )?.length > 0
      );
    },
  },
  mounted() {
    this.fetchBankIdSettings(this.$route.params.workspace_id)
      .then((resp) => {
        this.updateWorkspaceSettings({
          clientId: resp.client_id,
          clientSecret: resp.client_secret,
        });
      })
      .catch(() => {
        this.workspaceBankIdSettings = {
          clientId: '',
          clientSecret: '',
        };
      })
      .finally(() => {
        this.loaded = true;
      });
  },
  methods: {
    ...mapActions({
      fetchBankIdSettings: 'workspace/fetchBankIdSettings',
      setBankIdSettings: 'workspace/setBankIdSettings',
      deleteBankIdSettings: 'workspace/deleteBankIdSettings',
    }),
    updateWorkspaceSettings(value) {
      this.defaultWorkspaceBankIdSettings = {
        clientId: value.clientId,
        clientSecret: value.clientSecret,
      };
      this.workspaceBankIdSettings = Object.assign({}, this.defaultWorkspaceBankIdSettings);
    },
    onSaveBankIdSettings() {
      this.submittedBankIdSettings = true;

      this.setBankIdSettings({
        workspaceId: this.$route.params.workspace_id,
        workspaceBankIdSettings: {
          clientId: this.workspaceBankIdSettings.clientId,
          clientSecret: this.workspaceBankIdSettings.clientSecret,
        },
      })
        .then((resp) => {
          this.updateWorkspaceSettings({
            clientId: resp.client_id,
            clientSecret: resp.client_secret,
          });

          this.$notification.success(this.$t('account.settings.saved'));
        })
        .catch((err) => {
          this.$notification.error(
            getErrorMessage({
              errorCode: err.errorCode,
              errorValues: err.errorValues,
            }),
          );
        })
        .finally(() => {
          this.submittedBankIdSettings = false;
        });
    },
    onDeleteBankIdSettings() {
      this.submittedDelete = true;

      this.deleteBankIdSettings(this.$route.params.workspace_id)
        .then(() => {
          this.updateWorkspaceSettings({
            clientId: '',
            clientSecret: '',
          });

          this.$notification.success(this.$t('account.settings.saved'));
        })
        .catch((err) => {
          this.$notification.error(
            getErrorMessage({
              errorCode: err.errorCode,
              errorValues: err.errorValues,
            }),
          );
        })
        .finally(() => {
          this.submittedDelete = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings__box {
  border: 1px solid #d4dff0;
}
</style>
