<template>
  <div class="loader-wrap">
    <div class="loader"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Loader',
});
</script>

<style lang="scss">
.loader {
  width: 100px;
  height: 100px;
  background: url(@/assets/images/loader.svg) no-repeat center;
  background-size: contain;
  animation: spin 4s linear infinite;
  left: 50%;
  margin-left: -50px;
  position: relative;
}

.loader-wrap {
  position: absolute;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  left: 50%;

  transform: translateX(-50%) translateY(-50%);
}

.menuOpen .loader-wrap {
  padding-left: 325px;
}

.register-loader {
  top: 0;
  position: relative;
  left: 0;
  transform: unset;
}
</style>
