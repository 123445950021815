<script setup>
import { useStore } from '@/store';
import { computed } from 'vue';
import { useDocumentStore } from '@/app/features/document/store/document';
import { storeToRefs } from 'pinia';

const vuexStore = useStore();
const documentStore = useDocumentStore();
const { currentPageNumber, numberOfDocumentPages } = storeToRefs(documentStore);
const { setCurrentPageNumber } = documentStore;

const isLoading = computed(() => !vuexStore.getters['pdfDocument/document']);
const isDisabledDecrease = computed(() => 1 === currentPageNumber.value || isLoading.value);
const isDisabledIncrease = computed(() => currentPageNumber.value === numberOfDocumentPages.value || isLoading.value);

const currentShowingPage = computed({
  get: () => currentPageNumber.value,
  set: (value) => {
    setCurrentPageNumber(Math.max(1, Math.min(value, numberOfDocumentPages.value)));
  },
});

function activatePage(page) {
  setCurrentPageNumber(page);
}
</script>

<template>
  <div class="document-pages-pagination">
    <v-btn
      icon
      large
      outlined
      color="#95AFDA"
      :disabled="isDisabledDecrease"
      @click="activatePage(1)"
    >
      1
    </v-btn>

    <v-btn
      icon
      large
      outlined
      color="#95AFDA"
      :disabled="isDisabledDecrease"
      @click="activatePage(currentPageNumber - 1)"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>

    <div class="document-pages-pagination__page-preview">
      <div class="page-preview__icon">
        <v-icon color="#95AFDA">mdi-file-document-outline</v-icon>
      </div>

      <p class="page-preview__page-number caption">
        <input
          v-model="currentShowingPage"
          type="number"
          min="1"
          :max="numberOfDocumentPages"
        />
      </p>
    </div>

    <v-btn
      icon
      large
      outlined
      color="#95AFDA"
      :disabled="isDisabledIncrease"
      @click="activatePage(currentPageNumber + 1)"
    >
      <v-icon>mdi-chevron-down</v-icon>
    </v-btn>

    <v-btn
      icon
      large
      outlined
      color="#95AFDA"
      :disabled="isDisabledIncrease"
      @click="activatePage(numberOfDocumentPages)"
    >
      {{ numberOfDocumentPages }}
    </v-btn>
  </div>
</template>

<style scoped lang="scss">
.document-pages-pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 4rem 0;
  position: sticky;
  top: 0;

  &__page-preview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 3.5rem;
    width: 100%;
    margin: 0 auto;
    padding: 0.5rem 0;
    overflow: hidden;
    background: white;
    border: 1px solid #95afda;
    border-radius: 0.5rem;

    p {
      margin: 0;
    }
  }
}

.page-preview__icon {
  padding: 0.5rem 0;
}

.page-preview__page-number {
  width: 100%;
  color: #95afda;
  text-align: center;

  input {
    width: 80%;
    text-align: center;
    border: none;
    background: none;
    color: #95afda;
    font-weight: bolder;
  }
}
</style>
