/**
 * @param {array} progress
 * @returns {number} number of progress non ignore points
 */
export const getSignatureProgressLength = (progress) => {
  let signaturePoints = [];

  for (let x in progress) {
    signaturePoints = [].concat(
      signaturePoints,
      progress[x]?.points?.filter((rootPoint, rootIndex) =>
        progress[x]?.points?.some(
          (childPoint, childIndex) =>
            parseInt(childPoint.x) !== parseInt(rootPoint.x) &&
            parseInt(childPoint.y) !== parseInt(rootPoint.y) &&
            childIndex !== rootIndex,
        ),
      ),
    );
  }

  return signaturePoints.length;
};
