import { internalApiRequest } from '@/shared/services/apiService';

export const BulkOperationType = Object.freeze({
  MOVE: 'move',
  DELETE: 'delete',
  DELETE_PDF: 'delete_pdf',
  PROLONG_EXPIRATION: 'prolong_expiration',
  SIGN: 'sign',
});

/**
 * Perform move operation on documents
 * @param {number} workspaceId
 * @param {number[]} docsIds
 * @param {number} folderId
 */
export function performBulkDocumentsOperationMove(workspaceId, docsIds, folderId) {
  return performBulkDocumentsOperation({
    operation: BulkOperationType.MOVE,
    workspaceId,
    docsIds,
    options: {
      folderId,
    },
  });
}

/**
 * Perform sign operation on documents
 * @param {number} workspaceId
 * @param {number[]} docsIds
 */
export function performBulkDocumentsOperationSign(workspaceId, docsIds) {
  return performBulkDocumentsOperation({
    operation: BulkOperationType.SIGN,
    workspaceId,
    docsIds,
  });
}

/**
 * Perform delete operation on documents
 * @param {number} workspaceId
 * @param {number[]} docsIds
 */
export function performBulkDocumentsOperationDelete(workspaceId, docsIds) {
  return performBulkDocumentsOperation({
    operation: BulkOperationType.DELETE,
    workspaceId,
    docsIds,
  });
}

/**
 * Perform delete pdf operation on documents
 * @param {number} workspaceId
 * @param {number[]} docsIds
 */
export function performBulkDocumentsOperationDeletePdf(workspaceId, docsIds) {
  return performBulkDocumentsOperation({
    operation: BulkOperationType.DELETE_PDF,
    workspaceId,
    docsIds,
  });
}

/**
 * Perform delete operation on documents
 * @param {number} workspaceId
 * @param {number[]} docsIds
 * @param {number} extraDaysCount
 */
export function performBulkDocumentsOperationProlongExpiration(workspaceId, docsIds, extraDaysCount) {
  return performBulkDocumentsOperation({
    operation: BulkOperationType.PROLONG_EXPIRATION,
    workspaceId,
    docsIds,
    options: {
      extraDaysCount,
    },
  });
}

/**
 * Perform bulk operation on documents
 * @param payload
 * @returns {Promise<AxiosResponse<>>}
 */
export function performBulkDocumentsOperation(payload) {
  return internalApiRequest({
    method: 'POST',
    path: 'api/v1/docs-batch',
    data: payload,
  });
}
