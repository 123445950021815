<template>
  <v-card
    class="my-5"
    elevation="0"
  >
    <v-row
      v-if="$vuetify.breakpoint.mobile"
      class="workspace-members__table--row px-5"
      align="center"
      justify="start"
      no-gutters
    >
      <v-col>{{ $t('general.name_and_surname') }}</v-col>
    </v-row>

    <v-row
      v-else
      class="workspace-members__table--row px-5"
      align="center"
      justify="start"
      no-gutters
    >
      <v-col class="col-3">{{ $t('general.name_and_surname') }}</v-col>
      <v-col class="col-3">{{ $t('workspace.role') }}</v-col>
      <v-col class="col-5">{{ $t('general.email_simple') }}</v-col>
      <v-col class="col-1 text-end">{{ $t('general.actions') }}</v-col>
    </v-row>

    <WorkspaceMember
      v-for="member in workspace.allowed_users"
      :key="member.id"
      :member="member"
      :workspace="workspace"
      @reload-workspace="onReloadWorkspace"
    />
  </v-card>
</template>

<script>
import WorkspaceMember from '@/features/workspace/components/WorkspaceMember.vue';

export default {
  name: 'WorkspaceMembers',
  components: {
    WorkspaceMember,
  },
  props: {
    workspace: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getMemberTitle(member) {
      return member?.firstname && member?.lastname ? `${member.firstname} ${member.lastname}` : `${member.email}`;
    },
    onReloadWorkspace() {
      this.$emit('reload-workspace');
    },
  },
};
</script>
<style lang="scss" scoped>
.workspace-members__table--row {
  height: 5rem;
  border: 1px solid #ced4da;
  border-left: none;
  border-top: none;
  border-right: none;
}
</style>
