// eslint-disable-next-line no-unused-vars
import { Realtime, RealtimeChannel, RealtimeHistoryParams } from 'ably';

/**
 * Callback function to be called when the connection event is triggered
 * @callback ConnectionEventCallback
 */

/**
 * Initialize Ably client
 * @param {ConnectionEventCallback} onConnected
 * @param {ConnectionEventCallback} onFailed
 * @returns {Realtime}
 */
export function initializeAblyClient(onConnected = null, onFailed = null) {
  const client = new Realtime({
    key: '-yezdQ.zgE8jA:C1a8qvKYHF_kvLLRowJ-MgqFZdtdLGhlWscyNU7gVlE',
  });

  if (onConnected) {
    client.connection.on('connected', () => onConnected());
  }

  if (onFailed) {
    client.connection.on('failed', () => onFailed());
  }

  return client;
}

/**
 * Get a channel by name
 * @param {Realtime} client
 * @param {string} channelName
 * @returns {RealtimeChannel}
 */
export function getAblyChannel(client, channelName) {
  return client.channels.get(channelName);
}

/**
 * Subscribe to a specific event on a channel
 * @param {RealtimeChannel} channel
 * @param callback
 */
export function subscribeToAblyChannel(channel, callback) {
  return channel.subscribe(callback);
}

/**
 * Subscribe to a specific event on a channel
 * @param {RealtimeChannel} channel - Ably channel object
 * @param {string} eventName - Name of the event to subscribe to
 * @param callback - Callback function to be called when the event is received
 */
export function subscribeToAblyChannelEvent(channel, eventName, callback) {
  return channel.subscribe(eventName, callback);
}

/**
 * Returns the history of a channel
 * @param {RealtimeChannel} channel - Ably channel object
 * @param {RealtimeHistoryParams} options - Options for the history request
 */
export function getAblyChannelHistory(channel, options = null) {
  return channel.history(options ? options : null);
}

/**
 * Close the Ably client
 * @param {Realtime} client
 */
export function closeAblyClient(client) {
  return client.close();
}
