<template lang="html">
  <svg
    width="16px"
    height="24px"
    viewBox="0 0 16 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Desktop"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="SYN001---DSKTP---Nová-smlouva---výběr-zdroje"
        transform="translate(-396.000000, -546.000000)"
      >
        <rect
          fill="none"
          x="0"
          y="0"
          width="1200"
          height="893"
        ></rect>
        <g
          id="karty-3-copy"
          transform="translate(370.000000, 522.000000)"
          stroke="#b4c1d6"
        >
          <g
            id="ico/ico-dokumenty"
            transform="translate(19.000000, 21.000000)"
          >
            <g
              id="ico/ico-vzory-1"
              transform="translate(8.000000, 4.000000)"
            >
              <path
                d="M1.56043011e-13,5.08937663 C1.35279511e-13,14.8115798 1.45661261e-13,19.880391 1.87188262e-13,20.2958103 C2.49464551e-13,20.9189393 0.473028021,21.122807 1.0240711,21.122807 C1.57511418,21.122807 12.6159733,21.122807 13.218615,21.122807 C13.8212566,21.122807 14,20.7106099 14,20.0258869 C14,19.3411639 14,1.45297608 14,0.91424467 C14,0.375513264 13.6765831,-5.81628913e-15 13.0388841,0 C12.6137514,3.87752609e-15 9.93227174,3.87752609e-15 4.99444513,0 L1.56043011e-13,5.08937663 Z"
                id="Path-10"
                stroke-width="2"
                fill="none"
                fill-rule="nonzero"
                transform="translate(7.000000, 10.561404) scale(-1, 1) translate(-7.000000, -10.561404) "
              ></path>
              <path
                d="M7.8245614,6 C10.1381486,6 11.526649,6 11.9900625,6 C12.6851827,6 12.6630491,5.15505478 12.6630491,4.04974853 C12.6630491,3.3128777 12.6630491,1.96296152 12.6630491,-7.10542736e-15"
                id="Path-16"
                stroke-width="2"
                fill-rule="nonzero"
                transform="translate(10.243845, 3.000000) scale(-1, 1) translate(-10.243845, -3.000000) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoFilePrev',
};
</script>
