export function parseFileName(filePath) {
  const lastSlashIndex = filePath.lastIndexOf('/');
  const lastBackslashIndex = filePath.lastIndexOf('\\');
  const lastSeparatorIndex = Math.max(lastSlashIndex, lastBackslashIndex);

  const filenameWithExtension = -1 === lastSeparatorIndex ? filePath : filePath.slice(lastSeparatorIndex + 1);
  const lastDotIndex = filenameWithExtension.lastIndexOf('.');

  const name = -1 === lastDotIndex ? filenameWithExtension : filenameWithExtension.slice(0, lastDotIndex);
  const extension = -1 === lastDotIndex ? '' : filenameWithExtension.slice(lastDotIndex + 1);

  return {
    name,
    extension,
  };
}
