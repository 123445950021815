<template>
  <div class="load-more">
    <v-btn
      color="primary"
      :disabled="isLoadingTableRows"
      :loading="isLoadingTableRows"
      outlined
      large
      @click="$emit('onLoadMore')"
    >
      {{ $t('general.load_more') }}...
    </v-btn>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoadMore',
  props: {
    isLoadingTableRows: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.load-more {
  text-align: center;
  margin-top: 20px;

  .button {
    height: 50px;
    max-width: 200px;
  }
}
</style>
