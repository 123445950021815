/**
 * Store module providing info about workspace settings
 *
 * @author David Kulhánek <davidkulh182@gmail.com>
 */

import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';
import state from './state';

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
