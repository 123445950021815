<script setup>
import { getCurrentInstance, ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router/composables';
import { usePaymentMethodsStore } from '@/features/workspace/stores/paymentMethods';
import Loader from '@/components/Loader';
import PaymentMethodForm from '@/features/workspace/components/PaymentMethodForm';

const app = getCurrentInstance();
const route = useRoute();
const paymentMethodsStore = usePaymentMethodsStore();

const loaded = ref(false);
const showEditPaymentMethod = ref(false);

const paymentMethod = computed(() => paymentMethodsStore.paymentMethod || null);
const workspaceId = computed(() => route.params.workspace_id);
const isLoading = computed(() => !loaded.value);

onMounted(async () => {
  try {
    await paymentMethodsStore.fetchPaymentMethods(workspaceId.value);
    loaded.value = true;
  } catch (e) {
    app.proxy.$notification.console.error(app.proxy.$t('workspace.payment_method.load_error'));
  }
});
</script>

<template>
  <Loader v-if="isLoading" />

  <v-card
    v-else
    class="payment-method__box pa-0 ma-0"
    elevation="0"
    rounded="lg"
  >
    <v-card-title>
      <h2 class="title font-weight-medium mb-0">
        {{ $t('workspace.payment_method') }}
      </h2>
    </v-card-title>

    <v-card-text>
      <v-row
        v-if="paymentMethod === null"
        justify="center"
      >
        <v-col cols="12">
          <div class="title text-center">
            {{ $t('workspace.payment_method.no_method') }}
          </div>
        </v-col>

        <v-col cols="auto">
          <v-btn
            color="primary"
            min-width="240px"
            x-large
            @click="showEditPaymentMethod = true"
          >
            {{ $t('workspace.payment_method.create') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        v-else
        justify="center"
      >
        <v-col
          cols="6"
          md="4"
        >
          <v-text-field
            type="text"
            :value="paymentMethod.methodType"
            :label="$t('workspace.payment_method.method_type')"
            readonly
            disabled
          ></v-text-field>
        </v-col>

        <v-col
          cols="6"
          md="4"
        >
          <v-text-field
            :value="paymentMethod.country"
            :label="$t('general.country')"
            readonly
            disabled
          ></v-text-field>
        </v-col>

        <v-col
          v-if="paymentMethod.methodType === 'qr_pay'"
          cols="12"
          md="6"
        >
          <v-text-field
            :value="paymentMethod.iban"
            :label="$t('workspace.payment_method.iban')"
            readonly
            disabled
          ></v-text-field>
        </v-col>

        <v-col
          v-if="paymentMethod.methodType === 'qr_pay'"
          cols="12"
          md="4"
        >
          <v-text-field
            :value="paymentMethod.bic"
            :label="$t('workspace.payment_method.bic')"
            readonly
            disabled
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          class="text-center"
        >
          <v-btn
            color="error"
            min-width="10rem"
            @click="showEditPaymentMethod = true"
          >
            {{ $t('workspace.payment_method.edit') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <PaymentMethodForm
      v-model="showEditPaymentMethod"
      :payment-method="paymentMethod"
    />
  </v-card>
</template>

<style lang="scss" scoped>
.payment-method {
  .loader-wrap {
    position: relative;
    transform: unset;
    padding: 0;
    left: unset;
  }

  :deep(.v-input__slot::before) {
    border-image: none !important;
  }
}

.payment-method__box {
  border: 1px solid #d4dff0;
}
</style>
