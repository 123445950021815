<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
    <title>ico-N/ico-24-email.gray</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
      id="ico-N/ico-24-email.gray"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Group-9"
        transform="translate(1.000000, 4.000000)"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          d="M-2.81308433e-14,10.4718111 C-3.6030774e-14,12.66229 0.852920762,15 3.94359294,15 C7.03426513,15 17.6980055,15 19.237053,15 C20.7761004,15 22,13.9924509 22,11.4921192 C22,8.99178742 22,6.31416135 22,3.80540211 C22,1.29664287 21.3524603,-2.50023825e-14 18.9549918,-2.50023825e-14 C16.5575233,-2.50023825e-14 5.93670221,-5.29752238e-14 3.42755671,-3.8450549e-14 C0.91841121,-2.39258741e-14 -2.57646804e-14,1.3882141 -2.81308433e-14,3.35146876 C-3.02298587e-14,5.31472342 -2.0001929e-14,8.28133215 -2.81308433e-14,10.4718111 Z"
          id="Path-35"
          stroke-linecap="round"
          stroke-linejoin="round"
          transform="translate(11.000000, 7.500000) scale(-1, 1) translate(-11.000000, -7.500000) "
        ></path>
        <polyline
          id="Path-2"
          points="0.538445677 5.18320844 11 8.97589024 21.8659175 5.18320844"
        ></polyline>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoEmails',
};
</script>

<style lang="scss" scoped></style>
