import { computed, onMounted, onUnmounted, ref } from 'vue';

export function useScreen() {
  const orientationType = ref(window.screen.orientation.type);
  const isLandscapeOrientation = computed(() => orientationType.value?.includes('landscape'));

  function updateOrientation() {
    orientationType.value = window.screen.orientation.type;
  }

  onMounted(() => {
    if (window.screen.orientation) {
      window.screen.orientation.addEventListener('change', updateOrientation);
    }
  });

  onUnmounted(() => {
    if (window.screen.orientation) {
      window.screen.orientation.removeEventListener('change', updateOrientation);
    }
  });

  return { orientationType, isLandscapeOrientation };
}
