import { translationsPrefix } from '@/app/config/appEnvironment';
import { FALLBACK_LOCALE, loadTranslationsMessages } from '@/app/config/locales';
import { getPreferredLocale } from '@/app/services/localeService';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export default new VueI18n({
  locale: getPreferredLocale(),
  fallbackLocale: FALLBACK_LOCALE,
  messages: loadTranslationsMessages({
    path: 'exports/base',
    translationsPrefix: `${translationsPrefix}`,
  }),
  pluralizationRules: {
    cs: (count) => (1 === count ? 0 : count < 5 ? 1 : 2),
  },
  escapeParameterHtml: true,
});
