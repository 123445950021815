<template>
  <svg
    width="12px"
    height="8px"
    viewBox="0 0 12 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="SIG011-Vytváření-smlouvy---sjednocení"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="SYN015---320---podpis-6"
        transform="translate(-155.000000, -583.000000)"
        fill-rule="nonzero"
        stroke="#5E239E"
        stroke-width="2"
      >
        <g
          id="Group-4"
          transform="translate(8.000000, 332.000000)"
        >
          <g
            id="Group"
            transform="translate(0.000000, 12.000000)"
          >
            <g
              id="hotovo"
              transform="translate(30.000000, 56.000000)"
            >
              <g
                id="arr/arr-16-2"
                transform="translate(123.000000, 187.500000) scale(1, -1) translate(-123.000000, -187.500000) translate(118.000000, 185.000000)"
              >
                <polyline
                  id="Path-2"
                  points="0 0 4.77905049 4.77905049 9.55810098 0"
                ></polyline>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoArrowTop',
};
</script>
