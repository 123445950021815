import { authorizedApiRequest } from '@/services/ApiService';

/**
 * @param {String} password
 * @param {String|Number} userId
 * @param {String|Number} workspaceId
 * @returns {Promise<AxiosResponse<*>>}
 */
export const workspaceApiSettingsRequest = ({ password, userId, workspaceId }) => {
  return authorizedApiRequest({
    method: 'POST',
    endpoint: 'api/v1/tokens',
    data: { password, user_id: userId, workspace_id: workspaceId },
  });
};

/**
 * @param method
 * @param payload
 * @returns {Promise<AxiosResponse<*>>}
 */
export const workspaceApiKeyRequest = ({ method, payload }) => {
  return authorizedApiRequest({
    method,
    endpoint: 'api/v1/tokens',
    data: payload,
  });
};
