<template lang="html">
  <svg
    width="49px"
    height="51px"
    viewBox="0 0 49 51"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        transform="translate(-11.000000, -9.000000)"
        fill-rule="nonzero"
        stroke-width="2"
      >
        <g>
          <g transform="translate(11.666667, 9.333333)">
            <polyline
              stroke="#6DECAF"
              points="0.333333333 31.5194128 9.99833769 17.3333333 37.9247302 17.3333333 47.3333333 31.5194128"
            ></polyline>
            <g
              transform="translate(7.000000, 0.000000)"
              stroke="#D4DFF0"
            >
              <path
                d="M0.333333333,12.0020964 C0.333333333,33.6560944 0.333333333,44.9457194 0.333333333,45.8709715 C0.333333333,47.2588496 1.44832795,47.7129187 2.74721522,47.7129187 C4.04610248,47.7129187 30.0709848,47.7129187 31.4914972,47.7129187 C32.9120097,47.7129187 33.3333333,46.7948434 33.3333333,45.2697784 C33.3333333,43.7447135 33.3333333,3.90284065 33.3333333,2.70293889 C33.3333333,1.50303712 32.5709936,0.666666667 31.0678458,0.666666667 C30.0657473,0.666666667 23.7451167,0.666666667 12.105954,0.666666667 L0.333333333,12.0020964 Z"
                id="Path-10"
                fill="#FFFFFF"
                transform="translate(16.833333, 24.189793) scale(-1, 1) translate(-16.833333, -24.189793) "
              ></path>
              <path
                d="M21.3333333,12.6303027 C27.2098592,12.6303027 30.7366586,12.6303027 31.9137317,12.6303027 C33.6793413,12.6303027 33.6231218,11.0300277 33.6231218,8.93664469 C33.6231218,7.54105604 33.6231218,4.9843967 33.6231218,1.26666667"
                id="Path-16"
                transform="translate(27.478328, 6.948485) scale(-1, 1) translate(-27.478328, -6.948485) "
              ></path>
            </g>
            <path
              d="M0.333333333,30.6666667 L14.6038162,30.6666667 C16.3280688,36.1304684 19.4518932,38.8623692 23.9752894,38.8623692 C28.4986857,38.8623692 31.5178217,36.1304684 33.0326975,30.6666667 L47.3333333,30.6666667 L47.3333333,49.6666667 L0.333333333,49.6666667 L0.333333333,30.6666667 Z"
              id="Path-31"
              stroke="#6DECAF"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoDashboardTemplates',
};
</script>
