import { auth } from '@/app/plugins/firebase';
import { GoogleAuthProvider, OAuthProvider, signInWithPopup, signOut } from 'firebase/auth';

export function setAuthInstance(languageCode = null) {
  if (null === languageCode) {
    auth.useDeviceLanguage();
  } else {
    auth.languageCode = languageCode;
  }
}

export async function signInWithProvider(provider, parser) {
  try {
    const result = await signInWithPopup(auth, provider);
    const credential = parser(result);

    return {
      accessToken: credential.accessToken,
      idToken: credential.idToken,
      user: result.user,
    };
  } catch (error) {
    throw new Error(error?.message);
  }
}

export function useGoogleSignInPopup(languageCode = null) {
  setAuthInstance(languageCode);

  const provider = new GoogleAuthProvider();

  return signInWithProvider(provider, GoogleAuthProvider.credentialFromResult);
}

export function useMicrosoftSignInPopup(languageCode = null) {
  setAuthInstance(languageCode);

  const provider = new OAuthProvider('microsoft.com');

  return signInWithProvider(provider, OAuthProvider.credentialFromResult);
}

export function useProviderLogout() {
  return signOut(auth);
}
