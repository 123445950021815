import { authorizedApiRequest } from '@/services/ApiService';

export const namespaced = true;

export const state = {
  profile: {},
};

export const mutations = {
  setUserProfile: (state, payload) => {
    state.profile = payload;
  },
  resetUserProfile: (state) => {
    state.profile = null;
  },
};

export const actions = {
  fetchProfile: (context, workspaceId = null) => {
    return authorizedApiRequest({
      method: 'GET',
      endpoint: `/api/v1/loggeduser${null === workspaceId ? `?workspace_id=${workspaceId}` : ''}`,
    })
      .then((userProfile) => {
        context.commit('setUserProfile', userProfile);
        context.commit('updateProfile', userProfile, { root: true });
        context.dispatch('subscription/fetchActive', null, { root: true });

        return userProfile;
      })
      .catch(() => {
        context.commit('resetUserProfile');

        return {};
      });
  },
};

export const getters = {
  profile: (state) => {
    return state.profile;
  },
};
