<template>
  <section class="d-flex flex-column align-center justify-center">
    <v-container
      class="signature-upload-box__wrapper ma-4 pa-0 mb-0"
      :class="{
        'signature-upload-box__wrapper--dragging': canShowBorder,
      }"
      @dragenter="dragging = true"
      @dragleave="dragging = false"
      @drop.prevent="addDropFile"
      @dragover.prevent
      fluid
    >
      <div class="signature-upload-box pb-4">
        <template v-if="hasUploadedFile">
          <v-container
            class="ma-0 pa-0 mb-4"
            fluid
          >
            <v-row
              class="signature-upload-box__uploaded-file mx-auto mt-6"
              align="start"
              justify="space-between"
              no-gutters
            >
              <v-col class="col-11 align-self-center px-2">
                <v-row
                  align="center"
                  justify="center"
                  no-gutters
                >
                  <v-col class="col-12 d-flex justify-space-between align-center flex-row pb-2">
                    <span class="signature-upload-box__file-title text-truncate pr-1">
                      {{ uploadedImage.name }}
                    </span>

                    <span class="signature-upload-box__info-text pl-1 pt-1">
                      {{ formattedSize }}
                    </span>
                  </v-col>

                  <v-col class="col-12 pb-2">
                    <v-progress-linear
                      height="8px"
                      :value="100"
                      :color="stateColor"
                      :indeterminate="false"
                      :query="true"
                      rounded
                    ></v-progress-linear>
                  </v-col>

                  <v-col
                    v-if="hasError"
                    class="col-12"
                  >
                    <span class="signature-upload-box__uploaded-file--error">
                      {{ errorMessage }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>

              <v-col class="col-1">
                <v-btn
                  small
                  icon
                  @click="onDeleteSignatureImage"
                >
                  <v-icon
                    :color="actionColor"
                    small
                    >mdi-trash-can</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-if="hasUploadedFile">
              <v-col class="col-12">
                <img
                  :src="uploadedImageSrc"
                  alt=""
                />
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-else>
          <span class="signature-upload-box__title mb-6">
            {{ $t('upload.drop_zone.image') }}
          </span>

          <span class="signature-upload-box__separator mb-6">
            {{ $t('general.or') }}
          </span>
        </template>

        <label
          for="uploadedImageFile"
          class="signature-upload-box__upload-label d-flex align-center justify-center"
        >
          <v-icon
            color="white"
            small
            left
            >mdi-tray-arrow-up</v-icon
          >

          <span class="signature-upload-box__upload-label--text">
            {{ $t('general.select_file') }}
          </span>
        </label>
      </div>
    </v-container>

    <div class="d-none">
      <v-file-input
        v-model="uploadedImage"
        ref="uploadedImageFile"
        id="uploadedImageFile"
        name="uploadedImageFile"
        accept="image/*"
        :rules="fileInputRules"
        @change="saveImage"
        @update:error="(e) => (imageFileError = e)"
      ></v-file-input>

      <canvas
        id="imageCanvas"
        width="630"
        height="300"
      ></canvas>
    </div>
  </section>
</template>

<script>
import { formattedSize } from '@/common/reusable/file';
import { useSignatureStore } from '@/features/signature/stores/signature';

export default {
  name: 'SignatureImage',
  setup() {
    const signatureStore = useSignatureStore();

    return {
      signatureStore,
    };
  },
  data() {
    return {
      dragging: false,
      uploadedImage: null,
      uploadedImageSrc: null,
      imageFileError: false,
      fileInputRules: [
        (value) => !value || value.size < 5000000 || this.$t('file.max_file_size', { fileSize: '5' }),
        (value) => !value || value.type?.includes('image/') || this.$t('file.invalid_format'),
      ],
    };
  },
  computed: {
    canContinue() {
      return null !== this.uploadedImage;
    },
    canShowBorder() {
      return this.hasUploadedFile || this.dragging;
    },
    hasUploadedFile() {
      return null !== this.uploadedImage;
    },
    hasError() {
      return this.imageFileError;
    },
    formattedSize() {
      if (this.hasUploadError) {
        return this.$t('upload.failed');
      }

      return formattedSize(this.uploadedImage);
    },
    actionColor() {
      return this.hasError ? '#e03428' : '#95afda';
    },
    errorMessage() {
      return this.$refs.uploadedImageFile?.validations[0];
    },
    stateColor() {
      return this.hasError ? '#d7141a' : '#35d883';
    },
  },
  watch: {
    uploadedImage: {
      handler: function (value) {
        if (value) {
          this.dragging = false;
          this.saveImage();
        }
      },
    },
  },
  methods: {
    addDropFile(e) {
      this.uploadedImage = e.dataTransfer.files[0];
    },
    onDeleteSignatureImage() {
      const canvas = document.getElementById('imageCanvas');
      const context = canvas.getContext('2d');

      context.clearRect(0, 0, canvas.width, canvas.height);

      this.uploadedImage = null;
      this.signatureStore.setSignatureDataUrl(null);
    },
    saveImage() {
      const canvas = document.getElementById('imageCanvas');
      const context = canvas.getContext('2d');
      const signatureImage = new Image();
      const self = this;

      signatureImage.src = URL.createObjectURL(this.uploadedImage);
      signatureImage.onload = () => {
        const horizontalRatio = canvas.width / signatureImage.width;
        const verticalRatio = canvas.height / signatureImage.height;
        const ratio = Math.min(horizontalRatio, verticalRatio);
        const centerShift_x = (canvas.width - signatureImage.width * ratio) / 2;
        const centerShift_y = (canvas.height - signatureImage.height * ratio) / 2;

        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(
          signatureImage,
          0,
          0,
          signatureImage.width,
          signatureImage.height,
          centerShift_x,
          centerShift_y,
          signatureImage.width * ratio,
          signatureImage.height * ratio,
        );

        self.signatureStore.setSignatureDataUrl(canvas?.toDataURL('image/png'));
        self.uploadedImageSrc = canvas?.toDataURL('image/png');
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.signature-upload-box {
  display: flex;
  max-width: 736px;
  min-height: 300px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__wrapper {
    box-sizing: border-box;
    border: 1px dashed #b4c1d6;
    border-radius: 4px;
    background-color: #ffffff;
    margin: 0 auto;

    &--dragging {
      border: 2px solid var(--v-primary-base) !important;
    }
  }

  &__title {
    color: #424242;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 27.5px;
    text-align: center;
  }

  &__subtitle {
    color: #424242;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22.5px;
  }

  &__file-title {
    color: #495057;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
  }

  &__info-text {
    color: #95afda;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }

  &__separator {
    color: #424242;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }

  &__upload-label {
    height: 40px;
    padding: 0 1rem;
    border-radius: 4px;
    background: linear-gradient(90deg, #6226a3 0%, #934fdd 100%);
    color: white;
    cursor: pointer;
  }

  &__upload-label--text {
    height: 17px;
    width: 103px;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
  }

  &__uploaded-file {
    max-width: 450px;
  }

  &__uploaded-file--error {
    color: #e03428 !important;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }

  &__uploaded-file-actions {
    max-width: 675px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
