/**
 * @param {string} dataURI
 * @returns {Blob}
 */
export const convertDataUriToBlob = ({ dataURI }) => {
  const splitDataURI = dataURI.split(',');
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

/**
 * @param {String} url
 * @param {Function} callback
 * @param {String} [outputFormat='image/png']
 */
export const convertImgToBase64 = (url, callback, outputFormat = 'image/png') => {
  let canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const img = new Image();

  img.crossOrigin = 'Anonymous';
  img.onload = () => {
    canvas.height = img.height;
    canvas.width = img.width;
    ctx.drawImage(img, 0, 0);

    callback.call(this, canvas.toDataURL(outputFormat));

    canvas = null;
  };

  img.src = url;
};
