<template>
  <div class="workspace-input">
    <div class="input input-text">
      <div class="input-label-wrap">
        <div class="input__label input__label--value label_bar">
          <label for="workspace_custom_header">
            {{ $t('workspace.settings.custom_header') }}
          </label>

          <button
            class="custom_header--delete_button badge"
            @click="reset"
          >
            {{ $t('general.delete') }}
          </button>
        </div>

        <div class="input-label-wrap__placeholders_bar">
          <span
            v-for="(placeholderField, index) in allFields"
            :key="index"
            class="placeholder_bar__placeholder badge"
            :class="{ disabled: isFieldPlaced(placeholderField) || null === placeholderField.value }"
            :title="placeholderFieldValue(placeholderField)"
            @click="placeField(placeholderField)"
          >
            {{ placeholderFieldTitle(placeholderField) }}
          </span>
        </div>
      </div>

      <div class="input__workspace_custom_header__textarea-wrapper">
        <textarea
          ref="workspace_custom_header"
          id="workspace_custom_header"
          cols="80"
          rows="12"
          name="workspace_custom_header"
          class="workspace_custom_header textarea__data"
          v-model="editText"
        ></textarea>

        <!--For feature: Html tokens-->
        <!--<div class="fakearea multiline"-->
        <!--:class="{hidden: !editText.length}" v-html="visibleHtml"-->
        <!--&gt;</div>-->

        <div
          class="header_preview multiline mb-5"
          :class="{ hidden: !editText.length }"
          v-text="visibleText"
        ></div>

        <!--<div class="header_preview multiline">{{tokenizedText}}</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { insertAtCursor, setCursorPosition } from '@/helpers/PlaceholderHelper';

export default {
  name: 'WorkspaceOrganizationHeaderTemplate',
  props: {
    customFields: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    workspace: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      // TODO: get default template from server?
      defaultText: '',
      editText: '',
      loaded: false,
    };
  },
  mounted() {
    this.setup();
  },
  computed: {
    tokenizedCustomFields() {
      return _.map(this.customFields, (cf) => {
        const token = _.isNumber(cf.id) ? `CUSTOM_${cf.id}` : cf.id;
        return {
          id: cf.id,
          name: cf.name,
          visible_token: cf.name,
          token: token,
          value: cf.value,
        };
      });
    },
    filteredCustomFields() {
      return _.filter(this.tokenizedCustomFields, (f) => {
        return f.name !== '';
      });
    },
    filteredStandaloneFields() {
      return _.filter(this.standaloneFields, (f) => {
        return f.value !== '';
      });
    },
    standaloneFields() {
      // TODO: i18n - title can be translated, not sure about visible token; maybe it is dead code?
      return [
        {
          token: 'FIRSTNAME',
          visible_token: 'Jméno',
          title: this.$t('general.name'),
          value: _.get(this.workspace, 'firstname', ''),
        },
        {
          token: 'LASTNAME',
          visible_token: 'Příjmení',
          title: this.$t('general.surname'),
          value: _.get(this.workspace, 'lastname', ''),
        },
        {
          token: 'EMAIL',
          visible_token: 'E-mail',
          title: this.$t('general.email_simple'),
          value: _.get(this.workspace, 'email', ''),
        },
        {
          token: 'MOBILE',
          visible_token: 'Telefon',
          title: this.$t('general.phone'),
          value: _.get(this.workspace, 'mobile', ''),
        },
        {
          token: 'ORGANIZATION_NAME',
          visible_token: 'Název organizace',
          title: this.$t('company.title'),
          value: _.get(this.workspace, 'organization_name', ''),
        },
        {
          token: 'ORGANIZATION_ICO',
          visible_token: 'IČ',
          title: this.$t('company.ico'),
          value: _.get(this.workspace, 'organization_ico', ''),
        },
        {
          token: 'ORGANIZATION_DIC',
          visible_token: 'DIČ',
          title: this.$t('company.dic'),
          value: _.get(this.workspace, 'organization_dic', ''),
        },
        {
          token: 'ORGANIZATION_STREET',
          visible_token: 'Ulice',
          title: this.$t('company.street'),
          value: _.get(this.workspace, 'organization_street', ''),
        },
        {
          token: 'ORGANIZATION_CITY',
          visible_token: 'Město',
          title: this.$t('company.city'),
          value: _.get(this.workspace, 'organization_city', ''),
        },
        {
          token: 'ORGANIZATION_ZIP',
          visible_token: 'PSČ',
          title: this.$t('company.zip'),
          value: _.get(this.workspace, 'organization_zip', ''),
        },
        {
          token: 'POSITION',
          visible_token: 'Pozice',
          title: this.$t('workspace.position'),
          value: _.get(this.workspace, 'position', ''),
        },
      ];
    },
    allFields() {
      return _.concat(this.filteredStandaloneFields, this.filteredCustomFields);
    },
    visibleText() {
      let parsedHtml = this.editText;
      _.each(this.allFields, (f) => {
        parsedHtml = _.replace(parsedHtml, new RegExp(this.createPlaceholder(f.visible_token), 'mg'), f.value);
      });
      return parsedHtml;
    },
    tokenizedText() {
      let parsedHtml = this.editText;
      _.each(this.allFields, (f) => {
        parsedHtml = _.replace(
          parsedHtml,
          new RegExp(this.createPlaceholder(f.visible_token), 'mg'),
          this.createPlaceholder(f.token),
        );
      });
      return parsedHtml;
    },
    // for feature: tokens as html
    // visibleHtml() {
    //   let parsedHtml = this.editText;
    //   _.each(this.allFields, (f) => {
    //     parsedHtml = _.replace(parsedHtml, new RegExp(this.createPlaceholder(f.visible_token), 'mg'), this.createHtmlBadge(f.value));
    //   });
    //   return parsedHtml;
    // }
  },
  methods: {
    placeField(fieldToPlace) {
      if (this.isFieldPlaced(fieldToPlace)) return;

      const { text, newCursorPosition } = insertAtCursor(
        this.$refs.workspace_custom_header,
        this.createPlaceholder(fieldToPlace.visible_token),
        true,
      );
      this.editText = text;
      this.$nextTick(() => {
        setCursorPosition(this.$refs.workspace_custom_header, newCursorPosition);
      });
    },
    createPlaceholder(fieldName) {
      return `{${fieldName}}`;
    },
    // for feature: tokens as html
    // createHtmlBadge(fieldName) {
    //   return `<span class="badge">${fieldName}</span>`;
    // },
    placeholderFieldTitle(placeholderField) {
      return _.get(placeholderField, 'title', placeholderField.name);
    },
    placeholderFieldValue(placeholderField) {
      return _.get(placeholderField, 'value', '');
    },
    isFieldPlaced(field) {
      return this.editText.indexOf(this.createPlaceholder(field.visible_token)) !== -1;
    },
    cleanUp() {
      const matches = this.editText.match(new RegExp('({[ěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮa-zA-z\\s\\d]*})', 'gm'));
      let placeholdersToDelete = [];

      if (matches) {
        placeholdersToDelete = _.filter(matches, (match) => {
          return (
            _.filter(this.allFields, (f) => {
              return match === this.createPlaceholder(f.visible_token);
            }).length === 0
          );
        });

        let template = this.editText;

        _.each(placeholdersToDelete, (placeholder) => {
          template = _.replace(template, new RegExp(' ' + placeholder + ' ', 'mg'), '');
          template = _.replace(template, new RegExp(' ' + placeholder, 'mg'), '');
          template = _.replace(template, new RegExp(placeholder + ' ', 'mg'), '');
          template = _.replace(template, new RegExp(placeholder, 'mg'), '');
        });

        this.editText = template;
      }
    },
    humanizeTokens() {
      let template = this.editText;
      _.each(this.allFields, (f) => {
        template = _.replace(template, new RegExp(f.token, 'mg'), f.visible_token);
      });
      this.editText = template;
    },
    setup() {
      if (!this.loaded) {
        this.editText = _.replace(
          _.get(this.workspace, 'organization_header_template', ''),
          new RegExp('(<br />)|(<br/>)|(<br>)', 'mg'),
          '\n',
        );
        this.loaded = true;
      }
      this.humanizeTokens();
      this.cleanUp();
    },
    reset() {
      this.editText = '';
    },
  },
  watch: {
    editText() {
      this.cleanUp();
      this.$emit('change', this.tokenizedText);
    },
    filteredCustomFields() {
      this.cleanUp();
      this.$emit('change', this.tokenizedText);
    },
    workspace() {
      this.loaded = false;
      this.setup();
    },
  },
};
</script>

<style scoped lang="scss">
.input__workspace_custom_header__textarea-wrapper {
  position: relative;
  margin-bottom: 5px;
}

.multiline {
  white-space: pre-wrap;
}

.fakearea {
  outline: none;
  background: white;
  border: 1px solid #b1bbcb;
  border-radius: 6px;
  height: 216px;
  padding: 15px 20px;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: -0.03px;
  max-width: 100%;
  margin: 0;
  min-width: 100%;
  max-height: 200px;
  min-height: 150px;
  position: absolute;
  top: 0;
  opacity: 50%;
  color: red;
}

.badge {
  font-size: 17px;
  font-weight: 300;
  letter-spacing: -0.03px;
  color: transparent;
  border: 1px solid red;
  background: greenyellow;
}

.header_preview {
  opacity: 1;
  transition: opacity 350ms ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  outline: none;
  background: white;
  border: 1px solid #b1bbcb;
  border-radius: 6px;
  padding: 15px 20px;
  font-size: 17px;
  font-weight: 300;
  color: black;
  letter-spacing: -0.03px;
  max-width: 100%;
  margin: 10px 0 0;
  min-width: 100%;
  height: auto;

  &.hidden {
    opacity: 0;
  }
}

.label_bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-content: space-between;
  align-items: center;
  justify-content: space-between;
}

.input-label-wrap__placeholders_bar {
  padding: 5px 12px;
  background-color: #fbfbfb;
  border-radius: 8px;
  border: 1px solid #dedede;
  margin-bottom: 5px;
}

.badge {
  font-size: 17px;
  line-height: 30px;
  padding: 0 8px;
  margin: 3px 5px 3px 0;
  outline: none;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid #b1bbcb;
  color: #5e239e;
  -webkit-text-fill-color: #5e239e;
  -webkit-box-shadow: 0 0 0 1000px white inset;
  display: inline-block;

  &:hover {
    cursor: pointer;
    color: #ffffff;
    -webkit-text-fill-color: #ffffff;
    background-color: #5e239e;
    -webkit-box-shadow: 0 0 0 1000px #5e239e inset;
  }

  &.disabled {
    cursor: not-allowed;
    color: #8e97a4;
    -webkit-text-fill-color: #8e97a4;
    background-color: #f2f6fc;
    -webkit-box-shadow: 0 0 0 1000px #f2f6fc inset;
  }
}
</style>
