<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
    <title>ico-N/ico-24-duplicate.gray</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
      id="ico-N/ico-24-duplicate.gray"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        id="Rectangle"
        stroke="#B4C1D6"
        stroke-width="2"
        x="4"
        y="8"
        width="11"
        height="11"
        rx="3"
      ></rect>
      <path
        d="M9,4 C13.1451731,4 15.7237728,4 16.7357991,4 C18.2538386,4 19,5.25892255 19,6.37197325 C19,7.11400704 19,9.65668263 19,14"
        id="Path-57"
        stroke="#B4C1D6"
        stroke-width="2"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoDuplicate',
};
</script>

<style lang="scss" scoped></style>
