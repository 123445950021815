<template lang="html">
  <header
    class="header"
    :class="{
      openedMenu: isSideMenuVisible,
      enabledMenu: !disableMenu,
      closeAndSave: closeAndSave,
      withoutCloseAndSave: !closeAndSave,
    }"
  >
    <div
      v-if="!disableMenu"
      class="header__menu-ico"
      @click="toggleSideMenu()"
    >
      <span
        v-if="isSideMenuVisible"
        class="icon-close"
      ></span>
      <span
        v-else
        class="icon-ico-hamburger"
      ></span>
      <small class="header__menu-ico-label">MENU</small>
    </div>

    <div
      v-if="customTitle"
      class="header__title-center"
    >
      <span class="contract__name-edit-toggle">
        {{ customTitle }}
      </span>
    </div>

    <div
      v-if="showBackNavigation"
      class="header__navigation_wrapper header__navigation_wrapper-without-padding"
      @click="onNavigateBackClick"
    >
      <div class="header__navigation-back">
        <v-icon
          color="primary"
          left
          @click="onNavigateBackClick()"
          >mdi-arrow-left</v-icon
        >

        <span
          v-if="!$vuetify.breakpoint.mobile"
          @click="onNavigateBackClick()"
        >
          {{ $t(`general.${isRouteCreateContent ? 'back_to_signing_scenario' : 'back'}`) }}
        </span>
      </div>
    </div>

    <div
      v-else
      class="header__navigation_wrapper"
      :style="disableMenu ? 'margin-left: 2rem' : 'cursor: pointer'"
    >
      <AppWorkspaceMenu
        v-if="!closeAndSave"
        :disabled="disableMenu"
      />
    </div>

    <div
      v-if="contractName"
      class="header__title-center"
    >
      <router-link
        :to="homeRoute"
        class="mx-auto text-center"
      >
        <LogoSymbol class="header__logo mx-auto text-center" />
      </router-link>
    </div>

    <div class="header__avatar">
      <HeaderAvatar v-if="!closeAndSave" />

      <a
        v-else
        href="#"
        class="save-and-close-button"
        @click="close"
      >
        <span class="save-and-close-button__title save-and-close-button__title--lg">{{
          $t('general.save_and_close')
        }}</span>
        <span class="save-and-close-button__title save-and-close-button__title--md">{{
          $t('general.save_and_close')
        }}</span>
        <IcoClose />
      </a>
    </div>

    <div
      v-if="!closeAndSave && !customTitle && !contractName"
      class="header__logo--additional"
    >
      <router-link :to="homeRoute">
        <LogoSymbol class="header__logo" />
      </router-link>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { ContractService } from '@/services/ContractService.js';
import AppWorkspaceMenu from '@/layouts/components/AppWorkspaceMenu';
import HeaderAvatar from '@/components/partials/HeaderAvatar.vue';
import IcoClose from '@/components/svg/IcoClose.vue';

export default {
  name: 'Header',
  components: {
    AppWorkspaceMenu,
    HeaderAvatar,
    IcoClose,
  },
  data() {
    return {
      workspaceSelectBoxOpen: false,
      contractNameEditing: false,
      changedName: '',
    };
  },
  props: {
    closeAndSave: {
      required: false,
      default: false,
      type: Boolean,
    },
    customTitle: {
      default: null,
      required: false,
      type: String,
    },
    contractName: {
      required: false,
      default: false,
      type: Boolean,
    },
    disableMenu: {
      required: false,
      default: false,
      type: Boolean,
    },
    showBackNavigation: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(['isSideMenuVisible', 'contract', 'profile']),
    isRouteCreateContent() {
      return this.$route?.name === 'createContent';
    },
    title() {
      return this.changedName || this.contract.title;
    },
    homeRoute() {
      if (this.$route.params.workspace_id) {
        return {
          name: 'dashboard',
          params: {
            workspace_id: this.$route.params.workspace_id,
          },
        };
      }
      return {
        name: 'home',
      };
    },
  },
  methods: {
    toggleSideMenu() {
      this.$store.commit('toggleSideMenu');
    },
    onNavigateBackClick() {
      this.$emit('onNavigationBack');
    },
    close() {
      this.$emit('close');
    },
    async changeContractName(e) {
      this.changedName = e.target.value;
      this.contractNameEditing = false;

      if (e.target.value !== this.contractName) {
        const contractId = this.$route.params.contract_id || this.$route.query.contract_id;
        const data = {
          workspace_id: this.$route.params.workspace_id,
          title: e.target.value,
        };
        try {
          const response = await ContractService.update(contractId, data);
          this.$store.commit('setContract', response);
        } catch (e) {
          this.$notification.error(this.$t('contract.errors.general_save'));
        }
      }
    },
    resizeInput(e) {
      setTimeout(() => {
        this.$refs.ruler.innerHTML = e.target.value;
        if (!this.titleInput) {
          this.titleInput = true;
        }
        this.$nextTick(() => {
          this.$refs.titleInput.style.width = this.$refs.ruler.offsetWidth + 20 + 'px';
        });
      }, 0);
    },
    toggleChangeContractName() {
      this.resizeInput({ target: { value: this.title } });
      this.contractNameEditing = true;
      setTimeout(() => {
        this.$refs.titleInput.focus();
      }, 200);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/mixins';

$headerHeight: 60px;

.header__logo {
  width: 60px;
  overflow: hidden;
  display: block;
  cursor: pointer;
  svg {
    left: 20px;
  }

  @include md {
    display: block;
    position: absolute !important;
    line-height: $headerHeight;
    height: $headerHeight;
    left: 50%;
    transform: translateX(-50%);
  }
}

body.hidden-menu .header__logo {
  @include md {
    left: 50%;
  }
}

.header__claim {
  float: left;
  height: $headerHeight;
  line-height: $headerHeight;
  padding-left: 80px;
  font-weight: 300;
  font-size: 24px;
  color: #8e97a4;
  letter-spacing: -0.05px;
  text-align: center;

  @include md {
    display: none;
  }
}

body.hidden-menu .header__claim {
  display: none;
}

.header__menu-ico {
  height: $headerHeight;
  float: left;
  padding: 15px 0 15px 15px;
  position: relative;
  font-size: 17px;
  display: none;
  width: 45px;

  @include md {
    display: block;
    &:after {
      position: absolute;
      left: 60px;
      top: 0;
      width: 1px;
      background: #d4dff0;
      content: '';
      height: 60px;
    }
  }
  span {
    display: block;
    line-height: 30px;
  }
  .header__menu-ico-label {
    display: inline-block;
    font-size: 9px;
    line-height: 10px;
    vertical-align: top;
  }
}

.openedMenu .header__menu-ico {
  @include md {
    &:after {
      left: 311px;
    }
  }
}

body.hidden-menu .header__menu-ico {
  display: block;
}

.header {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: $headerHeight;
  border-bottom: 1px solid #d4dff0;
  background-color: #f2f6fc;
  @include md {
    border-bottom: none;
  }

  @media screen and (orientation: landscape) {
    // display: none;
  }
}

.header__avatar {
  float: right;
}

.save-and-close-button {
  font-size: 14px;
  letter-spacing: -0.03px;
  text-align: right;
  color: #000000;
  text-decoration: none;
  line-height: $headerHeight;
  font-weight: 300;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;

  svg {
    margin-left: 15px;
  }

  &__title {
    &--md {
      display: none;
      @include sm {
        display: block;
      }
    }

    &--lg {
      @include sm {
        display: none;
      }
    }
  }
}
.header__title-center {
  height: $headerHeight;
  line-height: $headerHeight;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: bold;
  width: fit-content;
  margin: auto;

  .contract__name-editing input {
    background: none;
    text-align: center;
    font-size: 20px;
    color: black;
    font-weight: bold;
    border: 2px solid #5e249e;
    border-radius: 6px;
    width: auto;
    outline: none;
    min-width: 300px;
    line-height: 40px;
    @include md {
      max-width: 300px;
    }
  }
}
.header__navigation_wrapper {
  height: 100%;
  float: left;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  font-size: 16px;
  color: black;
  letter-spacing: -0.5px;
  @include md {
    padding-left: 20px;
  }
  .header__navigation-back {
    cursor: pointer;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #bac1cc;
    padding-left: 20px;
    .ico-arrow-back {
      color: #602f9f;
      width: 15px;
      margin-right: 10px;
      display: flex;
      svg {
        width: 15px;
      }
    }
    @include md {
      border-left: 0;
      padding-left: 0;
      margin-left: 0.5rem;
    }
  }
}
.header__navigation-back__text {
  color: #575759;
  font-size: 16px;
  font-weight: 400;
}
.header__navigation_wrapper-without-padding {
  padding: 0;
  border: 0;
}
.contract__name-edit-toggle {
  cursor: text;
}
.contract__name-editing-ruler {
  visibility: hidden;
  white-space: nowrap;
  font-size: 20px;
  position: absolute;
  left: -9999px;
  text-align: center;
  color: black;
  border: 2px solid #5e249e;
  border-radius: 6px;
  width: auto;
  outline: none;
  min-width: 10px;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  -webkit-appearance: textfield;
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0;
  padding: 1px;
}

.icon-pen1:before {
  color: #979797;
  font-size: 15px;
  padding-left: 10px;
  cursor: pointer;
}

.header__navigation_wrapper:after {
  position: absolute;
  left: 310px;
  top: 0;
  width: 1px;
  background: #d4dff0;
  content: '';
  height: 60px;
  @include md {
    display: none;
  }
}
body.hidden-menu .header__navigation_wrapper:after {
  left: 350px;
}
.openedMenu .header__logo--wrapper {
  @media (min-width: 0px) and (max-width: 720px) {
    display: none;
  }
}
.openedMenu .header__avatar {
  @media (min-width: 0px) and (max-width: 400px) {
    display: none;
  }
}
.header__logo--additional {
  display: none;
}

.header__logo--additional {
  @media (min-width: 993px) {
    position: absolute;
    width: 100%;
    padding-left: 350px;
    padding-right: 450px;
    pointer-events: none; // otherwise click on menu redirects to homepage
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      pointer-events: auto;
    }
  }
}

.withoutCloseAndSave .header__logo--wrapper .header__logo {
  @media (min-width: 993px) {
    display: none;
  }
}
.withoutCloseAndSave .header__navigation_wrapper {
  @media (min-width: 993px) {
    position: relative;
    left: 20px;
    &:after {
      left: 250px !important;
    }
  }
}

body:not(.hidden-menu) {
  @media (min-width: 993px) {
    .withoutCloseAndSave {
      .header__navigation_wrapper {
        width: 270px;
        .workspace-select-box__container {
          left: 0;
          z-index: 20;
        }
        &:after {
          left: 270px !important;
        }
      }
    }
  }
}
body.hidden-menu {
  @media (min-width: 993px) {
    .withoutCloseAndSave {
      .header__navigation_wrapper {
        width: 210px;
        .workspace-select-box__container {
          width: 271px;
          left: -45px !important;
          z-index: 20;
        }

        &:after {
          left: 225px !important;
        }
      }
    }
  }
}
.closeAndSave .header__navigation_wrapper {
  &:after {
    display: none;
  }
}
</style>
