import { DEFAULT_LOCALE, LOCALE_STORAGE_KEY, isLocaleSupported } from '@/app/config/locales';

export function getPreferredLocale() {
  const preferredLocale = localStorage.getItem(LOCALE_STORAGE_KEY);

  if (preferredLocale && isLocaleSupported(preferredLocale)) {
    return preferredLocale;
  }

  const browserLocale = navigator.language?.split('-')[0];

  if (browserLocale && isLocaleSupported(browserLocale)) {
    return browserLocale;
  }

  return DEFAULT_LOCALE;
}

export function getCurrentLocale(component) {
  const componentLocale = component?.$i18n?.locale;

  if (componentLocale) {
    return componentLocale;
  }

  return localStorage.getItem(LOCALE_STORAGE_KEY) || getPreferredLocale();
}

export function changeAndSaveLocale(component, locale) {
  const isChanged = changeLocaleWithoutSave(component, locale);

  component.$vuetify.lang.current = locale;

  if (isChanged) {
    localStorage.setItem(LOCALE_STORAGE_KEY, locale);
  }
}

export function changeLocaleWithoutSave(component, locale) {
  if (!component || !component.$i18n || String(locale) === component.$i18n.locale || !isLocaleSupported(locale)) {
    return false;
  }

  component.$i18n.locale = locale;
  component.$root.$i18n.locale = locale;

  return true;
}
