<template lang="html">
  <svg
    width="81px"
    height="96px"
    viewBox="0 0 81 96"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Desktop"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="SYN001---DSKTP---Dashboard---no-workspace---V2"
        transform="translate(-715.000000, -771.000000)"
        fill-rule="nonzero"
        stroke-width="2"
      >
        <g
          id="oblibene-vzory"
          transform="translate(627.000000, 699.000000)"
        >
          <g
            id="Group-8"
            transform="translate(128.500000, 120.000000) scale(-1, 1) translate(-128.500000, -120.000000) translate(89.000000, 73.000000)"
          >
            <polyline
              id="Path-34"
              stroke="#6DECAF"
              points="0.5 66.4996086 16.5397945 43.3386625 62.8857224 43.3386625 78.5 66.4996086"
            ></polyline>
            <g
              id="Group-5"
              transform="translate(11.000000, 0.000000)"
              stroke="#D4DFF0"
            >
              <path
                d="M2.83125065e-13,16.6496048 C1.14050848e-13,58.9471463 1.14050848e-13,80.941592 2.83125065e-13,82.632942 C5.36678528e-13,85.169967 1.9258998,86 4.16943235,86 C6.41296489,86 51.3650343,86 53.8186467,86 C56.2722592,86 57,84.3217691 57,81.5339682 C57,78.7461673 57,5.91568831 57,3.72228187 C57,1.52887543 55.6832313,-2.36806058e-14 53.0868852,0 C51.3559878,1.57870705e-14 39.0813921,1.57870705e-14 16.263098,0 L2.83125065e-13,16.6496048 Z"
                id="Path-10"
                fill="#FFFFFF"
                stroke-dasharray="2"
              ></path>
              <path
                d="M2.4,17.8987892 C9.87282873,17.8987892 14.35765,17.8987892 15.8544639,17.8987892 C18.0996848,17.8987892 18.0281938,15.462701 18.0281938,12.2759577 C18.0281938,10.1514621 18.0281938,6.25947624 18.0281938,0.6"
                id="Path-16"
                stroke-dasharray="2,2"
              ></path>
              <path
                d="M13.1142423,31.5 L20.4810495,31.5"
                id="Path-12"
              ></path>
              <path
                d="M24.9336735,31.5 L45.8520408,31.5"
                id="Path-12-Copy"
              ></path>
              <path
                d="M13.1142423,43.4951205 L20.4810495,43.4951205"
                id="Path-12-Copy-5"
              ></path>
              <path
                d="M24.9336735,43.4951205 L45.8520408,43.4951205"
                id="Path-12-Copy-4"
              ></path>
              <path
                d="M13.1142423,56.5 L20.4810495,56.5"
                id="Path-12-Copy-9"
              ></path>
              <path
                d="M24.9336735,56.5 L45.8520408,56.5"
                id="Path-12-Copy-8"
              ></path>
              <path
                d="M13.1142423,37.5 L34.1377551,37.5"
                id="Path-12-Copy-3"
              ></path>
              <path
                d="M38.3214286,37.5 L45.8520408,37.5"
                id="Path-12-Copy-2"
              ></path>
              <path
                d="M13.1142423,49.5 L34.1377551,49.5"
                id="Path-12-Copy-7"
              ></path>
              <path
                d="M38.3214286,49.5 L45.8520408,49.5"
                id="Path-12-Copy-6"
              ></path>
            </g>
            <path
              d="M1,66 L24.3793016,66 C27.204141,75.0343744 32.3218959,79.5515616 39.7325664,79.5515616 C47.1432368,79.5515616 52.0894809,75.0343744 54.5712988,66 L78,66 L78,94 L1,94 L1,66 Z"
              id="Path-31"
              stroke="#6DECAF"
              fill="#FFFFFF"
            ></path>
            <path
              d="M1.56873862,80.9512364 L11.9833623,80.9512364"
              id="Path-32"
              stroke="#6DECAF"
            ></path>
            <path
              d="M78.7973417,81 L67.6136587,81"
              id="Path-33"
              stroke="#6DECAF"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoFile',
};
</script>
