<template>
  <v-card
    v-if="$vuetify.breakpoint.mobile"
    class="pa-3 workspace-member__form rounded-0"
    elevation="0"
  >
    <v-row
      align="center"
      justify="start"
      no-gutters
    >
      <v-col class="col-12 pb-3">
        <span class="workspace-member__search--label">
          {{ $t('general.email_address') }}
        </span>

        <v-text-field
          type="text"
          name="email"
          v-model="memberSettings.email"
          :label="$t('general.email_address')"
          class="workspace-member__select--input--border"
          hide-details
          disabled
          solo
          flat
        ></v-text-field>
      </v-col>

      <v-col class="col-12 pb-5">
        <span class="workspace-member__search--label">
          {{ $t('workspace.role') }}
        </span>

        <SPositionSearch
          :type="'position'"
          :positions="filteredPositions.length ? filteredPositions : membersJobPositions"
          :label="`${memberSettings.position || $t('workspace.role')}`"
          :hint="`${$t('workspace.role.help', { link: $t('links.variableRole') })}`"
          :isDynamicList="false"
          :currentId="1"
          :openSearchId="openSearchId"
          @toggleSearch="toggleOpenSearchId($event)"
          @onSignerSelect="selectPosition"
          @onSearchTermChange="filterSearch($event)"
        />
      </v-col>

      <v-col class="col-12">
        <v-checkbox
          v-model="memberSettings.all_contracts"
          color="success"
          :label="$t('workspace.team.role.every_doc')"
          hide-details
        ></v-checkbox>

        <v-checkbox
          v-model="showAllContacts"
          color="success"
          :label="$t('workspace.team.role.only_their_contacts')"
          hide-details
        ></v-checkbox>
      </v-col>

      <v-col class="col-12 mt-5">
        <v-divider></v-divider>
      </v-col>

      <v-col class="col-12 pb-3">
        <v-radio-group
          v-model="memberSettings.rights"
          hide-details
        >
          <v-radio
            :label="$t('workspace.team.role.signer')"
            value="signer"
            name="workspace-person"
            color="success"
            class="mb-5"
          ></v-radio>

          <v-radio
            :label="$t('workspace.team.role.assistant')"
            value="assistant"
            name="workspace-person"
            color="success"
          ></v-radio>
        </v-radio-group>
      </v-col>

      <v-col class="col-12 pb-5">
        <span class="workspace-member__search--label">
          {{ $t('signature.footer.placeholder') }}
        </span>

        <v-textarea
          v-model="memberSettings.signatureFooter"
          :label="$t('footer.text')"
          rows="2"
          dense
          full-width
          outlined
          hide-details
        />
      </v-col>

      <v-col class="col-12 pb-3">
        <v-btn
          color="primary"
          class="workspace-member__submit--btn"
          block
          :loading="isSubmitted"
          @click="onConfirmationAction"
        >
          {{ $t('general.save') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>

  <v-card
    v-else
    class="pa-1 workspace-member__form rounded-0"
    elevation="0"
  >
    <v-row
      class="pl-5 pr-3 pt-2"
      align="center"
      justify="start"
      no-gutters
    >
      <v-col class="col-3 pr-10">
        <v-text-field
          v-model="fullName"
          flat
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col class="col-3 pr-10">
        <SPositionSearch
          :type="'position'"
          :positions="filteredPositions.length ? filteredPositions : membersJobPositions"
          :label="`${memberSettings.position || $t('workspace.role')}`"
          :hint="`${$t('workspace.role.help', { link: $t('links.variableRole') })}`"
          :isDynamicList="false"
          :isEditForm="true"
          :currentId="1"
          :openSearchId="openSearchId"
          @toggleSearch="toggleOpenSearchId($event)"
          @onSignerSelect="selectPosition"
          @onSearchTermChange="filterSearch($event)"
        />
      </v-col>
      <v-col class="col-4 pr-10">
        <v-text-field
          v-model="memberSettings.email"
          flat
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col class="col-2 text-end">
        <v-btn
          color="primary"
          icon
          :loading="isSubmitted"
          @click="onConfirmationAction"
        >
          <v-icon>mdi-content-save-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      class="mt-2 pl-5"
      align="center"
      justify="start"
      no-gutters
    >
      <v-col class="col-auto">
        <v-checkbox
          v-model="memberSettings.all_contracts"
          color="success"
          :label="$t('workspace.team.role.every_doc')"
          hide-details
        ></v-checkbox>

        <v-checkbox
          v-model="showAllContacts"
          color="success"
          :label="$t('workspace.team.role.only_their_contacts')"
          hide-details
        ></v-checkbox>
      </v-col>

      <v-col class="col-auto mx-12 mt-5">
        <v-divider
          vertical
          style="height: 60px"
        ></v-divider>
      </v-col>

      <v-col class="col-auto">
        <v-radio-group
          v-model="memberSettings.rights"
          class="pa-0 pt-1"
          hide-details
        >
          <v-radio
            :label="$t('workspace.team.role.signer')"
            value="signer"
            name="workspace-person"
            color="success"
            class="mb-5"
          ></v-radio>

          <v-radio
            :label="$t('workspace.team.role.assistant')"
            value="assistant"
            name="workspace-person"
            color="success"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row
      class="mt-8 pb-5 pl-5 pr-5"
      align="center"
      justify="start"
      no-gutters
    >
      <v-col class="col-12">
        <span class="workspace-member__search--label">
          {{ $t('signature.footer.placeholder') }}
        </span>

        <v-textarea
          v-model="memberSettings.signatureFooter"
          :label="$t('footer.text')"
          rows="2"
          dense
          full-width
          outlined
          hide-details
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { email, required } from 'vuelidate/lib/validators';
import SPositionSearch from '@/common/components/SPositionSearch';

export default {
  name: 'WorkspaceMemberForm',
  components: {
    SPositionSearch,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    defaultConfig: {
      type: Object,
      required: true,
    },
    isSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showWorkspaceMemberSettingsDialog: false,
      openSearchId: null,
      filteredPositions: [],
      openPositonMenu: false,
      searchTerm: '',
    };
  },
  validations: {
    memberSettings: {
      email: {
        required,
        email,
        minLength: 5,
      },
    },
  },
  computed: {
    ...mapGetters({
      membersJobPositions: 'workspace/membersJobPositions',
    }),
    memberSettings: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    showAllContacts: {
      get() {
        return !this.memberSettings.all_contacts;
      },
      set(value) {
        this.memberSettings.all_contacts = !value;
      },
    },
    isOwner() {
      return 'owner' === this.memberSettings.rights;
    },
    fullName() {
      return this.memberSettings?.firstname && this.memberSettings?.lastname
        ? `${this.memberSettings.firstname} ${this.memberSettings.lastname}`
        : `${this.$t('workspace.members.noname_user')}`;
    },
  },
  watch: {
    membersJobPositions: {
      handler: function (value) {
        this.filteredPositions = [...new Set(value)];
      },
    },
  },
  methods: {
    getMemberTitle(member) {
      return member?.firstname && member?.lastname ? `${member.firstname} ${member.lastname}` : `${member.email}`;
    },
    onConfirmationAction() {
      this.$v.memberSettings.$touch();

      if (this.$v.memberSettings.$error || this.isSubmitted) {
        return;
      }

      this.$emit('update-settings');
    },
    onCloseDialog() {
      this.showWorkspaceMemberSettingsDialog = false;
      this.memberSettings = Object.assign({}, this.defaultConfig);
    },
    toggleOpenSearchId(currentId) {
      this.openSearchId = currentId;
    },
    filterSearch(query) {
      this.filteredPositions = this.membersJobPositions.filter((position) =>
        position.name.toLowerCase().includes(query.toLowerCase()),
      );
    },
    selectPosition(position) {
      this.memberSettings.position = position.name ? position.name : position;
    },
  },
};
</script>
<style lang="scss" scoped>
.workspace-member__form {
  border-bottom: 1px solid #ced4da;
}

.workspace-member {
  background: none;

  @media only screen and (min-width: 960px) {
    background: #ffffff;
    padding: 0.5rem 1rem 1.5rem 1rem;
  }

  &__search--label {
    display: inline-block;
    text-transform: uppercase;
    color: #868e96;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 9px;
  }

  &__select--input {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 15px;

    &--border {
      border: 1px solid #ced4da;
      border-radius: 5px;
    }
  }

  &__submit--btn {
    height: 3rem !important;
  }
}
</style>
