/*
 * Find svg flag on wikipedia, e.g. https://en.wikipedia.org/wiki/Flag_of_Vietnam
 * (country flags are too big: https://www.countryflags.com/en/vietnam-flag-vector.html)
 */
import { applicationTranslations } from '@digitalfactorycz/core';

// TODO - move to core
const COUNTRIES = [
  { countryCode: 'CZE', locale: 'cs' },
  { countryCode: 'USA', locale: 'en' },
  { countryCode: 'DEU', locale: 'de' },
  { countryCode: 'SVK', locale: 'sk' },
  { countryCode: 'POL', locale: 'pl' },
  { countryCode: 'HUN', locale: 'hu' },
  { countryCode: 'NLD', locale: 'nl' },
  { countryCode: 'UKR', locale: 'ua' },
  { countryCode: 'BEL', locale: 'bg' },
  { countryCode: 'RUS', locale: 'ru' },
  { countryCode: 'VNM', locale: 'vi' },
];

export const LOCALE_STORAGE_KEY = 'locale';
export const DEFAULT_LOCALE = 'en';
export const FALLBACK_LOCALE = 'en';

export const appLocales = applicationTranslations.locales.map((locale) => {
  return {
    ...locale,
    locale: locale.localeCode,
    code: locale.browserCode,
    countryCode: COUNTRIES.find((country) => country.locale === locale.localeCode)?.countryCode,
  };
});

export const appLocalesFlags = appLocales?.map((l) => l.flag);

export const availableLocales = appLocales.sort((a, b) => (a.locale > b.locale ? 1 : b.locale > a.locale ? -1 : 0));

export const fullyLocalizedLocales = appLocales.filter((l) => l.isFullyLocalized || false);

export const getAlternateLocale = (lang) => {
  const currentLocale = appLocales?.find((l) => l.locale === lang);

  return currentLocale?.alternate || currentLocale?.locale || 'en';
};

export const getLocales = (isFullyLocalized = true) => (isFullyLocalized ? fullyLocalizedLocales : availableLocales);

export const getNormalizeLocaleCode = (lang) => appLocales?.find((l) => l.locale === lang)?.code || 'en-US';

export const isFullyLocalized = (lang) => appLocales?.find((l) => l.locale === lang)?.isFullyLocalized || false;

export const isLocaleSupported = (lang) => availableLocales.findIndex((l) => l.locale === lang) !== -1;

export function loadTranslationsMessages({ path, translationsPrefix }) {
  const messages = {};

  for (const { localeCode } of appLocales) {
    messages[localeCode] = require(
      `@/locales/${path}/${localeCode}${translationsPrefix ? `_${translationsPrefix}` : ''}.json`,
    );
  }

  return messages;
}
