<template lang="html">
  <Data
    :showMenu="false"
    :shadow="false"
  >
    <div class="pa-5 pt-0 text-center">
      <IcoWorkspaceCreated />
    </div>

    <v-card
      class="workspace__box pa-2 pa-md-5 mx-auto"
      max-width="980px"
      elevation="0"
      rounded="lg"
    >
      <v-row
        class="px-5"
        align="center"
        justify="center"
        no-gutters
      >
        <v-col
          cols="12"
          class="mx-auto my-10"
        >
          <h1 class="display-1 font-weight-medium mb-0">
            {{ createdCongratulation }}
          </h1>
        </v-col>

        <template v-if="isLegalWorkspace">
          <v-col
            cols="12"
            class="workspace-created-box mb-16"
          >
            <div class="workspace-created-box">
              <div class="workspace-created-box__content">
                <div class="workspace-created-box__ico">
                  <IcoPeople />
                </div>
                <div
                  class="workspace-created-box__box"
                  v-html="$t('workspace.team.add_member.legalHtml')"
                ></div>
              </div>

              <div class="workspace-created-box__button">
                <Button
                  @click="
                    $router.push({
                      name: 'workspaceSettings',
                      hash: '#team',
                      params: { workspace_id: $route.params.workspace_id },
                    })
                  "
                  type="primary"
                >
                  {{ $t('workspace.team.invite_members') }}
                </Button>
              </div>
            </div>
          </v-col>

          <v-col
            cols="12"
            class="text-center"
          >
            <v-btn
              text
              class="text-decoration-underline"
              @click="continueToDashboard"
            >
              {{ $t('contract.back_to_dashboard') }}
            </v-btn>
          </v-col>
        </template>

        <template v-else>
          <v-col
            cols="12"
            class="pb-5 pt-2 text-center"
          >
            <v-btn
              color="primary"
              min-width="12rem"
              x-large
              @click="continueToDashboard"
            >
              {{ continueButtonText }}
            </v-btn>
          </v-col>
        </template>
      </v-row>
    </v-card>
  </Data>
</template>

<script>
import { mapGetters } from 'vuex';
import IcoPeople from '@/components/svg/IcoPeople.vue';
import IcoWorkspaceCreated from '@/components/svg/IcoWorkspaceCreated.vue';

export default {
  name: 'WorkspaceCreated',
  components: {
    IcoPeople,
    IcoWorkspaceCreated,
  },
  created() {
    window.dataLayer.push({ event: 'workspace_new' });
  },
  computed: {
    ...mapGetters({
      profile: 'profileWorkspaces',
    }),
    createdCongratulation() {
      return this.isFirstWorkspace ? this.$t('general.congratulations') : this.$t('workspace.created');
    },
    continueButtonText() {
      return this.isFirstWorkspace ? this.$t('general.finish') : this.$t('general.continue');
    },
    isFirstWorkspace() {
      return this.profile && 1 === this.profile.length && this.profile[0].id === this.workspaceId;
    },
    isLegalWorkspace() {
      const newWorkspace = this.profile.find((workspace) => workspace.id === this.workspaceId);

      return newWorkspace && 'legal' === newWorkspace.type;
    },
    workspaceId() {
      return this.$route.params.workspace_id;
    },
  },
  methods: {
    continueToDashboard() {
      return this.$router.push({
        name: 'dashboard',
        params: {
          workspace_id: this.workspaceId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/mixins';

.workspace__box {
  border: 1px solid #d4dff0;
}

.workspace-created-box {
  position: relative;
  background: #fffad1;
  box-shadow:
    0 3px 3px -2px rgb(0 0 0 / 10%),
    0px 3px 4px 0px rgb(0 0 0 / 8%),
    0px 1px 8px 0px rgb(0 0 0 / 8%) !important;
  max-width: 770px;
  margin: 0 auto;
  padding: 30px 30px 60px 30px;
  border-radius: 6px;

  @media screen and (max-width: 545px) {
    padding: 30px 30px 30px 30px;
  }

  &__content {
    @include clearfix;
  }

  &__ico {
    float: left;

    @include display(960px) {
      float: none;
    }
  }

  &__box {
    color: #614909;
    font-size: 17px;
    font-weight: 300;
    text-align: center;
    padding-left: 80px;
    line-height: 1.7;

    @include display(960px) {
      margin-top: 30px;
      padding-left: 0;

      br {
        display: none;
      }
    }
  }

  &__button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    transform: translate(0, 50%);

    @media screen and (max-width: 545px) {
      position: static;
      margin-top: 30px;
      transform: translate(0, 0);
    }
  }
}
</style>
