import router from '@/app/plugins/router';
import { internalApiRequest } from '@/shared/services/apiService';

/**
 * @param {Number|String} contractId
 * @param {Number|String} workspaceId
 * @return {Promise<AxiosResponse<*>>}
 */
export const fetchForFirstStep = ({ contractId, workspaceId }) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v1/contracts/${contractId}?workspace_id=${workspaceId}&with=1st_step&forceBaseContract=1`,
  });
};

/**
 * @type {(function({attempt: *, contractId: *, currentRouteName: *, next: *, catchError: *}): void)|*}
 * @param {Object} payload
 * @param {Number} payload.attempt
 * @param {Number|String} payload.mainContractId
 * @param {String} payload.currentRouteName
 * @param {Function} payload.next
 * @param {Function} payload.catchError
 */
export const fetchAvailableEnvelopSignTypes = (function () {
  return function ({ attempt = 10, mainContractId, currentRouteName, next, catchError }) {
    return internalApiRequest({
      method: 'GET',
      path: `api/v1/contracts/${mainContractId}/enum/signTypes`,
    })
      .then((resp) => next(resp))
      .catch((err) => {
        if (424 === err.response.status) {
          return catchError(err?.response?.data);
        }

        if (router.currentRoute.name === currentRouteName && 0 < attempt) {
          return setTimeout(function () {
            fetchAvailableEnvelopSignTypes({
              attempt: attempt - 1,
              mainContractId,
              currentRouteName,
              next,
              catchError,
            });
          }, 2500);
        } else {
          catchError(err?.response?.data);
        }
      });
  };
})();

/**
 * @param {Number|String} contractId
 * @param {String} searchTerm
 * @return {Promise<AxiosResponse<*>>}
 */
export const fetchCounterParties = ({ contractId, searchTerm }) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v1/contracts/${contractId}/users?q=${searchTerm}`,
  });
};

/**
 * @param {Number|String} contractId
 * @param {Object} data
 * @return {Promise<AxiosResponse<*>>}
 */
export const update = ({ contractId, data }) => {
  return internalApiRequest({
    method: 'PUT',
    path: `api/v1/contracts/${contractId}`,
    data,
  });
};

/**
 * @param {String|Number} contractId
 * @param {Object[]} attachments
 * @return {Promise<AxiosResponse<*>>}
 */
export const addAttachments = (docsId, attachments) => {
  return internalApiRequest({
    method: 'POST',
    path: `api/v1/docs/${docsId}/contracts`,
    data: attachments,
  });
};

/**
 * @param {Number|String} contractId
 * @param {Boolean} completely
 * @return {Promise<AxiosResponse<*>>}
 */
export const erase = ({ contractId, completely = true }) => {
  return internalApiRequest({
    method: 'DELETE',
    path: `api/v1/contracts/${contractId}?delete_complete=${completely ? '1' : '0'}`,
  });
};
