import WorkspaceCreate from '@/features/workspace/views/WorkspaceCreate';
import WorkspaceCreated from '@/features/workspace/views/WorkspaceCreated';
import WorkspaceSettings from '@/features/workspace/views/WorkspaceSettings';
import store from '@/store';

const routes = [
  {
    path: '/workspace/create',
    name: 'workspaceNew',
    component: WorkspaceCreate,
    beforeEnter: (to, from, next) => {
      store.dispatch('getUserProfile').then(() => {
        if (store.getters['profileHasDisabledCreatingWorkspace']) {
          next({ name: 'dashboard' });
        } else {
          next();
        }
      });
    },
  },
  {
    path: '/workspace/:workspace_id/created',
    name: 'workspaceCreated',
    component: WorkspaceCreated,
  },
  {
    path: '/workspace/:workspace_id/settings',
    name: 'workspaceSettings',
    component: WorkspaceSettings,
  },
];

routes.forEach((route) => {
  route.meta = {
    authRequired: true,
    layout: () => import('@/layouts/EmptyLayout.vue'),
  };
});

export default routes;
