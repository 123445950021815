import router from '@/app/plugins/router';
import store from '@/store';

// auto logout if 401 response returned from api
export function autologoutIfUnauthorized(response) {
  if (response.status === 401) {
    if (!store.getters['profile']) {
      return router.push({ name: 'dashboard' });
    }

    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    const urlBeforeLogin = window.location.href;

    if (urlBeforeLogin.indexOf('redirect_to') !== -1) {
      // there might be multiple parallel API requests, redirect only first one
      return;
    }

    if (isRoutePublicPage(router.currentRoute.name)) {
      return;
    }

    return router.push({
      name: router.currentRoute.query['loginPage'] ? `${router.currentRoute.query['loginPage']}-login` : 'login',
      query: {
        redirect_to: urlBeforeLogin,
      },
    });
  }
}

export function isRoutePublicPage(route) {
  return [
    'login',
    'passwordReset',
    'public-sign-intro',
    'public-sign-reject',
    'contractExpiredLink',
    'contractView',
    'contractData',
    'contract2FAStart',
    'contract2FASubmit',
    'signing-completed',
    'contractSign',
    'register',
    'registerEmail',
    'passwordForgotten',
    'registerToConfirm',
    'registrationConfirm',
    'notFound',
    'public-sign-delegated',
    'success-sign-sms',
  ].includes(route);
}
