import { authHeader } from '@/config/authHeader';
import { environment } from '@/config/environment';
import { apiAuthorizedRequest } from '@/services/ApiService';
import { internalApiRequest } from '@/shared/services/apiService';
import axios from 'axios';
import * as types from './mutation_types';

export const fetchSettings = ({ commit }, workspaceId) => {
  commit(types.SET_WORKSPACE_SETTINGS, null);

  return axios
    .get(`${environment.getApiUrl()}/api/v1/workspaces/${workspaceId}`, {
      headers: authHeader(),
    })
    .then((resp) => {
      commit(types.SET_WORKSPACE_SETTINGS, resp.data);
    })
    .catch((err) => {
      commit(types.SET_WORKSPACE_SETTINGS, null);

      throw err;
    });
};

export const fetchWorkspaceMembersPositions = ({ commit }, workspaceId) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v2/workspace/${workspaceId}/userWorkspace/positions`,
  })
    .then((resp) => {
      commit(types.SET_MEMBERS_JOB_POSITIONS, resp);

      return resp;
    })
    .catch((err) => {
      commit(types.SET_MEMBERS_JOB_POSITIONS, []);

      throw err;
    });
};

export const setActiveWorkspace = ({ commit }, workspace) => {
  commit(types.SET_ACTIVE_WORKSPACE, workspace);
};

export const fetchWorkspaceSignatureFooterSettings = ({ commit }, workspaceId) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v2/workspace/${workspaceId}/contract/settings/sign/footer`,
  }).then((resp) => {
    commit(types.SET_WORKSPACE_SIGNATURE_FOOTER_SETTINGS, resp);
  });
};

export const saveWorkspaceSignatureFooterSettings = ({ commit }, { workspaceId, data }) => {
  return internalApiRequest({
    method: 'POST',
    path: `api/v2/workspace/${workspaceId}/contract/settings/sign/footer`,
    data,
  }).then((resp) => {
    commit(types.SET_WORKSPACE_SIGNATURE_FOOTER_SETTINGS, resp);
  });
};

export const fetchBankIdSettings = (context, workspaceId) => {
  return apiAuthorizedRequest('GET', `api/v2/workspace/${workspaceId}/settings/bankId`).then((resp) => {
    return resp;
  });
};

export const setBankIdSettings = (context, payload) => {
  return apiAuthorizedRequest(
    'PATCH',
    `api/v2/workspace/${payload.workspaceId}/settings/bankId`,
    payload.workspaceBankIdSettings,
  ).then((resp) => {
    return resp;
  });
};

export const deleteBankIdSettings = (context, workspaceId) => {
  return apiAuthorizedRequest('DELETE', `api/v2/workspace/${workspaceId}/settings/bankId`).then((resp) => {
    return resp;
  });
};
