import { render, staticRenderFns } from "./ContractSignIdentitiesManagementAdd.vue?vue&type=template&id=b7f1aa66&scoped=true"
import script from "./ContractSignIdentitiesManagementAdd.vue?vue&type=script&lang=js"
export * from "./ContractSignIdentitiesManagementAdd.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7f1aa66",
  null
  
)

export default component.exports