<template>
  <div class="tutorial-progress-bar">
    <span v-if="item === 0">{{ item }} / {{ outOf }}</span>
    <div
      class="tutorial-progress"
      :style="{ width: `${100 * (item / outOf)}%`, background: backgroundColor }"
    >
      <span v-if="item > 0">{{ item }} / {{ outOf }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TutorialProgressBar',
  props: {
    item: {
      type: Number,
      required: true,
    },
    outOf: {
      type: Number,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '#6DECAF',
    },
  },
};
</script>
<style lang="scss">
.tutorial-progress-bar {
  height: 20px;
  border-radius: 20px;
  width: 100%;
  background: #e0e3e8;
  color: gray;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  span {
    font-weight: bold;
  }
  .tutorial-progress {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 14px;
    width: 25%;
    height: 20px;
    background: rgb(109, 236, 175);
    border-radius: 0 20px 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    span {
      color: #1b6c45;
      font-weight: bold;
    }
  }
}
</style>
