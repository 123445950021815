import Vue from 'vue';

export const fitFont = (el, maxims) => {
  const relFontsize = el.offsetWidth * 0.08;
  el.style.fontSize = Math.max(maxims[0], Math.min(maxims[1], relFontsize)) + 'px';
  el.style.opacity = '1';
};
// Initialize the annoying-background directive.
const fitText = {
  // eslint-disable-next-line no-unused-vars
  bind(el, { value }, vnode) {
    setTimeout(() => {
      fitFont(el, value);
    }, 0);
  },
  unbind() {},
};

Vue.directive('fit-text', fitText);
