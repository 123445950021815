<template>
  <div>
    <v-badge
      v-if="hasVerification"
      avatar
      overlap
    >
      <template v-slot:badge>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-avatar
              v-on="on"
              slot="activator"
              :color="hasExpiredVerification ? 'error' : 'success'"
              :size="avatarSize"
            >
              <v-icon>
                {{ `mdi-${hasExpiredVerification ? 'close' : 'check'}` }}
              </v-icon>
            </v-avatar>
          </template>

          <span>{{ description }}</span>
        </v-tooltip>
      </template>

      <SAvatar
        :firstname="contact.firstname"
        :lastname="contact.lastname"
        :position="contact.variable_position"
        :size="avatarSize"
      />
    </v-badge>

    <SAvatar
      v-else
      :firstname="contact.firstname"
      :lastname="contact.lastname"
      :position="contact.variable_position"
      :size="avatarSize"
    />
  </div>
</template>

<script>
import { computed, defineComponent, getCurrentInstance } from 'vue';
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions';
import SAvatar from '@/common/components/SAvatar';

export default defineComponent({
  name: 'VerificationBadge',
  components: {
    SAvatar,
  },
  props: {
    avatarSize: {
      type: Number,
      default: 50,
    },
    contact: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const instance = getCurrentInstance();
    const hasVerification = computed(() => props.contact?.valid_auths?.length > 0);
    const hasExpiredVerification = computed(() => false);
    const description = computed(() => {
      return `${instance.proxy.$t(
        `auth.group.default.${
          'undefined' === typeof props.contact?.valid_auths?.bankId ? 'signi_verification' : 'bank_id'
        }`,
      )} - ${
        instance.proxy.$t('general.valid_until').charAt(0).toLowerCase() +
        instance.proxy.$t('general.valid_until').slice(1)
      } ${getFormattedDateByLocale({
        date: props.contact?.valid_auths[0].valid_to,
      })}`;
    });

    return {
      description,
      hasVerification,
      hasExpiredVerification,
    };
  },
});
</script>

<style lang="scss" scoped></style>
