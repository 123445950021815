<template>
  <v-tooltip
    v-if="hasArchiveStateStatus"
    top
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        color="#B3C2D6"
        :small="small"
        v-bind="attrs"
        v-on="on"
        >{{ stateIcon }}</v-icon
      >
    </template>

    <span>{{ stateInfo }}</span>
  </v-tooltip>
</template>

<script>
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions';

export default {
  name: 'ArchiveState',
  props: {
    archiveState: {
      type: Object,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      archiveStateIcons: {
        ready_to_archive: 'archive-arrow-up-outline',
        archived: 'archive-check-outline',
        expired: 'archive-alert-outline',
        deleted: 'archive-remove-outline',
        send_error: 'archive-sync-outline',
        without_archive_to_trash: 'archive-cancel-outline',
        without_archive_in_trash: 'archive-minus-outline',
      },
    };
  },
  computed: {
    archiveStateStatus() {
      return this.archiveState?.status;
    },
    documentArchivingExpirationDate() {
      return getFormattedDateByLocale({
        date: this.archiveState?.document_expiration_date,
      });
    },
    hasArchiveStateStatus() {
      return !!this.archiveState?.status;
    },
    stateIcon() {
      return this.hasArchiveStateStatus ? `mdi-${this.archiveStateIcons[`${this.archiveStateStatus}`]}` : null;
    },
    stateInfo() {
      return this.hasArchiveStateStatus ? this.$t(`contract.archive.${this.archiveStateStatus}`) : null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
