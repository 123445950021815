<template>
  <v-main :style="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'max-width: 75%' : ''">
    <p
      class="font-weight-regular mt-md-0 mt-5"
      style="max-width: 440px"
    >
      {{ $t('workspace.team.add_member.help') }}
    </p>

    <h2 class="font-weight-medium pt-1">
      {{ $t('workspace.team.add_member') }}
    </h2>

    <InvitationForm
      v-model="invitation"
      :is-submitted="submitted"
      @success-changes="onCreateInvitation"
    />

    <h2 class="font-weight-medium pb-1 pt-3">
      {{ $t('workspace.members') }}
    </h2>

    <WorkspaceMembers
      :workspace="workspace"
      @reload-workspace="onReloadWorkspace"
    />
  </v-main>
</template>

<script>
import { mapActions } from 'vuex';
import { createInvitation } from '@/features/workspace/services/workspaceMembersService';
import InvitationForm from '@/features/workspace/components/InvitationForm';
import WorkspaceMembers from '../components/WorkspaceMembers.vue';

export default {
  name: 'WorkspaceSettingsTeam',
  components: {
    InvitationForm,
    WorkspaceMembers,
  },
  props: {
    workspace: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      resendingInvitation: false,
      invitation: {
        all_contracts: false,
        all_contacts: true,
        email: '',
        position: '',
        rights: 'signer',
      },
      invitationDefaultConfig: {
        all_contracts: false,
        all_contacts: true,
        email: '',
        position: '',
        rights: 'signer',
      },
      submitted: false,
    };
  },
  computed: {
    workspaceId() {
      return this.$route.params.workspace_id;
    },
  },
  mounted() {
    this.fetchWorkspaceMembersPositions(this.$route.params.workspace_id);
  },
  methods: {
    ...mapActions({
      fetchWorkspaceMembersPositions: 'workspace/fetchWorkspaceMembersPositions',
    }),
    onReloadWorkspace() {
      this.$emit('reload-workspace');
    },
    onCreateInvitation() {
      this.searchTerm = '';
      this.submitted = true;

      createInvitation(
        Object.assign({}, this.invitation, {
          all_contracts: this.invitation.all_contracts ? 1 : 0,
          all_contacts: this.invitation.all_contacts ? 1 : 0,
          workspace_id: parseInt(this.workspaceId),
        }),
      )
        .then(() => {
          this.$emit('reload-workspace');
          this.invitation = Object.assign(this.invitationDefaultConfig);
        })
        .catch(() => {
          this.$notification.error(this.$t('workspace.invitation.not_possible'));
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
