<template lang="html">
  <label>
    <div
      class="input-label-wrap"
      v-if="label"
    >
      <span class="input__label">{{ label }}</span>
    </div>
    <div
      v-click-outside="closeSelect"
      :class="{
        'language-select-disabled': isDisabled,
        'language-select-opened': isSelectOpened,
        'language-select-form': label,
      }"
    >
      <div
        class="language-select-options"
        v-if="!isDisabled"
      >
        <ul>
          <li
            v-for="option in options"
            :key="option.locale"
            @click.stop="selectOption(option)"
          >
            <span class="language-select-option-flag">
              <LanguageFlag
                class="language-select-flag"
                :locale-data="option"
              />
            </span>
            <span class="language-select-option-country">{{ option.locale.toUpperCase() }}</span>
          </li>
        </ul>
      </div>
      <label>
        <div
          class="language-select-active"
          tabindex="0"
          @click.stop.prevent="toggleSelect"
        >
          <LanguageFlag
            class="language-select-flag"
            :locale-data="selectedLocale"
          />
          <span
            v-if="selectedLocale.locale"
            class="language-select-active-name"
          >
            {{ selectedLocale.locale.toUpperCase() }}
          </span>
          <span
            class="language-select-arrow"
            v-if="!isDisabled"
          >
            <span class="icon-bottom"></span>
          </span>
        </div>
      </label>
    </div>
  </label>
</template>

<script>
import { defineComponent } from 'vue';
import { getCurrentLocale } from '@/app/services/localeService';
import { getLocales } from '@/app/config/locales';
import LanguageFlag from '@/common/components/LanguageFlag';

export default defineComponent({
  name: 'LanguageSelect',
  components: {
    LanguageFlag,
  },
  data() {
    return {
      isSelectOpened: false,
      options: getLocales(this.onlyFullyLocalized),
    };
  },
  props: {
    locale: {
      default: getCurrentLocale(this),
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    onlyFullyLocalized: {
      type: Boolean,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    selectedLocale() {
      const selected = this.options.find((option) => option.locale === this.locale);
      return selected || this.options[0]; // fallback to avoid hidden select
    },
  },
  methods: {
    selectOption(option) {
      if (this.isDisabled) {
        return;
      }
      this.$emit('onLocaleChanged', option.locale);
      this.closeSelect();
    },
    closeSelect() {
      if (this.isDisabled) {
        return;
      }
      this.isSelectOpened = false;
    },
    toggleSelect() {
      if (this.isDisabled) {
        return;
      }
      this.isSelectOpened = !this.isSelectOpened;
    },
  },
});
</script>

<style lang="scss" scoped>
.language-select-form {
  position: relative;
  .language-select-active {
    margin: 1ex 0 20px;
  }
  .language-select-options {
    left: 75px;
    top: 54px;
    bottom: auto;
    max-height: 475px;
    overflow-y: auto;
  }
}

.language-select-active {
  margin-top: 20px;
  text-align: left;
  cursor: pointer;
  position: relative;
  width: 100px;
  outline: none;
  background: none;
  border: 1px solid #b1bbcb;
  border-radius: 6px; // copy-pasted from .button
  height: 50px;
  padding: 0 5px;
  font-size: 16px;
  letter-spacing: -0.03px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 54px;
  display: flex;
  align-items: center;
}

.language-select-active-name {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-left: 5px;
  color: black;
  font-size: 13px;
}

.language-select-arrow {
  font-size: 10px;
  position: absolute;
  right: 5px;
  transition: 0.2s;
  top: 50%;
  line-height: 54px;
  display: block;
  color: white;
}

.language-select-arrow .icon-bottom {
  display: block;
  top: 0;
  transform: translateY(-50%);
  position: absolute;
  right: 0;
  line-height: 54px;
}

.language-select-arrow .icon-bottom:before {
  color: #b1bbcb;
}

.language-select-options {
  bottom: 60px;
  display: none;
  background: white;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  border: solid 1px #5e239e;
  border-top: none;
  border-radius: 6px;
  max-height: 235px;
  overflow-y: scroll;
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  ul {
    li {
      list-style-type: none;
      border-top: solid 1px #d4dff0;
      padding: 10px 15px;
      color: #000000;
      font-weight: 300;
      transition: 0.2s;
      cursor: pointer;
      &:hover {
        background: #f2f6fc;
        transition: 0.2s;
      }
    }
  }
}

.language-select-disabled {
  pointer-events: none;
}

.language-select-opened {
  .language-select-options {
    display: block;
    z-index: 1001;
  }

  ul {
    padding-left: 0;
  }

  .icon-bottom {
    display: block;
    top: 0;
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
    position: absolute;
    right: 0;
    line-height: 54px;
  }
}

.language-select-options {
  .language-select-option-country {
    display: inline-block;
    vertical-align: text-bottom;
    line-height: 30px;
    font-size: 13px;
  }
  .language-select-option-flag {
    display: inline-block;
    width: 40px;
    height: 30px;
    margin-right: 5px;
    .language-select-flag {
      max-width: 40px;
      max-height: 30px;
    }
  }
}
.language-select-flag {
  width: 40px;
  max-height: 30px;
}
</style>
