<template>
  <v-dialog
    v-model="showDialog"
    max-width="35rem"
    width="100%"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        text
        block
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left>mdi-plus</v-icon>
        {{ $t('signature.add_signature_field') }}
      </v-btn>
    </template>

    <v-card>
      <v-toolbar
        class="pt-1"
        dense
        flat
      >
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="showDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-title class="px-md-5 pb-x pb-md-5 px-2">
        <h2 class="headline font-weight-medium mb-0 text-center">
          {{ $t('contract.signs.sign_help') }}
        </h2>
      </v-card-title>

      <v-card-text>
        <v-select
          v-model="selectedItemId"
          item-value="id"
          item-text="name"
          :items="availableSignatories"
          :placeholder="$t('contract.signs.signer_placeholder')"
          outlined
          @change="editSignatoryIdentities"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ContractHelper } from '@/helpers/ContractHelper';

export default {
  name: 'ContractSignIdentitiesManagementAdd',
  props: {
    availableSignatories: {
      type: Array,
      default: () => [],
    },
    unsignedSignatures: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedItemId: null,
      showDialog: false,
    };
  },
  methods: {
    async editSignatoryIdentities() {
      const selectedSignIdentity = this.unsignedSignatures.find((item) => {
        return item.signIdentity.id === this.selectedItemId;
      });

      if (selectedSignIdentity) {
        ContractHelper.editContractRole(selectedSignIdentity.signIdentity, 'sign');
      }

      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
