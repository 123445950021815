import { fetchNews } from '@/services/NewsService';
import * as types from './mutation_types';

export const fetchNewsMsg = ({ state, commit }) => {
  if (state.messages.length !== 0) {
    return;
  }

  return fetchNews().then((messages) => {
    commit(types.SET_APP_NEWS, messages);
  });
};
