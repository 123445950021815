import { environment } from '@/config/environment';
import { internalApiRequest } from '@/shared/services/apiService';
import axios from 'axios';

const postRequest = ({ endpoint, data = null, method = 'POST' }) => {
  return axios.request({
    method,
    url: `${environment.getApiUrl()}/${endpoint}`,
    data,
  });
};

export const login = ({ credentials }) => {
  return postRequest({
    endpoint: 'api/v1/login',
    data: credentials,
  });
};

/**
 * @return {Promise<AxiosResponse<*>>}
 */
export function logout() {
  return internalApiRequest({
    method: 'POST',
    path: 'api/v1/logout',
  });
}

export const createAccount = ({ accountSettings }) => {
  return postRequest({
    endpoint: 'api/v1/register',
    data: accountSettings,
  });
};

export const confirmAccount = ({ confirmToken }) => {
  return postRequest({
    endpoint: 'api/v1/confirmemail',
    data: {
      token: confirmToken,
    },
  });
};

export const requestResetPassword = ({ email }) => {
  return postRequest({
    endpoint: 'api/v1/forgottenpassword',
    data: {
      email,
      return_url: `${environment.getAppUrl()}/password/reset`,
    },
  });
};

export const resetPassword = ({ credentials }) => {
  return postRequest({
    endpoint: 'api/v1/resetpassword',
    data: credentials,
  });
};

export const validatePromoCode = ({ promoCode }) => {
  return postRequest({
    method: 'GET',
    endpoint: `api/v1/register/validatePromoCode?promoCode=${promoCode}`,
  });
};

export const createWorkspaceCheck = ({ email }) => {
  return postRequest({
    endpoint: 'api/v1/register/check',
    data: {
      email,
    },
  })
    .then((resp) => {
      return {
        createWorkspace: 'new' === resp?.data?.emailStatus || false,
        emailAlreadyUsed: 'confirmed' === resp?.data?.emailStatus || false,
      };
    })
    .catch((err) => {
      throw err?.response?.data;
    });
};
