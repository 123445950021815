<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>ico-N/ico-24-edit</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
      id="ico-N/ico-24-edit"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        d="M9.57467732,7.24134399 L15.0746773,7.24134399 L15.0746773,16.6201335 L12.4627564,21.5769324 L9.57467732,16.6439873 L9.57467732,7.24134399 Z M11.606946,6.13023288 L11.606946,6.02467732 C11.606946,6.09497832 11.579981,6.13023288 11.4375762,6.13023288 L11.606946,6.13023288 Z M13.0217484,6.13023288 L13.2075008,6.13023288 C13.088914,6.13023288 13.0115125,6.08117533 12.9935545,6.06391471 C13.0048387,6.08792193 13.0139111,6.10953078 13.0217484,6.13023288 Z M12.9935545,6.06391471 C12.9917181,6.06214959 12.9905033,6.060717 12.9899297,6.05970339 C12.9916506,6.06274421 12.9933525,6.06588578 12.9950328,6.06910946 Z M9.60694605,6.91912177 C9.60694605,6.39816561 9.60694605,6.39816561 9.60694605,6.02467732 C9.60694605,5.08959897 10.340734,4.13023288 11.4375762,4.13023288 C12.2892739,4.13023288 12.2892739,4.13023288 13.2075008,4.13023288 C14.4150211,4.13023288 15.0502048,5.25260448 15.0502048,6.214287 C15.0502048,6.51857489 15.0502048,6.51857489 15.0502048,6.91912177 L15.0502048,7.91912177 L9.60694605,7.91912177 L9.60694605,6.91912177 Z"
        id="Combined-Shape"
        stroke="#B4C1D6"
        stroke-width="2"
        fill-rule="nonzero"
        transform="translate(12.324677, 12.324677) rotate(-315.000000) translate(-12.324677, -12.324677) "
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoEdit',
};
</script>
