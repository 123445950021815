<template lang="html">
  <Modal
    @close="close"
    :enableClose="true"
  >
    <template v-if="isLoading">
      <Loader
        class="register-loader"
        style="text-align: center; padding-left: 0"
      />
    </template>
    <template v-else>
      <h2 class="bigger-h2 new-user-title">{{ $t('contract.signs.load_from_history') }}</h2>
      <div class="party-modal-header">
        <div
          class="available-user__box"
          v-if="contracts.length"
        >
          <div
            v-for="lastDoc in contracts"
            :key="lastDoc.id"
            @click="selectContract(lastDoc)"
            class="available-users"
          >
            <div class="available-user__ico">
              <span class="icon-circle-plus"></span>
            </div>
            <div class="available-user__data">
              <div class="available-user__name">{{ lastDoc.title }}</div>
              <div class="available-user__workspace">
                <span class="available-user__label">{{ prettyDate(lastDoc.created_at) }}</span>
                <span class="available-user__label">{{
                  lastDoc.is_attachment ? $t('contract.type.attachment') : $t('contract.type.base_contract')
                }}</span>
                <span
                  class="available-user__label"
                  v-if="contract.type == 'doc'"
                  >{{ lastDoc.file_name }}</span
                >
                <span
                  v-for="(position, index) in lastDoc.positions"
                  :key="index"
                  class="available-user__label"
                >
                  {{ position.is_proposer ? $t('contract.proposer') : $t('contract.counterparty') }}
                  <span class="icon-round-check"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <p v-else>
          {{ $t('contract.signs.load_from_history.not_found') }}
        </p>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Loader from '@/components/Loader.vue';
import { ContractService } from '@/services/ContractService.js';
import { parseDate } from '@/services/helpers/parseDate.js';

export default {
  name: 'SignaturePositionsModal',
  components: {
    Modal,
    Loader,
  },
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      contracts: [],
    };
  },
  created() {
    this.findLastDocuments();
  },
  methods: {
    findLastDocuments() {
      this.isLoading = true;
      ContractService.getLastDocuments(this.contract.workspace_id, this.contract.type).then(
        (response) => {
          this.contracts = response;
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    close() {
      this.$emit('close');
    },
    selectContract(contract) {
      this.$emit('select', contract.positions);
      this.$emit('close');
    },
    prettyDate(date) {
      return date ? parseDate(date) : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.available-user__label {
  display: inline-block;
  border-radius: 5px;
  padding: 3px;
  background: #ffffff;
  margin: 1ex 1ex 0 0;
}
</style>
