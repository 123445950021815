import { internalApiRequest } from '@/shared/services/apiService';

const mutationsTypes = {
  ADD_SETTINGS_TEMPLATES: 'ADD_SETTINGS_TEMPLATES',
  DELETE_SETTINGS_TEMPLATES: 'DELETE_SETTINGS_TEMPLATES',
  SET_SETTINGS_TEMPLATES_LIST: 'SET_SETTINGS_TEMPLATES_LIST',
};

export const namespaced = true;

export const state = {
  settingsTemplatesList: [],
};

export const mutations = {
  [mutationsTypes.ADD_SETTINGS_TEMPLATES](state, payload) {
    state.settingsTemplatesList.push(payload);
  },
  [mutationsTypes.DELETE_SETTINGS_TEMPLATES](state, templateId) {
    state.settingsTemplatesList = state.settingsTemplatesList.filter((template) => template.id !== templateId);
  },
  [mutationsTypes.SET_SETTINGS_TEMPLATES_LIST](state, list) {
    state.settingsTemplatesList = [...list];
  },
};

export const actions = {
  fetchSettingsTemplatesList: ({ commit }, workspaceId) => {
    return internalApiRequest({
      method: 'GET',
      path: `api/v2/workspace/${workspaceId}/contract/settings/template/list`,
    }).then((resp) => {
      commit(mutationsTypes.SET_SETTINGS_TEMPLATES_LIST, resp);
    });
  },
  createNewSettingsTemplate: ({ commit }, { workspaceId, data }) => {
    return internalApiRequest({
      method: 'POST',
      path: `api/v2/workspace/${workspaceId}/contract/settings/template`,
      data,
    }).then((resp) => {
      commit(mutationsTypes.ADD_SETTINGS_TEMPLATES, resp);
    });
  },
  deleteSettingsTemplate: ({ commit }, { workspaceId, templateId }) => {
    return internalApiRequest({
      method: 'DELETE',
      path: `api/v2/workspace/${workspaceId}/contract/settings/template/${templateId}`,
    }).then(() => {
      commit(mutationsTypes.DELETE_SETTINGS_TEMPLATES, templateId);
    });
  },
};

export const getters = {
  list: (state) => {
    return state.settingsTemplatesList || [];
  },
};
