import { authorizedRequest } from '@/services/ApiService';

export const namespaced = true;

export const state = {
  list: [],
};

export const mutations = {
  setCreditPackages(state, packages) {
    state.list = packages;
  },
};

export const actions = {
  fetchCreditPackages: (context, currency = '') => {
    return authorizedRequest({
      method: 'GET',
      endpoint: `/api/v1/credit${currency !== '' ? `/${currency}` : ''}`,
    })
      .then((resp) => {
        context.commit('setCreditPackages', resp);
      })
      .catch(() => {
        return authorizedRequest({
          method: 'GET',
          endpoint: `/api/v1/credit/EUR`,
        })
          .then((resp) => {
            context.commit('setCreditPackages', resp);
          })
          .catch(() => {
            context.commit('setCreditPackages', []);
          });
      });
  },
};

export const getters = {
  list: (state) => {
    return state.list;
  },
  isDiscountVisible: (state) => {
    return (
      state.list.filter((c) => {
        return c.credit_original > 0;
      }).length > 0
    );
  },
};
