const routes = [
  {
    path: '/dashboard/workspace/:workspace_id/contacts',
    name: 'contacts',
    component: () => import('./views/Contacts.vue'),
  },
  {
    path: '/dashboard/workspace/:workspace_id/contacts/:contact_id',
    name: 'contactDetail',
    component: () => import('./views/ContactDetail.vue'),
  },
];

export default routes;
