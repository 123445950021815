<script setup>
import { getCurrentInstance, ref, computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import { usePaymentMethodsStore } from '@/features/workspace/stores/paymentMethods';

const app = getCurrentInstance();
const route = useRoute();
const paymentMethodsStore = usePaymentMethodsStore();

const props = defineProps({
  value: {
    type: Boolean,
    required: true,
  },
  paymentMethod: {
    type: Object,
    required: false,
  },
});

const emits = defineEmits(['input']);

const defaultPaymentMethodValues = {
  methodType: '',
  country: '',
  iban: '',
  bic: '',
};
const paymentMethod = ref(props.paymentMethod || defaultPaymentMethodValues);
const availableMethodTypes = ref([
  {
    text: app.proxy.$t('workspace.payment_method.qr_payment'),
    value: 'qr_pay',
  },
  {
    text: 'PayU',
    value: 'payu',
  },
]);
const availableCountries = ref([
  {
    text: 'CZ',
    value: 'CZ',
  },
  {
    text: 'SK',
    value: 'SK',
  },
]);

const workspaceId = computed(() => route.params.workspace_id);
const showDialog = computed({
  get() {
    return props?.value;
  },
  set(val) {
    emits('input', val);
  },
});

function closeDialog() {
  paymentMethod.value = props.paymentMethod || defaultPaymentMethodValues;
  showDialog.value = false;
}

async function saveWorkspacePaymentMethod() {
  try {
    await paymentMethodsStore.saveWorkspacePaymentMethod(workspaceId.value, {
      methodType: paymentMethod.value?.methodType,
      country: paymentMethod.value?.country,
      iban: 'qr_pay' === paymentMethod.value?.methodType ? paymentMethod.value?.iban : null,
      bic: 'qr_pay' === paymentMethod.value?.methodType ? paymentMethod.value?.bic : null,
    });
    await paymentMethodsStore.fetchPaymentMethods(workspaceId.value);
    app.proxy.$notification.success(app.proxy.$t('workspace.payment_method.created'));
  } catch (e) {
    await paymentMethodsStore.fetchPaymentMethods(workspaceId.value);
    app.proxy.$notification.error(app.proxy.$t('workspace.payment_method.create_error'));
  } finally {
    closeDialog();
  }
}
</script>

<template>
  <v-dialog
    v-model="showDialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title class="text-center">
        {{ $t('workspace.payment_method.create') }}
      </v-card-title>

      <v-card-text>
        <form>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="paymentMethod.methodType"
                  :items="availableMethodTypes"
                  :label="$t('workspace.payment_method.method_type')"
                  hide-details
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="paymentMethod.country"
                  :items="availableCountries"
                  :label="$t('general.country')"
                  hide-details
                  outlined
                ></v-select>
              </v-col>
              <v-col
                v-if="paymentMethod.methodType === 'qr_pay'"
                cols="12"
              >
                <v-text-field
                  v-model="paymentMethod.iban"
                  :label="$t('workspace.payment_method.iban')"
                  hide-details
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                v-if="paymentMethod.methodType === 'qr_pay'"
                cols="12"
              >
                <v-text-field
                  v-model="paymentMethod.bic"
                  :label="$t('workspace.payment_method.bic')"
                  hide-details
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card-text>

      <v-card-actions class="pa-5 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          min-width="6rem"
          @click="closeDialog()"
        >
          {{ $t('general.cancel') }}
        </v-btn>
        <v-btn
          text
          color="primary"
          min-width="8rem"
          @click="saveWorkspacePaymentMethod()"
        >
          {{ $t('general.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped></style>
