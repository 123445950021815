const routes = [
  {
    path: '/access/forgotten-password',
    alias: '/password-forgotten',
    name: 'passwordForgotten',
    component: () => import('./views/ForgottenPassword.vue'),
  },
  {
    path: '/access/forgotten-password/done',
    name: 'passwordForgottenDone',
    component: () => import('./views/ForgottenPasswordDone.vue'),
  },
  {
    path: '/access/forgotten-password/reset/:token',
    alias: '/password/reset/:token',
    name: 'passwordReset',
    component: () => import('./views/ResetForgottenPassword.vue'),
  },
  {
    path: '/access/forgotten-password/reset/:token/done',
    name: 'passwordResetDone',
    component: () => import('./views/ResetForgottenPasswordDone.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('./views/Registration.vue'),
  },
  {
    path: '/register/email/:register_email',
    name: 'registerEmail',
    redirect: (to) => ({
      name: 'register',
      query: {
        email: to.params.register_email,
        code: to.query.code,
      },
    }),
  },
  {
    path: '/register/:locale',
    name: 'registerLocale',
    redirect: (to) => ({
      name: 'register',
      query: {
        locale: to.params.locale,
        code: to.query.code,
      },
    }),
  },
  {
    path: '/register/to/:email/confirm',
    name: 'registerToConfirm',
    component: () => import('./views/RegistrationDone.vue'),
  },
  {
    path: '/registration/confirm/:token',
    name: 'registrationConfirm',
    component: () => import('./views/ConfirmRegistration.vue'),
  },
];

routes.forEach((route) => {
  route.meta = {
    authRequired: false,
    fullLayout: true,
    layout: () => import('@/layouts/AccessLayout.vue'),
  };
});

export default routes;
