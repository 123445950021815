<template>
  <div
    v-if="tasks.length > 0"
    class="tutorial-profile-finish__wrapper"
  >
    <div class="tutorial-profile-finish__top">
      <div class="tutorial-profile-finish__heading">
        {{ $t('tutorial.progress.finish') }}
      </div>
      <div class="tutorial-profile-finish__progress">
        <div>
          {{ $t('tutorial.progress.current') }}
        </div>
        <div>
          <TutorialProgressBar
            :item="finished"
            :outOf="tasks.length"
          />
        </div>
      </div>
    </div>
    <div class="tutorial-profile-finish__content">
      <div
        v-for="(task, index) in tasks"
        :key="index"
        @click.prevent.stop="navigate(task.routeLink)"
        :class="{ 'tutorial-step-passed': task.isCompleted }"
      >
        <p>{{ index + 1 }}. {{ task.title }}</p>
      </div>
    </div>
    <div
      @click="hideProfileFinish"
      class="tutorial-progress-close-wrapper"
    >
      <IcoClose />
    </div>
  </div>
</template>

<script>
import TutorialProgressBar from '../components/TutorialProgressBar';
import IcoClose from '@/components/svg/IcoClose';

export default {
  name: 'TutorialProfileFinish',
  props: ['finished', 'tasks', 'profile'],
  components: {
    TutorialProgressBar,
    IcoClose,
  },
  methods: {
    setCompletenessLocalState(key, value = null) {
      const completenessLocalState = localStorage.getItem('completenessLocalState');
      let parsedCompletenessLocalState;
      try {
        parsedCompletenessLocalState = JSON.parse(completenessLocalState);
      } catch (e) {}

      parsedCompletenessLocalState = parsedCompletenessLocalState || {};

      if (value) {
        if (!parsedCompletenessLocalState[key]) {
          parsedCompletenessLocalState[key] = [];
        }
        parsedCompletenessLocalState[key] = [...parsedCompletenessLocalState[key], value];
      } else {
        parsedCompletenessLocalState = { ...parsedCompletenessLocalState, [key]: true };
      }

      localStorage.setItem('completenessLocalState', JSON.stringify(parsedCompletenessLocalState));
    },
    navigate(routeLink) {
      this.$router.push(routeLink);

      if (routeLink.name === 'workspaceSettings') {
        this.setCompletenessLocalState('adaptWorkspaceClicked', this.profile.id);
      }
    },
    hideProfileFinish() {
      this.setCompletenessLocalState('skippedTutorial', this.profile.id);
      this.$emit('hideComponent');
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/mixins';

.tutorial-profile-finish__wrapper {
  background: #e9faf3;
  padding: 30px 50px;
  border-radius: 10px;
  color: darkgreen;
  width: 100%;
  position: relative;
  @include md {
    padding: 20px;
  }

  .tutorial-progress-close-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .tutorial-profile-finish__top {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    margin-bottom: 20px;
    @include md {
      flex-direction: column;
    }

    .tutorial-profile-finish__heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: black;
      font-size: 22px;
      width: 50%;
      @include md {
        width: 100%;
      }
    }

    .tutorial-profile-finish__progress {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;

      @include md {
        padding: 10px 0;
        flex-direction: column;
        width: 100%;
      }

      div {
        color: gray;
        font-size: 12px;
        font-weight: 100;
        width: 50%;
      }
      div:first-child {
        width: fit-content;
        margin-right: 10px;
        font-weight: 400;
        letter-spacing: -0.5px;

        @include md {
          margin: 0;
        }
      }
      div:nth-child(2) {
        flex: 2 1 0;
        div {
          width: 100%;
        }
      }
    }
  }

  .tutorial-profile-finish__content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @include md {
      flex-direction: column;
    }
    div {
      width: calc(100% / 3);
      padding-right: 20px;
      cursor: pointer;
      @include md {
        width: 100%;
      }
      p {
        font-weight: 400;
        color: black;
        text-decoration: underline;
      }
    }
    .tutorial-step-passed {
      cursor: default;
      // pointer-events: none;
      p {
        color: #b3b3b3;
      }
    }
  }
}
</style>
