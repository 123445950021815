<template>
  <SConfirmDialog
    v-model="showDialog"
    :message="`${$t('docs.bulk_actions.delete_pdf_question')}`"
    @confirmation-callback="onDeleteAction"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import SConfirmDialog from '@/common/components/SConfirmDialog';
import { performBulkDocumentsOperationDeletePdf } from '@/app/features/dashboard/services/bulkOperationsService';

export default {
  name: 'BulkDocumentsPdfsDeleteDialog',
  components: {
    SConfirmDialog,
  },
  props: {
    value: Boolean,
  },
  computed: {
    ...mapGetters({
      dashboardCheckedDocuments: 'dashboardCheckedDocuments',
    }),
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onDeleteAction() {
      const docsIds = this.dashboardCheckedDocuments.map((document) => document.docs_id);

      performBulkDocumentsOperationDeletePdf(this.$route.params.workspace_id, docsIds).then((resp) => {
        if (resp?.status) {
          const undeletedDocuments = resp?.filter((result) => 'failed' === result.status);

          if (undeletedDocuments?.length) {
            return (() => {
              this.$notification.error(this.$t('docs.bulk_action.delete_pdf.undone_documents'));
            })();
          }
        }

        this.$notification.success(this.$t('docs.bulk_action.delete_pdf.successfully_deleted'));

        this.$emit('refresh-table', resp?.batchId);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
