<template>
  <v-menu
    v-model="show"
    offset-y
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <article
        v-bind="attrs"
        v-on="on"
      >
        <v-avatar :size="35">
          <v-img
            :src="accountAvatar"
            :alt="accountName"
            contain
          />
        </v-avatar>
        <v-icon color="primary">
          {{ `mdi-chevron-${show ? 'up' : 'down'}` }}
        </v-icon>
      </article>
    </template>

    <v-card width="20rem">
      <v-list class="pb-0">
        <v-list-item
          @click="$router.push({ name: 'account' })"
          link
        >
          <v-list-item-avatar
            class="mr-3 px-2"
            size="55"
          >
            <img
              :src="accountAvatar"
              :alt="accountName"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              class="title font-weight-medium"
              style="color: #606771"
              v-text="accountName"
            ></v-list-item-title>
            <v-list-item-subtitle
              class="subtitle-2 font-weight-regular"
              v-text="accountCompanyName"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list class="pb-4 pt-0">
        <v-hover
          v-for="menuItem in menuItems"
          :key="menuItem.id"
          v-slot="{ hover }"
        >
          <v-list-item
            class="user-menu__item px-0"
            link
            @click="menuItem.callback"
          >
            <v-list-item-content class="font-weight-normal black--text">
              <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon>
              <v-icon :color="hover ? 'primary' : '#b3c2d6'"> mdi-chevron-right </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-hover>

        <v-hover v-slot="{ hover }">
          <v-list-item
            class="logout-button"
            link
            @click="onLogout"
          >
            <v-list-item-content class="black--text">
              {{ $t('logout') }}
            </v-list-item-content>
            <v-icon :color="hover ? 'error' : '#b3c2d6'">mdi-power</v-icon>
          </v-list-item>
        </v-hover>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import { goToLinkWithTarget } from '@/common/reusable/routing';
import { logout } from '@/features/access/services/accessService';
import { useProviderLogout } from '@/features/access/services/authenticationService';

export default {
  name: 'AppUserMenu',
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      isPartnersClient: 'isPartnersClient',
      workspaceById: 'workspaceById',
    }),
    accountAvatar() {
      if (this.profile?.avatar_url) {
        return this.profile.avatar_url;
      }

      return '/images/user.svg';
    },
    accountCompanyName() {
      return this.profile?.invoice_info?.invoice_organization_name || null;
    },
    accountName() {
      return `${this.profile?.firstname || ''} ${this.profile?.lastname || ''}`;
    },
    activeWorkspace() {
      return this.workspaceById(this.$route.params.workspace_id || localStorage.getItem('workspace_id'));
    },
    isOwnerOfWorkspace() {
      return this.activeWorkspace?.is_owner || false;
    },
    canSeeSubscription() {
      if (this.isPartnersClient) {
        return false;
      }

      return this.isOwnerOfWorkspace;
    },
    menuItems() {
      return [
        {
          callback: () => this.$router.push({ name: 'account' }),
          title: this.$t('account.settings'),
        },
        {
          callback: () => this.$router.push({ name: 'subscription' }),
          disabled: !this.canSeeSubscription,
          title: this.$t('subscription.payments_and_subscriptions'),
        },
        {
          callback: () => goToLinkWithTarget(this.$t('links.terms_and_conditions.registered')),
          title: this.$t('legal.terms'),
        },
        {
          callback: () => goToLinkWithTarget(this.$t('links.privacy_policy')),
          title: this.$t('legal.privacy'),
        },
      ]?.filter((i) => true !== i?.disabled);
    },
  },
  methods: {
    onLogout() {
      if (this.profile?.providers?.isGoogleType || this.profile?.providers?.isAzureType) {
        useProviderLogout();
      }

      logout().finally(() => {
        this.$router.push({ name: 'login' });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-menu__item {
  margin: 0 20px;
  border-bottom: 1px solid #d4dff0;
}

.logout-button {
  margin: 20px 20px 0;
  height: 47px;
  border-radius: 6px;
  background-color: #f2f6fc;
  overflow: hidden;
}
</style>
