<template>
  <div class="content-box__header">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ContentPanel',
};
</script>

<style lang="scss">
@import '@/assets/sass/mixins';

.content-box__header {
  width: calc(100vw - 20px);
  border: 1px solid #dfe7f3;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  z-index: 10;
  background: #fff;
  font-size: 16px;
  padding: 0;

  position: fixed;
  bottom: 0;
  top: auto;
  left: 10px;
  z-index: 20;

  box-shadow: 0 2px 54px 0 rgba(0, 0, 0, 0.2);
}

.content-box__header__back {
  padding: 10px 20px;
  border-bottom: 1px solid #dfe7f3;
  color: #9ca2ad;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;

  a {
    text-decoration: none;
    color: #5d2a9c;
  }
}

.content-box__continue {
  display: inline-block;
  float: right;
  margin-top: 20px;

  @include md {
    float: none;
    text-align: center;
    margin-top: 10px;
  }

  button {
    margin-bottom: 0 !important;
  }
}
</style>
