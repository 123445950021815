<template>
  <div class="error-message">
    <p class="error-message__text">
      <slot></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',
};
</script>

<style lang="scss">
.error-message {
  &__text {
    color: #cd0000;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 17.5px;
  }
}
</style>
