import { authorizedRequest } from '@/services/ApiService';

export const namespaced = true;

export const state = {
  list: [],
};

export const mutations = {
  setSubscriptionPackages(state, packages) {
    state.list = [
      ...packages,
      {
        id: 'custom',
        custom: true,
      },
    ];
  },
};

export const actions = {
  fetchSubscriptionPackages: (context, currency = '') => {
    return authorizedRequest({
      method: 'GET',
      endpoint: `/api/v1/creditpackages${currency !== '' ? `/${currency}` : ''}`,
    })
      .then((resp) => {
        resp.sort((a, b) => a.credit - b.credit);
        context.commit('setSubscriptionPackages', resp);
      })
      .catch(() => {
        return authorizedRequest({
          method: 'GET',
          endpoint: `/api/v1/creditpackages/EUR`,
        })
          .then((resp) => {
            resp.sort((a, b) => a.credit - b.credit);
            context.commit('setSubscriptionPackages', resp);
          })
          .catch(() => {
            context.commit('setSubscriptionPackages', []);
          });
      });
  },
};

export const getters = {
  list: (state, getters, rootState, rootGetters) => {
    return state.list?.filter((subscriptionPackage) => {
      return subscriptionPackage?.id !== rootGetters['profile']?.active_credit_package?.id;
    });
  },
  currentPackage: (state) => (packageId) => {
    return state.list?.find((p) => {
      return p.id === packageId;
    });
  },
};
