<template>
  <div class="dashboard-documents__filters">
    <v-card
      class="filters"
      flat
    >
      <v-row
        align="end"
        no-gutters
      >
        <v-col
          cols="12"
          md="3"
        >
          <span class="filters__search--label">
            {{ $t('placeholder.fulltext') }}
          </span>
          <v-text-field
            type="text"
            v-model="filters.searchQuery"
            :placeholder="$t('placeholder.fulltext')"
            class="filters__search--input filters__select--placeholder"
            solo
            flat
            hide-details
            clearable
            dense
          >
            <template v-slot:prepend-inner>
              <v-icon
                color="#95AFDA"
                small
                >mdi-magnify</v-icon
              >
            </template>
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="9"
          lg="8"
          class="pl-md-8 pl-lg-2 pl-0"
        >
          <v-expand-transition>
            <div v-show="$vuetify.breakpoint.mobile ? openFilters : true">
              <v-row
                justify-sm="space-between"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="2"
                  class="pb-0"
                >
                  <span class="filters__search--label"> {{ $t('general.state') }}: </span>

                  <v-select
                    v-model="filters.states"
                    :items="workspaceData.states"
                    :placeholder="`${$t('general.select')}`"
                    append-icon="mdi-chevron-down"
                    menu-props="offset-y"
                    class="multi-select filters__select--placeholder"
                    item-text="name"
                    item-value="key"
                    height="38px"
                    dense
                    solo
                    multiple
                    hide-details
                    flat
                    @change="checkState('states')"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item style="height: 38px">
                        <v-list-item-content class="multi-select__select-all">
                          <v-checkbox
                            v-model="checkAll.states"
                            class="d-flex multi-select__select-all mr-6"
                            @change="selectAll('states')"
                            :disabled="!workspaceData.states.length"
                          />
                          {{ $t('general.all') }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider />
                    </template>

                    <template v-slot:selection="{ item, index }">
                      <div
                        v-if="index === 0"
                        class="text-truncate"
                      >
                        {{ item.name }}
                      </div>
                      <div
                        v-if="index === 1"
                        class="grey--text text-caption d-md-none pl-1"
                      >
                        (+{{ filters.states.length - 1 }} {{ $t('general.other') }})
                      </div>
                    </template>
                  </v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="2"
                  class="pb-0"
                >
                  <span class="filters__search--label"> {{ $t('tags') }}: </span>

                  <v-select
                    v-model="filters.tags"
                    :items="workspaceData.tags"
                    :placeholder="`${$t('general.select')}`"
                    append-icon="mdi-chevron-down"
                    menu-props="offset-y"
                    class="multi-select filters__select--placeholder"
                    item-text="name"
                    item-value="key"
                    height="38px"
                    dense
                    solo
                    multiple
                    hide-details
                    flat
                    @change="checkState('tags')"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item style="height: 38px">
                        <v-list-item-content class="multi-select__select-all">
                          <v-checkbox
                            v-model="checkAll.tags"
                            class="d-flex multi-select__select-all mr-6"
                            @change="selectAll('tags')"
                            :disabled="!workspaceData.tags.length"
                          />
                          {{ $t('general.all') }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider />
                    </template>

                    <template v-slot:selection="{ item, index }">
                      <div
                        v-if="index === 0"
                        class="text-truncate"
                      >
                        {{ item.name }}
                      </div>
                      <div
                        v-if="index === 1"
                        class="grey--text text-caption d-md-none pl-1"
                      >
                        (+{{ filters.tags.length - 1 }} {{ $t('general.other') }})
                      </div>
                    </template>
                  </v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="2"
                  class="pb-0"
                >
                  <span class="filters__search--label"> {{ $t('folders.title') }}: </span>

                  <v-select
                    v-model="filters.folders"
                    :items="workspaceData.folders"
                    :placeholder="`${$t('general.select')}`"
                    append-icon="mdi-chevron-down"
                    menu-props="offset-y"
                    class="multi-select filters__select--placeholder"
                    item-text="name"
                    item-value="key"
                    height="38px"
                    dense
                    solo
                    multiple
                    hide-details
                    flat
                    @change="checkState('folders')"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item style="height: 38px">
                        <v-list-item-content class="multi-select__select-all">
                          <v-checkbox
                            v-model="checkAll.folders"
                            class="d-flex multi-select__select-all mr-6"
                            @change="selectAll('folders')"
                            :disabled="!workspaceData.folders.length"
                          />
                          {{ $t('general.all') }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider />
                    </template>

                    <template v-slot:selection="{ item, index }">
                      <div
                        v-if="index === 0"
                        class="text-truncate"
                      >
                        {{ item.name }}
                      </div>
                      <div
                        v-if="index === 1"
                        class="grey--text text-caption d-md-none pl-1"
                      >
                        (+{{ filters.folders.length - 1 }} {{ $t('general.other') }})
                      </div>
                    </template>
                  </v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <span class="filters__search--label"> {{ $t('contract.date') }}: </span>

                  <div
                    class="dashboard-datepicker"
                    :class="{ 'dashboard-datepicker-open': isDatepickerOpened }"
                  >
                    <date-range-picker
                      ref="picker"
                      :opens="'left'"
                      v-model="filters.createdAt"
                      :locale-data="datepickerLocale"
                      :singleDatePicker="false"
                      :timePicker="false"
                      :timePicker24Hour="false"
                      :showWeekNumbers="false"
                      :showDropdowns="false"
                      :auto-apply="true"
                      :ranges="false"
                      @toggle="toggleCreatedDatepicker"
                      @select="updateCreatedAt"
                    >
                      <template
                        v-slot:input="picker"
                        style="min-width: 350px"
                      >
                        <template v-if="picker && picker.startDate">
                          <span v-line-clamp="1">
                            {{ getFormattedDate(picker.startDate) }} -
                            {{ getFormattedDate(picker.endDate) }}

                            <v-icon
                              @click.prevent.stop="clearCreatedAt"
                              class="dashboard-datepicker__close-icon"
                              >mdi-close</v-icon
                            >
                          </span>
                        </template>
                        <template v-else>
                          <v-icon
                            class="mr-2"
                            color="#95AFDA"
                            small
                            >mdi-calendar-month</v-icon
                          >
                          <span class="filters__select--placeholder"> {{ $t('general.select') }}</span>
                        </template>
                      </template>
                    </date-range-picker>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
        </v-col>
        <v-col
          cols="12"
          lg="1"
          align-self="end"
          v-if="filtersCount > 0"
          class="d-none d-md-flex align-end mb-lg-2 mt-lg-0 mb-0 mt-2 justify-end"
        >
          <v-btn
            color="#868E96"
            small
            text
            @click="resetFilters"
          >
            <v-icon
              color="#95AFDA"
              small
              class="mr-2"
              >mdi-close-circle</v-icon
            >
            {{ $t('table.filters.delete') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="d-md-none mt-0">
        <v-col>
          <v-card-actions class="d-flex align-center justify-space-between">
            <v-btn
              v-if="!openFilters"
              class="ma-auto"
              @click="openFilters = true"
              text
            >
              <v-icon
                class="mr-2"
                color="primary"
                >mdi-filter-variant</v-icon
              >
              {{ $t('table.filters.docs') }}
            </v-btn>

            <v-btn
              v-if="openFilters"
              @click="openFilters = false"
              text
            >
              <v-icon
                class="mr-2"
                color="primary"
                >mdi-filter-variant</v-icon
              >
              {{ $t('table.filters.hide') }}
            </v-btn>

            <v-btn
              v-if="openFilters && filtersCount > 0"
              small
              plain
              @click="resetFilters"
            >
              <v-icon
                small
                class="mr-2"
                >mdi-close-circle</v-icon
              >
              {{ $t('table.filters.delete') }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useContractsFiltersStore } from '@/features/contracts/stores/contractsFilter';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions';

export default defineComponent({
  name: 'ContractsFiltersSettings',
  components: {
    DateRangePicker,
  },
  props: {
    workspaceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      datepickerLocale: {
        firstDay: 1,
        daysOfWeek: [
          this.$t('calendar.shortDays.sun'),
          this.$t('calendar.shortDays.mon'),
          this.$t('calendar.shortDays.tue'),
          this.$t('calendar.shortDays.wed'),
          this.$t('calendar.shortDays.thu'),
          this.$t('calendar.shortDays.fri'),
          this.$t('calendar.shortDays.sat'),
        ],
        monthNames: [
          this.$t('calendar.months.jan'),
          this.$t('calendar.months.feb'),
          this.$t('calendar.months.mar'),
          this.$t('calendar.months.apr'),
          this.$t('calendar.months.may'),
          this.$t('calendar.months.jun'),
          this.$t('calendar.months.jul'),
          this.$t('calendar.months.aug'),
          this.$t('calendar.months.sep'),
          this.$t('calendar.months.oct'),
          this.$t('calendar.months.nov'),
          this.$t('calendar.months.dec'),
        ],
      },
      isDatepickerOpened: false,
      openFilters: false,
      checkAll: {
        folders: false,
        tags: false,
        states: false,
      },
    };
  },
  setup() {
    const contractsFiltersStore = useContractsFiltersStore();
    const filters = computed(() => contractsFiltersStore.getFilters);

    return { filters, contractsFiltersStore };
  },
  computed: {
    filtersCount() {
      return (
        [
          this.filters.searchQuery?.length,
          this.filters.tags?.length,
          this.filters.folders?.length,
          this.filters.states?.length,
          this.filters.createdAt?.startDate,
        ]?.filter((isTruthy) => isTruthy)?.length || 0
      );
    },
  },
  methods: {
    selectAll(type) {
      if (this.checkAll[type]) {
        return this.contractsFiltersStore.updateFilter(
          type,
          this.workspaceData[type].map((item) => item.key),
        );
      }

      return this.contractsFiltersStore.updateFilter(type, []);
    },
    checkState(type) {
      this.checkAll[type] = this.workspaceData[type].length === this.filters[type].length;
      this.contractsFiltersStore.updateFilter(type, this.filters[type]);
    },
    toggleCreatedDatepicker() {
      this.isDatepickerOpened = !this.isDatepickerOpened;
    },
    getFormattedDate(date) {
      return getFormattedDateByLocale({
        date,
        options: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        },
      });
    },
    clearCreatedAt() {
      this.contractsFiltersStore.updateFilter('createdAt', {
        startDate: null,
        endDate: null,
      });
    },
    updateCreatedAt(newDatepickerModel) {
      this.contractsFiltersStore.updateFilter('createdAt', {
        startDate: newDatepickerModel.startDate,
        endDate: newDatepickerModel.endDate,
      });
    },
    resetFilters() {
      this.contractsFiltersStore.resetFilters();
      this.contractsFiltersStore.updateFilter('workspace_id', this.$route.params.workspace_id);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/sass/mixins';

.dashboard-documents__filters {
  @media only screen and (min-width: 960px) {
    box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
  }

  .filters {
    background: none;
    margin-bottom: 1rem;

    @media only screen and (min-width: 960px) {
      background: #ffffff;
      padding: 0.5rem 1rem 1.5rem 1rem;
    }

    &__search--label {
      display: inline-block;
      text-transform: uppercase;
      color: #868e96;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 15px;
      margin-bottom: 9px;
      margin-top: 16px;

      &--disabled {
        opacity: 0.5;
      }
    }

    &__select--placeholder {
      font-size: 12px;
      letter-spacing: 0;
      line-height: 17px;
    }

    &__search--input {
      height: 40px;
      border: 1px solid #ced4da;
      border-radius: 4px;

      @media only screen and (min-width: 960px) {
        max-width: 230px !important;
      }
    }
  }
}

.dashboard-datepicker {
  cursor: pointer;
  position: relative;
  width: 100%;
  outline: none;
  border-radius: 6px;
  height: 40px;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: -0.03px;
  color: #868e96;

  @media screen and (min-width: 960px) {
    max-width: 190px;
  }

  .row {
    margin: 0;
  }
  &__close-icon {
    position: absolute;
    right: 10px;
    top: 25%;
  }
  .in-range {
    background-color: #712cbd26 !important;
  }
  .start-date,
  .end-date {
    background-color: #712cbd !important;
  }
  .vue-daterange-picker {
    height: 40px;
    width: 100%;

    ::v-deep .reportrange-text {
      border: 1px solid #ced4da !important;
      border-radius: 4px;
      padding: 4px 10px;
      max-height: 40px;
      overflow: hidden;
    }

    & > div {
      border: 0;
      width: 100%;
      background: none;
      padding: 0 50px 0 20px;
      color: gray;
      font-weight: 400;
      font-size: 16px;
    }
    .daterangepicker {
      top: 0 !important;
      right: -1px;
      box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
    }
    .calendars-container {
      width: 100%;
      background: white;
      @include md {
        & > div:first-child {
          width: 100%;
          max-width: unset;
          padding: 10px;
        }
        & > div:nth-child(2) {
          display: none;
        }
      }
    }
  }
  .form-control {
    background: white !important;
    border-radius: 6px;
  }
}
.dashboard-datepicker-open {
  border-color: #5e239e;
  border-radius: 6px 6px 0 0;

  ::v-deep .daterangepicker {
    margin: 0;
    top: 54px !important;
    border: 1px solid #5e239e !important;
    border-radius: 6px 0 6px 6px;
    overflow: hidden;
    &:before,
    &:after {
      display: none;
    }
    @include md {
      width: calc(100% + 2px) !important;
      border-radius: 0 0 6px 6px;
    }
  }
}

.multi-select {
  border: 1px solid #ced4da;

  &__select-all,
  &__select-tags {
    font-size: 13px;
    font-weight: 500;
  }
}

.v-select {
  font-size: 14px;
  border-radius: 4px;
}

::v-deep .v-input--selection-controls__ripple:before {
  background: none !important;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap !important;
}

.v-select-list {
  border: 1px solid #7031b4;
  border-radius: 4px;
}
</style>
