<template>
  <svg
    width="50px"
    height="50px"
    viewBox="0 0 50 50"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>ico-N/ico-50-cekajinamne</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
      id="ico-N/ico-50-cekajinamne"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Group-3"
        transform="translate(10.000000, 0.300000)"
        stroke="#B4C1D6"
        stroke-width="2"
      >
        <path
          d="M0.483645398,0.698403926 L29.7431519,0.698403926"
          id="Path-38"
        ></path>
        <path
          d="M0.445618441,48.7 L30.722346,48.7"
          id="Path-40"
        ></path>
        <path
          d="M18.7863553,22.1328691 C17.8618011,22.8842277 17.399524,23.7086778 17.399524,24.6062192 C17.399524,25.9525313 22.9889578,27.3167291 25.1815308,32.158087 C26.6432462,35.3856588 27.2431933,40.6680188 26.9813721,48.0051668"
          id="Path-41"
        ></path>
        <path
          d="M3.10139778,48.0593662 C3.36752287,39.5804183 4.21315147,34.0081821 5.63828359,31.3426576 C7.77598176,27.3443708 12.8338827,26.8668745 12.8338827,24.6867052 C12.8338827,22.506536 8.64375757,21.9480116 5.63828359,17.7375343 C3.63463427,14.9305494 2.97882602,9.35750027 3.67085884,1.01838676"
          id="Path-45"
        ></path>
        <path
          d="M24.8840231,16.7819996 C26.4999618,10.1631737 27.3079312,4.92733492 27.3079312,1.0744831"
          id="Path-46"
        ></path>
        <path
          d="M3.63158566,11.3555597 C6.01003379,9.13717987 8.38175706,8.39771993 10.7467555,9.13717987 C14.2942531,10.2463698 20.2282687,12.9040354 25.9809821,10.5983946"
          id="Path-47"
        ></path>
        <path
          d="M4.1305702,36.1593547 C6.0606944,35.1355288 8.05331495,34.6236158 10.1084319,34.6236158 C13.1911072,34.6236158 16.0671949,37.0509342 19.5108607,37.0509342 C21.8066379,37.0509342 24.060452,36.4814796 26.2723029,35.3425704"
          id="Path-48"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoDocsPendingMe',
};
</script>
