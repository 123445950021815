<template>
  <v-alert
    class="mx-auto px-3 py-1 pt-2"
    color="primary"
    :style="`border: 1px solid #ffd6d6`"
    max-width="845px"
    width="100%"
    dense
    dark
  >
    <template v-slot:prepend>
      <v-icon class="align-self-start py-1 pr-2">mdi-alert-outline</v-icon>
    </template>

    <template>
      <v-row
        align="center"
        justify="start"
        no-gutters
      >
        <v-col cols="12">
          <div class="subtitle-1 notification-title font-weight-bold">
            {{ disabledUserMessage }}
          </div>
        </v-col>
      </v-row>
    </template>
  </v-alert>
</template>

<script>
export default {
  name: 'ArchiveNotification',
  props: {
    profile: {
      type: Object,
    },
    workspace: {
      type: Object,
    },
  },
  computed: {
    disabledUserMessage() {
      return (
        this.profile?.disabledUserMessage ||
        this.workspace?.owner_disabled_message ||
        this.$t('account.disable.message')
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-title {
  font-size: 15px !important;
  line-height: 1.2rem !important;
  padding-top: 0.2rem;
}
</style>
