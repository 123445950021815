<template lang="html">
  <svg
    width="20px"
    height="21px"
    viewBox="0 0 20 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="form/input-search-47"
        transform="translate(-257.000000, -14.000000)"
        fill-rule="nonzero"
        stroke="#5E239E"
        stroke-width="2"
      >
        <g
          id="ico/ico-search"
          transform="translate(257.000000, 14.888889)"
        >
          <circle
            id="Oval"
            cx="8.5"
            cy="8.5"
            r="7.5"
          ></circle>
          <path
            d="M13.4303773,13.9203611 L18.7190424,19.2090262"
            id="Path-2"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoSearchPurple',
};
</script>
