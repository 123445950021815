<template>
  <svg
    width="125px"
    height="141px"
    viewBox="0 0 125 141"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Mobil"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="SYN001---Finish-screen---smlouva-odmítnuta"
        transform="translate(-98.000000, -142.000000)"
        fill-rule="nonzero"
      >
        <g
          id="Group-3"
          transform="translate(98.000000, 142.000000)"
        >
          <g id="Group">
            <circle
              id="Oval-4"
              :stroke="color"
              stroke-width="2"
              cx="63"
              cy="63"
              r="53"
            ></circle>
            <circle
              id="Oval-4-Copy"
              stroke="#E8EDF5"
              cx="62.5"
              cy="62.5"
              r="62"
            ></circle>
            <g
              id="ico/ico-odmitnuti"
              transform="translate(24.000000, 25.000000)"
              :stroke="color"
              stroke-width="2"
            >
              <path
                d="M47.881334,44.2023663 C49.2189771,44.2023663 50.9819103,42.9457056 52.7942422,40.2549534 C54.6065742,37.5642013 55.9977997,35.6737482 58.2717745,35.6737482 C60.5457494,35.6737482 60.8434756,37.051396 59.0600701,39.2067132 C57.2766646,41.3620305 54.8309019,45.3869423 52.4475181,50.3595405 C50.0641343,55.3321386 47.250789,59.2734195 42.9448772,60.9586324 C38.6389654,62.6438453 30.1204148,62.7237375 26.116755,59.9515189 C22.1130953,57.1793004 22.4316801,43.7733569 22.4316801,39.2067132 C22.4316801,34.6400696 18.9675876,24.4087571 20.6996338,22.2357297 C22.4316801,20.0627024 23.9317242,25.4355484 24.5462641,29.2034673 C25.160804,32.9713861 26.4493111,37.1183007 27.6988559,37.1183007 C28.9484008,37.1183007 28.0178342,33.9310759 27.4450114,25.5091437 C26.8721885,17.0872114 27.4152978,16.0490069 28.780155,15.7939817 C30.1450121,15.5389565 31.0185128,17.5925392 31.4189575,22.2357297 C31.8194022,26.8789203 32.7674821,35.00962 33.6258308,35.00962 C34.4841796,35.00962 34.6008693,33.1787909 34.6008693,28.7967256 C34.6008693,24.4146604 34.2569002,13.7024207 36.8630375,13.7024207 C38.6004623,13.7024207 39.4322395,15.6968386 39.3583689,19.6856744 C39.3583689,28.1838911 39.3583689,32.8624363 39.3583689,33.7213098 C39.3583689,35.00962 40.2582574,35.9586838 41.1236902,35.00962 C41.989123,34.0605563 42.6325894,29.4338951 42.9448772,25.5091437 C43.257165,21.5843922 43.5600075,18.2141631 45.5092974,18.2141631 C47.4585874,18.2141631 47.5382828,21.4850189 47.1829004,23.9195652 C46.827518,26.3541115 46.1307246,38.4646607 46.1307246,40.7051831 C46.1307246,42.9457056 46.5436909,44.2023663 47.881334,44.2023663 Z"
                id="Path-26"
              ></path>
            </g>
          </g>

          <g
            v-if="type === 'success'"
            id="Group-2"
            transform="translate(40.000000, 96.000000)"
          >
            <circle
              id="Oval"
              :fill="color"
              cx="22.5"
              cy="22.5"
              r="22.5"
            ></circle>
            <polyline
              id="Path-26-Copy-6"
              stroke="#FFFFFF"
              stroke-width="4"
              stroke-linecap="round"
              points="12.7358491 24.0545915 19.3855151 30.7042575 33.1086405 16.9811321"
            ></polyline>
          </g>

          <g
            v-if="type === 'error'"
            id="Group-2"
            class="checkmark"
            transform="translate(37.000000, 95.000000)"
          >
            <circle
              class="checkmark__circle"
              cx="25"
              cy="25"
              r="20"
              fill="red"
            />
            <path
              class="checkmark__check"
              fill="none"
              d="M16 16 36 36 M36 16 16 36"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoContractRejected',
  props: {
    type: {
      type: String,
      default: 'success',
    },
  },
  computed: {
    color() {
      if (this.type === 'error') {
        return '#F44336';
      }

      return '#6decaf';
    },
  },
};
</script>

<style lang="scss" scoped>
.checkmark__circle {
  stroke: #f44336;
  fill: #f44336;
}

.checkmark {
  stroke-width: 2;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 29;
  stroke-dashoffset: 29;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.5s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
    fill: #f44336;
  }
}
</style>
