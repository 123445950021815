import { render, staticRenderFns } from "./IcoEnvelopeWrap.vue?vue&type=template&id=c71567ac&lang=html"
import script from "./IcoEnvelopeWrap.vue?vue&type=script&lang=js"
export * from "./IcoEnvelopeWrap.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports