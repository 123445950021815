import { render, staticRenderFns } from "./SPhoneNumberInput.vue?vue&type=template&id=3dfdae54&scoped=true"
import script from "./SPhoneNumberInput.vue?vue&type=script&lang=js"
export * from "./SPhoneNumberInput.vue?vue&type=script&lang=js"
import style0 from "./SPhoneNumberInput.vue?vue&type=style&index=0&id=3dfdae54&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dfdae54",
  null
  
)

export default component.exports