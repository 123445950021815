<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>ico-N/ico-24-expiration.gray</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
      id="ico-N/ico-24-expiration.gray"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Group"
        transform="translate(3.000000, 3.000000)"
        stroke="#B4C1D6"
        stroke-width="2"
      >
        <circle
          id="Oval"
          cx="9"
          cy="9"
          r="9"
        ></circle>
        <polyline
          id="Path-106"
          points="9.00154828 3.10226397 9.00154828 9.93031013 11.7475077 13.8202386"
        ></polyline>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoExpiration',
};
</script>

<style lang="scss" scoped></style>
