import { getAuthState, getContact } from '@/features/contract/services/VerificationService';

export const namespaced = true;

export const state = {
  auths: null,
};

export const mutations = {
  SET_VERIFICATION: (state, payload) => {
    state.auths = payload.contract_auths;
  },
};

export const actions = {
  fetchAuthState: ({ commit }, contractId) => {
    return getAuthState(contractId).then(
      (resp) => {
        commit('SET_VERIFICATION', resp);
      },
      (err) => {
        throw err;
      },
    );
  },
  fetchContacts: ({ commit }, contactId) => {
    return getContact(contactId).then((authContact) => {
      commit('SET_CONTACT', authContact);
    });
  },
};

export const getters = {
  auths: (state) => {
    return state.auths;
  },
  isAllAuthsDone: (state, getters) => {
    let isDone = true;

    getters['auths']?.forEach((auth) => {
      if (0 === Object.keys(auth.auths)?.length) {
        isDone = false;
      } else {
        Object.keys(auth.auths).forEach((authPart) => {
          if (auth.auths[authPart].status !== 'ok') {
            isDone = false;
          }
        });
      }
    });

    return isDone;
  },
};
