var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.profile.id)?_c('div',{staticClass:"main",class:{
    coverMenu: !_vm.showMenu,
    'panel-load': _vm.panelLoad,
  }},[(!_vm.panelLoad)?_c('div',{attrs:{"id":"triangle"}}):_vm._e(),(!(_vm.panelLoad || _vm.disableMenu) || _vm.isAccountSettingsRoute)?_c('SideMenu',{attrs:{"showMenu":_vm.showMenu}}):_vm._e(),(!_vm.panelLoad && _vm.showHeader)?_c('Header',{attrs:{"closeAndSave":_vm.closeAndSave,"contractName":_vm.contractName_,"customTitle":_vm.customTitle,"disableMenu":_vm.disableMenu,"showBackNavigation":_vm.showBackNavigation},on:{"close":() => _vm.$emit('close'),"onNavigationBack":() => _vm.$emit('onNavigationBack')}}):_vm._e(),(_vm.loader)?_c('div',{staticClass:"data data--loading",class:{ menuOpen: _vm.isSideMenuVisible }},[_c('Loader')],1):_c('div',{staticClass:"data",class:{
      menuOpen: _vm.isSideMenuVisible,
      'without-margin': _vm.withoutMargin,
      'without-side-paddings': _vm.contractSignature,
      'without-y-paddings': !_vm.showHeader,
      'without-after-graphic': _vm.withoutAfterGraphic,
    }},[_c('v-container',{staticClass:"data__content",class:{ 'is-dashboard': _vm.isDashboard },style:(_vm.$vuetify.breakpoint.mobile && _vm.$route.name !== 'createSign' ? 'margin-top: 1rem !important;' : ''),attrs:{"fluid":""}},[_c('div',[(_vm.titleSlotHasContent)?_c('v-row',{attrs:{"align":"start","justify":_vm.isDashboard ? 'start' : 'center',"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.withoutMargin && !_vm.contractSignature)?_c('h1',[_vm._t("title")],2):_vm._e()])],1):_vm._e()],1),_c('div',[(_vm.headerSlotHasContent)?_c('v-row',{attrs:{"align":"start","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.contractSignature)?_c('div',{staticClass:"data__tab",class:{ 'data__tab-noshaddow': !_vm.shadow }},[_vm._t("header")],2):_vm._e()])],1):_vm._e()],1),_c('div',{staticClass:"mx-auto"},[_vm._t("default")],2)])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }