<template>
  <canvas ref="textSignatureCanvas" />
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent, computed } from 'vue';
import { useSignatureStore } from '@/features/signature/stores/signature';
import FontFaceObserver from 'fontfaceobserver';

export default defineComponent({
  name: 'TextSignatureCanvas',
  props: {
    fontFamily: {
      type: String,
      required: true,
    },
    textSignature: {
      type: String,
      required: true,
    },
  },
  setup() {
    const signatureStore = useSignatureStore();

    const canvasWidth = computed(() => signatureStore.getTextSignatureCanvasWidth);
    const canvasHeight = computed(() => signatureStore.getTextSignatureCanvasHeight);

    return {
      signatureStore,
      canvasWidth,
      canvasHeight,
    };
  },
  data() {
    return {
      canvas: {},
      ctx: {},
      fontSize: '',
      baseFontSize: 150,
    };
  },
  computed: {
    ...mapGetters({
      signatureColor: 'signature/signatureColor',
    }),
  },
  watch: {
    textSignature(input) {
      this.setCurrentFont(input, this.fontFamily);
      this.clearCanvas();
      this.fillText();

      this.ctx.background = 'transparent';
      this.signatureStore.setSignatureDataUrl(this.canvas.toDataURL('image/png'));
    },
    fontFamily(selectedFontFamily) {
      const fontObserver = new FontFaceObserver(selectedFontFamily);
      this.ctx.font = `${this.fontSize} ${selectedFontFamily}`;

      fontObserver.load('ěščřžýáíéň').then(() => {
        this.setCurrentFont(this.textSignature, selectedFontFamily);
        this.clearCanvas();
        this.fillText();

        this.ctx.background = 'transparent';
        this.signatureStore.setSignatureDataUrl(this.canvas.toDataURL('image/png'));
      });
    },
  },
  mounted() {
    this.$nextTick(() => this.setBaseCanvasTemplate());
    this.loadFontFaceObserver();
  },
  methods: {
    loadFontFaceObserver() {
      const fontObserver = new FontFaceObserver(this.fontFamily);
      fontObserver.load('ěščřžýáíéň').then(() => {});
    },
    setBaseCanvasTemplate() {
      this.canvas = this.$refs.textSignatureCanvas;

      const ratio = window.devicePixelRatio;
      this.canvas.width = this.canvasWidth * ratio;
      this.canvas.height = this.canvasHeight * ratio;
      this.canvas.style.width = this.canvasWidth + 'px';
      this.canvas.style.height = this.canvasHeight + 'px';

      this.ctx = this.canvas.getContext('2d');
      this.ctx.scale(ratio, ratio);

      this.ctx.font = `150px ${this.fontFamily}`;
      this.fontSize = '150px';

      this.fillText();
    },
    clearCanvas() {
      this.ctx.background = 'white';
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    },
    fillText() {
      this.ctx.fillText(this.textSignature, this.canvasWidth / 2, this.canvasHeight / 2);
      this.ctx.textAlign = 'center';
      this.ctx.textBaseline = 'middle';
      this.ctx.fillStyle = this.signatureColor;
    },
    setCurrentFont(textSignature, fontFamily) {
      this.clearCanvas();
      this.fillText();

      const currentFont = (
        ((this.canvasWidth * 0.9) / this.ctx.measureText(textSignature).width) *
        parseInt(this.fontSize || this.baseFontSize)
      ).toFixed(0);

      this.fontSize = `${currentFont > 150 ? 150 : currentFont}px`;
      this.ctx.font = `${currentFont > 150 ? 150 : currentFont}px ${fontFamily}`;
    },
  },
});
</script>

<style lang="scss" scoped>
canvas {
  transform: scale(0.3, 0.3) translate(0%, -100%);
}

@media (-webkit-transform-2d) {
  canvas {
    -webkit-transform: scale(0.3, 0.3) translate(-110%, -100%);
  }
}
</style>
