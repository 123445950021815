import { authorizedApiRequest } from '@/services/ApiService';

export const fetchAuthsPoliticalTexts = ({ workspaceId }) => {
  return authorizedApiRequest({
    method: 'GET',
    endpoint: `/api/v2/workspaces/${workspaceId}/amlTexts`,
  });
};

export const updateAuthsPoliticalTexts = ({ workspaceId, data }) => {
  return authorizedApiRequest({
    method: 'PATCH',
    endpoint: `/api/v2/workspaces/${workspaceId}/amlTexts`,
    data,
  });
};
