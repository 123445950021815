import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// TODO - this config should not have been placed here
const firebaseConfig = {
  apiKey: 'AIzaSyAmUG3rri9D9cV6VF8jSEDJ6EKONAZmQWc',
  authDomain: 'signi-master.firebaseapp.com',
  projectId: 'signi-master',
  storageBucket: 'signi-master.appspot.com',
  messagingSenderId: '534468036332',
  appId: '1:534468036332:web:55b33f93eadd4b6c7507c9',
  measurementId: 'G-BYPCPMHTGJ',
};

export const auth = getAuth(initializeApp(firebaseConfig));
