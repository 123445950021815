import { getFormattedDate } from '@/common/reusable/dateFunctions';
import { getSignatureProgressLength } from '@/features/signature/services/validationService';

export const signatureColor = (state) => {
  return state.signatureColor;
};

export const signatureDate = (state) => {
  return `${state.signatureDate}`;
};

export const signatureDateFormatted = (state, getters) => {
  return getFormattedDate({
    date: `${getters['signatureDate']}`,
    format: 'DD.MM.YYYY',
  });
};

export const signaturePlace = (state) => {
  return state.signaturePlace;
};

export const signatureProgress = (state) => {
  return state.signatureProgress;
};

export const signatureProgressLength = (state, getters) => {
  return getSignatureProgressLength(getters['signatureProgress']);
};

export const signatureProgressBarWidth = (state, getters) => {
  const signatureProgressPercentage = (100 / 20) * getters['signatureProgressLength'];

  return signatureProgressPercentage < 100 ? signatureProgressPercentage : 100;
};

export const isSignatureDateFilled = (state, getters) => {
  return !!getters['signatureDate'];
};

export const isSignaturePlaceFilled = (state, getters) => {
  const signaturePlace = getters['signaturePlace'];

  return !!signaturePlace && signaturePlace?.length >= 2;
};

export const isSignatureProgressFilled = (state, getters) => {
  return 100 === getters['signatureProgressBarWidth'];
};

export const hasSignatureProgressContainsPoints = (state, getters) => {
  return getters['signatureProgress']?.length > 0;
};

export const preloadedSignatureImage = (state) => {
  return state.signatureImage;
};

export const previousDisplayWidth = (state) => {
  return state.display.previousWidth;
};

export const previousDisplayHeight = (state) => {
  return state.display.previousHeight;
};
