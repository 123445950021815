import { apiUrl } from '@/app/config/appEnvironment';
import router from '@/app/plugins/router';
import { authHeader } from '@/config/authHeader';
import { autologoutIfUnauthorized } from './helpers';

export const TemplateService = {
  getWorkspaceTemplates,
  getPDF,
  detail,
};

function getPDF(template_id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader() },
  };

  return fetch(`${apiUrl}/api/v1/templates/${template_id}/pdf`, requestOptions).then(handleResponseRaw);
}

function getWorkspaceTemplates(workspaceId, areOnlyFavouritesLoaded) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader() },
  };
  const typeFilter = areOnlyFavouritesLoaded ? '&type=favourite' : '';
  return fetch(`${apiUrl}/api/v1/templates?workspaceId=${workspaceId}${typeFilter}`, requestOptions).then(
    handleResponse,
  );
}

function detail(workspace_id, template_id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader() },
  };

  return fetch(`${apiUrl}/api/v1/workspaces/${workspace_id}/templates/${template_id}`, requestOptions).then(
    handleResponse,
  );
}

function handleResponseRaw(response) {
  return response;
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 404) {
        router.push({ name: 'notFound' });
        return;
      }

      autologoutIfUnauthorized(response);

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
