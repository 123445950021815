const routes = [
  {
    path: '/workspace/:workspaceId/contract/:contractId/create/parties',
    name: 'contractsCollectionParties',
    component: () => import('@/features/contracts/views/ContractsCollectionParties.vue'),
  },
  {
    path: '/workspace/:workspaceId/contract/:contractId/created',
    name: 'contractsCollectionCreated',
    component: () => import('@/features/contracts/views/ContractsCollectionCreated.vue'),
    meta: {
      backButton: false,
      saveButton: false,
    },
  },
];

routes.forEach((route) => {
  Object.assign(route, {
    meta: {
      ...route.meta,
      fullLayout: true,
      layout: () => import('@/layouts/createContractsCollection/CreateContractsCollectionLayout.vue'),
    },
  });
});

export default routes;
