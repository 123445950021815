import { internalApiRequest } from '@/shared/services/apiService';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const usePaymentMethodsStore = defineStore('paymentMethod', () => {
  const paymentMethod = ref(null);

  async function fetchPaymentMethods(workspaceId) {
    try {
      const resp = await internalApiRequest({
        method: 'GET',
        path: `api/v1/workspaces/${workspaceId}/paymentMethod`,
      });

      paymentMethod.value = resp;
    } catch (err) {
      paymentMethod.value = null;
    }
  }

  async function saveWorkspacePaymentMethod(workspaceId, data) {
    try {
      await internalApiRequest({
        method: 'POST',
        path: `api/v1/workspaces/${workspaceId}/paymentMethod`,
        data,
      });
    } catch (err) {
      throw new Error(`Failed to save payment method with reason ${err}`);
    }
  }

  return {
    paymentMethod,
    fetchPaymentMethods,
    saveWorkspacePaymentMethod,
  };
});
