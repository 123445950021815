<template>
  <div
    class="attachment-panel"
    :class="{ open: attachmentPanelOpen }"
  >
    <div class="attachment-panel__header">
      <a
        v-if="page === 'templates' || page === 'prepare-own'"
        @click.prevent="() => backToCreate()"
        href="#"
        class="attachment-panel__back"
      >
        <IcoBack /> {{ $t('templates.back_to_source') }}
      </a>

      <a
        @click.prevent="close"
        href="#"
        class="attachment-panel__close"
      >
        <IcoClose />
      </a>
    </div>

    <div class="attachment-panel__content">
      <CreateNew
        v-if="page === 'create'"
        :panelLoad="true"
        :isAttachment="true"
        :disableForms="disableForms"
        @change="changeContent"
      />

      <Templates
        v-if="page === 'templates'"
        :panelLoad="true"
      />
    </div>
  </div>
</template>

<script>
import IcoClose from '@/components/svg/IcoClose';
import IcoBack from '@/components/svg/IcoBack.vue';
import CreateNew from '@/views/create/CreateNew';
import Templates from '@/views/dashboard/Templates.vue';

export default {
  name: 'AttachmentPanel',
  components: {
    IcoClose,
    IcoBack,
    CreateNew,
    Templates,
  },
  props: {
    attachmentPanelOpen: {
      required: true,
      type: Boolean,
    },
    disableForms: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 'create',
      templateId: null,
    };
  },
  methods: {
    changeContent(action, id) {
      // called from <Storage in <CreateNew
      switch (action) {
        case 'showContractTemplates':
          this.page = 'templates';
          break;
        case 'prepareOwn':
          this.page = 'prepare-own';
          break;
      }
    },

    backToCreate() {
      this.page = 'create';
    },

    close() {
      this.page = 'create';
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/mixins';

.attachment-panel {
  position: fixed;
  top: 0;
  right: 0;
  margin-right: -60%;
  width: 55%;
  max-width: 680px;
  min-width: 600px;
  background: #fff;
  z-index: 1000;
  height: 100vh;
  box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  @include md {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-right: -100%;
  }

  &.open {
    margin-right: 0;
  }

  &__close {
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 10001;
    cursor: pointer;
  }

  &__back {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.79;
    letter-spacing: -0.03px;
    color: #5e239e;
    top: 40px;
    position: relative;
    left: 80px;
    text-decoration: none;

    svg {
      margin-right: 8px;
      position: relative;
      top: 1px;
    }
  }

  .data__tab {
    @include sm {
      background: #fff;
    }

    &.data__tab-noshaddow {
      @include sm {
        &:before {
          background: #fff;
        }

        &:after {
          background: #fff;
        }
      }
    }
  }

  &__header {
    width: 100%;
    height: 70px;
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    z-index: 1001;
  }

  &__content {
    height: 100vh;
    overflow: auto;
  }

  .data {
    padding-top: 102px;

    &:after {
      display: none;
    }

    &__content {
      padding: 0 60px;
    }

    &__title {
      @include sm {
        background: #fff;
      }
    }
  }
}
</style>
