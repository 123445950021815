var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"button",class:[
    'button--' + _vm.type,
    { disabled: _vm.disabled },
    { 'small-text': _vm.type == 'accept-small' },
    { 'no-hover': _vm.noHover },
  ],style:(_vm.cssVars),attrs:{"disabled":_vm.disabled,"type":_vm.buttonType},on:{"click":function($event){return _vm.$emit('click')}}},[_c('span',[(
        !_vm.hideIcon &&
        (_vm.type == 'accept' || _vm.type == 'accept-small' || _vm.type == 'plus' || _vm.type == 'reject' || _vm.type == 'search')
      )?_c('span',{staticClass:"button__ico button__ico--left"},[(_vm.type == 'accept')?_c('IcoCheck'):_vm._e(),(_vm.type == 'accept-small')?_c('IcoCheck'):_vm._e(),(_vm.type == 'plus')?_c('IcoPlus'):_vm._e(),(_vm.type == 'reject')?_c('IcoReject'):_vm._e(),(_vm.type == 'search')?_c('IcoSearchPurple'):_vm._e()],1):_vm._e(),(_vm.type == 'add-person')?_c('span',{staticClass:"button__ico button__ico--left"},[(_vm.type == 'add-person')?_c('IcoPlus'):_vm._e()],1):_vm._e(),(_vm.hasStyledSlot)?_c('span',[_vm._t("default")],2):(_vm.hideIcon)?_c('span',{staticClass:"text"},[_vm._t("default")],2):_c('span',{staticClass:"button__text"},[_vm._t("default")],2),(_vm.type == 'right-plus')?_c('span',{staticClass:"button__ico button__ico--right"},[(_vm.type == 'right-plus')?_c('IcoPlusRight'):_vm._e()],1):_vm._e(),(_vm.type == 'secondary-plus')?_c('span',{staticClass:"button__ico button__ico--right"},[(_vm.type == 'secondary-plus')?_c('IcoPlus'):_vm._e()],1):_vm._e(),(_vm.type == 'right-edit')?_c('span',{staticClass:"button__ico button__ico--right"},[_c('span',{staticClass:"icon-pen1"})]):_vm._e()]),(_vm.loading)?_c('span',{staticClass:"button-loading"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }