<template lang="html">
  <svg
    width="15px"
    height="14px"
    viewBox="0 0 15 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="SYN010---DSKTP---Připojit---7"
        transform="translate(-380.000000, -320.000000)"
        fill-rule="nonzero"
      >
        <g
          id="ico/ico-alert"
          transform="translate(379.000000, 318.000000)"
        >
          <path
            d="M9.34164079,3.80131556 C9.19649523,3.51102446 8.9611115,3.27564072 8.67082039,3.13049517 C7.92985265,2.7600113 7.02884309,3.06034782 6.65835921,3.80131556 L1.89442719,13.3291796 C1.79028562,13.5374628 1.73606798,13.7671324 1.73606798,14 C1.73606798,14.8284271 2.40764085,15.5 3.23606798,15.5 L12.763932,15.5 C12.9967997,15.5 13.2264693,15.4457824 13.4347524,15.3416408 C14.1757202,14.9711569 14.4760567,14.0701474 14.1055728,13.3291796 L9.34164079,3.80131556 Z"
            id="Triangle-2"
            stroke="#D9CB11"
            stroke-width="1"
            fill="#F8E71C"
          ></path>
          <path
            d="M8.97363922,4.94686789 L12.0137962,4.94686789 L12.0137962,12.0099694 L10.5736984,14.7429293 L8.97363922,12.0099694 L8.97363922,4.94686789 Z M9.00166837,3.79836413 C9.00166837,3.47534131 9.00166837,3.21636497 9.00166837,3.02143512 C9.00166837,2.72904035 9.24502173,2.24450611 9.72316721,2.24450611 C10.2013127,2.24450611 10.7246187,2.24450611 11.2605526,2.24450611 C11.7964865,2.24450611 11.992539,2.94272679 11.992539,3.18613318 C11.992539,3.3484041 11.992539,3.55248109 11.992539,3.79836413 L9.00166837,3.79836413 Z"
            id="Combined-Shape"
            fill="#000000"
            transform="translate(10.493718, 8.493718) rotate(-315.000000) translate(-10.493718, -8.493718) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoUnsignedAlert',
};
</script>
