import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

const defaultFilters = {
  workspace_id: '',
  states: [],
  tags: [],
  folders: [],
  searchQuery: '',
  createdAt: {
    startDate: null,
    endDate: null,
  },
};

export const useContractsFiltersStore = defineStore('contractsFilters', {
  state: () => ({
    filters: useStorage('filters', defaultFilters, localStorage, { mergeDefaults: true }),
  }),
  actions: {
    updateFilter(type, newValue) {
      this.filters[type] = newValue;
    },
    resetFilters() {
      this.filters = defaultFilters;
    },
  },
  getters: {
    getFilters() {
      return this.filters;
    },
  },
});
