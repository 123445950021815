import { render, staticRenderFns } from "./SignOnPhoneDialog.vue?vue&type=template&id=0fca9fad&scoped=true"
import script from "./SignOnPhoneDialog.vue?vue&type=script&lang=js"
export * from "./SignOnPhoneDialog.vue?vue&type=script&lang=js"
import style0 from "./SignOnPhoneDialog.vue?vue&type=style&index=0&id=0fca9fad&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fca9fad",
  null
  
)

export default component.exports