import { apiAuthorizedRequest } from '@/services/ApiService';

/**
 * Method to get actual state of persons authentication
 *
 * @param contractId
 * @returns {Promise<*>}
 */
export function getAuthState(contractId) {
  return apiAuthorizedRequest('GET', `/api/v1/contracts/${contractId}/auths`);
}

/**
 * Method to get info about counter party
 *
 * @param contactId
 * @returns {Promise<*>}
 */
export function getContact(contactId) {
  return apiAuthorizedRequest('GET', `/api/v1/contacts/${contactId}`);
}

/**
 * Method to get document state
 *
 * @param authId
 * @param authDocumentId
 * @returns {Promise<*>}
 */
export function getDocumentsState(authId, authDocumentId) {
  return apiAuthorizedRequest('GET', `/api/v1/auths/${authId}/documents/${authDocumentId}`);
}

/**
 * Method to get document images
 *
 * @param authId
 * @param authDocumentId
 * @returns {Promise<*>}
 */
export function getDocumentsImagesState(authId, authDocumentId) {
  return apiAuthorizedRequest('GET', `/api/v1/auths/${authId}/documents/${authDocumentId}/images`);
}

/**
 * Method to get current document image
 *
 * @param authId
 * @param authDocumentId
 * @param imageId
 * @returns {Promise<*>}
 */
export function getCurrentDocumentImage(authId, authDocumentId, imageId) {
  return apiAuthorizedRequest('GET', `/api/v1/auths/${authId}/documents/${authDocumentId}/images/${imageId}`);
}

/**
 * Method allows to submit document
 *
 * @param authId
 * @param authDocumentId
 * @returns {Promise<*>}
 */
export function submitDocument(authId, authDocumentId) {
  return apiAuthorizedRequest('PATCH', `/api/v1/auths/${authId}/documents/${authDocumentId}/approve`);
}

/**
 * Method allows to reject document
 *
 * @param authId
 * @param authDocumentId
 * @returns {Promise<*>}
 */
export function rejectDocument(authId, authDocumentId) {
  return apiAuthorizedRequest('PATCH', `/api/v1/auths/${authId}/documents/${authDocumentId}/reject`);
}

/**
 * Method to get state of micropament
 * @param authId
 * @param authMicroPaymentId
 * @returns {Promise<*>}
 */
export function getMicroPaymentState(authId, authMicroPaymentId) {
  return apiAuthorizedRequest('GET', `/api/v1/auths/${authId}/micro_transactions/${authMicroPaymentId}`);
}

/**
 * Method to submit micropayment
 * @param authId
 * @param authMicroPaymentId
 * @returns {Promise<*>}
 */
export function submitMicroPayment(authId, authMicroPaymentId) {
  return apiAuthorizedRequest('PATCH', `/api/v1/auths/${authId}/micro_transactions/${authMicroPaymentId}/approve`);
}

/**
 * Method to reject micropayment
 *
 * @param authId
 * @param authMicroPaymentId
 * @returns {Promise<*>}
 */
export function rejectMicroPayment(authId, authMicroPaymentId) {
  return apiAuthorizedRequest('PATCH', `/api/v1/auths/${authId}/micro_transactions/${authMicroPaymentId}/reject`);
}

/**
 * Method to get political question
 *
 * @param authId
 * @param authPoliticalId
 * @returns {Promise<*>}
 */
export function getPoliticalState(authId, authPoliticalId) {
  return apiAuthorizedRequest('GET', `/api/v1/auths/${authId}/politicals/${authPoliticalId}`);
}
