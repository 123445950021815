export const SET_LOADED = 'SET_LOADED';
export const SET_ERROR = 'SET_ERROR';

/**
 * Account Profile
 */
export const SET_PROFILE = 'SET_PROFILE';

/**
 * Account Signature
 */
export const SET_SIGNATURE = 'SET_SIGNATURE';
