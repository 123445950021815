// TODO - module is not important now
export const WindowModule = {
  namespaced: true,
  state: {
    windowWidth: window.innerWidth,
  },
  actions: {
    updateWindowWidth: ({ commit, state }) => {
      commit('updateWindowWidth', window.innerWidth);
      return state.windowWidth;
    },
  },
  mutations: {
    updateWindowWidth: (state, payload) => {
      state.windowWidth = payload;
    },
  },
  getters: {
    isMobileDevice(state) {
      return state.windowWidth < 991; // TODO: UI - 999 is hardcoded in many components
    },
    windowWidth: (state) => {
      return state.windowWidth;
    },
  },
};
