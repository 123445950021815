const routes = [
  {
    path: '/dashboard/workspace/:workspace_id/documents/:contract_id',
    alias: '/:workspace_id/data/contract/:contract_id/detail',
    name: 'documentsDetail',
    component: () => import('@/features/contract/views/ContractDocumentDetail.vue'),
  },
  {
    path: '/dashboard/workspace/:workspace_id/documents/:contract_id/counterparty',
    alias: '/:workspace_id/data/contract/:contract_id/counterparty',
    name: 'documentsDetailCounterparty',
    component: () => import('@/features/contract/views/ContractDocumentDetail.vue'),
    redirect: (to) => {
      return {
        name: 'documentsDetail',
        params: {
          ...to.params,
        },
        query: {
          ...to.query,
          counterparty: true,
        },
      };
    },
  },
  {
    path: '/dashboard/workspace/:workspace_id/documents/:contract_id/2fa/start',
    alias: '/:workspace_id/create/:contract_id/2fa/start',
    name: 'contractDocumentSign2FAStart',
    component: () => import('@/features/contract/views/ContractDocumentSign2FAStart.vue'),
  },
  {
    path: '/dashboard/workspace/:workspace_id/documents/:contract_id/2fa/submit',
    alias: '/:workspace_id/create/:contract_id/2fa/submit',
    name: 'contractDocumentSign2FASubmit',
    component: () => import('@/features/contract/views/ContractDocumentSign2FASubmit.vue'),
  },
  {
    path: '/dashboard/workspace/:workspace_id/documents/:contractId/revision-list',
    name: 'contractDocumentRevisionList',
    component: () => import('@/features/contract/views/ContractDocumentRevisionList.vue'),
  },
];

routes.forEach((route) => {
  route.meta = {
    authRequired: true,
    layout: () => import('@/layouts/EmptyLayout.vue'),
  };
});

export default routes;
