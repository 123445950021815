import * as types from './mutation_types';

export default {
  [types.SET_DISPLAY_SIZES](state, sizes) {
    Object.assign(state.display, sizes);
  },
  [types.SET_SIGNATURE_COLOR](state, color) {
    state.signatureColor = color;
  },
  [types.SET_SIGNATURE_DATE](state, signatureDate) {
    state.signatureDate = signatureDate;
  },
  [types.SET_SIGNATURE_IMAGE](state, preloadedSignatureImage) {
    state.signatureImage = preloadedSignatureImage;
  },
  [types.SET_SIGNATURE_PLACE](state, signaturePlace) {
    state.signaturePlace = signaturePlace;
  },
  [types.SET_SIGNATURE_PROGRESS](state, signatureProgress) {
    state.signatureProgress = signatureProgress;
  },
  [types.RESET_SIGNATURE](state) {
    state.signatureDate = new Date();
    state.signaturePlace = null;
    state.signatureProgress = [];
  },
  [types.RESET_SIGNATURE_PROGRESS](state) {
    state.signatureProgress = [];
  },
};
