import router from '@/app/plugins/router';
import { isRoutePublicPage } from '@/services/helpers';

export async function automaticLogout() {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');

  const urlBeforeLogin = window.location.href;
  const authRequired = router.currentRoute.meta.authRequired || false;
  const isRoutePublic = isRoutePublicPage(router.currentRoute.name);

  if (urlBeforeLogin.indexOf('redirect_to') !== -1) {
    return;
  }

  if (authRequired || isRoutePublic) {
    return;
  }

  return router.push({
    name: router.currentRoute.query['loginPage'] ? `${router.currentRoute.query['loginPage']}-login` : 'login',
    query: {
      redirect_to: urlBeforeLogin,
    },
  });
}
