<template>
  <v-btn
    :color="color"
    x-large
    @click="$emit('click')"
  >
    {{ title }}
  </v-btn>
</template>

<script>
export default {
  name: 'SBtn',
  props: {
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style lang="scss" scoped></style>
