var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-box__header__signs"},[_c('div',{staticClass:"content-box__header__back"},[(_vm.isAllSignaturesPlaced)?_c('span',[_c('s-help',{attrs:{"message":_vm.$t('info.document.sign_boxes')}}),_vm._v(" "+_vm._s(_vm.$t('contract.sign_boxes_for_placement'))+": ")],1):_vm._e(),(_vm.isEditSignaturesForced)?_c('v-btn',{staticClass:"ml-auto mr-0",attrs:{"color":"primary","x-large":"","block":_vm.$vuetify.breakpoint.mobile},on:{"click":function($event){return _vm.$emit('continueToSignButtons')}}},[_vm._v(" "+_vm._s(_vm.$t('contract.continue_to_signature'))+" ")]):_vm._e()],1),_c('div',{staticClass:"sign-group__wrapper"},[_c('div',{staticClass:"sign-group__container"},_vm._l((_vm.unsignedSignatures),function(signatureGroup,index){return _c('div',{key:index,staticClass:"sign-group",class:{ 'signature-group-empty': !signatureGroup }},[(signatureGroup && !signatureGroup.every((signature) => signature.is_placed))?_c('div',{staticClass:"sign-group__header"},[_vm._v(" "+_vm._s(index === 0 ? _vm.$t('contract.parties.proposer2') : _vm.$t('contract.parties.counterparties2', { partyOrder: index }))+" ")]):_vm._e(),_c('div',{staticClass:"sign-group-all"},_vm._l((signatureGroup),function(signature){return _c('div',{key:signature.signIdentity.id,staticClass:"sign-group__inner"},[_c('Drag',{attrs:{"data":signature,"type":"signatures"}},[(!signature.is_placed)?_c('button',{class:{
                  'sign-button': true,
                  'sign-button--owner': signature.is_proposer_sign,
                  'sign-button--counter': !signature.is_proposer_sign,
                  proposer: signature.is_proposer_sign,
                  negotiator: !signature.is_proposer_sign,
                  active: _vm.activeSignPlacement === 'ownerSign',
                  inconsistent: signature.is_inconsistent,
                },attrs:{"type":"button","name":"button"},on:{"mouseup":function($event){return _vm.autoPlaceSignature(signature)}}},[_c('div',{staticClass:"sign-button__inner"},[_c('div',{staticClass:"sign-button__avatar",style:([
                      signature.signIdentity.avatar_url
                        ? {
                            'background-image': `url(${signature.signIdentity.avatar_url})`,
                          }
                        : { 'background-image': 'unset' },
                    ])},[(!signature.signIdentity.avatar_url)?_c('span',[_vm._v(" "+_vm._s(_vm.getSignInitials(signature))+" ")]):_vm._e()]),_c('div',{staticClass:"sign-button__content"},[_c('div',{staticClass:"sign-button__name"},[(signature.signIdentity.variable_position)?[_vm._v(" "+_vm._s(signature.signIdentity.variable_position)+" ")]:[_vm._v(" "+_vm._s(signature.firstname)+" "+_vm._s(signature.lastname)+" ")]],2),_c('div',{staticClass:"sign-button__desc"},[_vm._v(" "+_vm._s(signature.signIdentity.organization_name || _vm.$t('general.signature'))+" ")])]),_c('div',{staticClass:"sign-button__ico"},[_c('span',{staticClass:"icon-check"})])]),_c('div',{staticClass:"sign-button__handle"},[_c('IcoPlaceSignature')],1)]):_vm._e()])],1)}),0)])}),0)]),_c('div',{staticClass:"content-box__extended"},[_c('span',{staticClass:"sign__load-from-other-documents",on:{"click":_vm.openManageSignIdentities}},[_vm._v(" "+_vm._s(_vm.$t('contract.signs.edit'))+" ")]),_c('span',{staticClass:"sign__load-from-other-documents",attrs:{"title":_vm.$t('contract.signs.load_from_history')},on:{"click":function($event){return _vm.$emit('openSignaturePositionsModal')}}},[_vm._v(" "+_vm._s(_vm.$t('contract.signs.load_from_history2'))+" ")])]),_c('ContractSignIdentitiesManagement',{attrs:{"show-dialog":_vm.manageSignIdentities && !_vm.insertingSignatory,"unsigned-signatures":_vm.editedUnsignedSignatures},on:{"save-sign-identities":_vm.saveSignatoryIdentities,"remove-sign-identity":function($event){return _vm.editSignatoryIdentities($event)},"close-dialog":function($event){_vm.manageSignIdentities = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }