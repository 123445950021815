import i18n from '@/app/plugins/i18n';
import { authorizedApiRequest } from '@/services/ApiService';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useAppNotificationsStore = defineStore('appNotifications', () => {
  const list = ref([]);

  const notifications = computed(() => list.value);

  function fetchAppNotifications() {
    authorizedApiRequest({
      method: 'GET',
      endpoint: `/api/public/v1/notifications/${i18n.locale}`,
    }).then((resp) => {
      list.value = resp;
    });
  }

  return {
    notifications,
    fetchAppNotifications,
  };
});
