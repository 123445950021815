import { apiUrl } from '@/app/config/appEnvironment';
import router from '@/app/plugins/router';
import { authHeader } from '@/config/authHeader';
import { autologoutIfUnauthorized } from './helpers';

export const DataService = {
  getContracts,
  getCreditsReport,
  manageTagsOrFolders,
  assignTagsOrFolders,
};

function getContracts(query) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify(query),
  };
  return fetch(`${apiUrl}/api/v1/workspaces/${query.workspace_id}/contracts`, requestOptions).then(handleResponse);
}

function getCreditsReport(query) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify(query),
  };
  return fetch(`${apiUrl}/api/v1/workspaces/${query.workspace_id}/reports`, requestOptions).then(
    handleCsvOrJsonResponse,
  );

  function handleCsvOrJsonResponse(response) {
    if (query.format == 'csv') {
      return response.text();
    } else {
      return handleResponse(response);
    }
  }
}

function manageTagsOrFolders(request) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify(request),
  };
  return fetch(`${apiUrl}/api/v1/workspaces/${request.workspace_id}/tags`, requestOptions).then(handleResponse);
}

function assignTagsOrFolders({ workspace_id, folder_id, tags_ids, docs_ids }) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader() },
    body: JSON.stringify({
      folder_id,
      tags_ids,
      docs_ids,
    }),
  };
  return fetch(`${apiUrl}/api/v1/workspaces/${workspace_id}/tags`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 404) {
        router.push({ name: 'notFound' });
        return;
      }

      autologoutIfUnauthorized(response);

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
