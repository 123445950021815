<template lang="html">
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
    <title>ico/ico-9-dokumenty.gray</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
      id="ico/ico-9-dokumenty.gray"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="ico/ico-9-dokumenty-purple"
        fill-rule="nonzero"
        stroke="#B4C1D6"
        stroke-width="2"
      >
        <path
          d="M6,7.33696048 C6,15.621828 6,19.9412634 6,20.2952669 C6,20.8262722 6.40545259,21 6.87777523,21 C7.35009787,21 16.8136914,21 17.3302414,21 C17.8467914,21 18,20.6487424 18,20.0652492 C18,19.4817559 18,4.23816732 18,3.77908225 C18,3.31999718 17.7227855,3 17.1761864,3 C16.8117869,3 14.5133758,3 10.280953,3 L6,7.33696048 Z"
          id="Path-10"
          transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "
        ></path>
        <path
          d="M13,9 C15.5522135,9 17.026748,9 17.4236035,9 C18.0188867,9 17.9999321,8.29587898 17.9999321,7.37479044 C17.9999321,6.76073141 17.9999321,5.63580127 17.9999321,4"
          id="Path-16"
          transform="translate(15.500000, 6.500000) scale(-1, 1) translate(-15.500000, -6.500000) "
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoMenuPage',
};
</script>
