import { defineStore } from 'pinia';

// TODO - this store will replace signature vuex
export const useSignatureStore = defineStore('signature', {
  state: () => ({
    textSignatureInput: '',
    signatureUrl: '',
    textSignatureCanvasDims: {},
  }),
  actions: {
    setTextSignatureInput(input) {
      this.textSignatureInput = input;
    },
    setSignatureDataUrl(url) {
      this.signatureUrl = url;
    },
    setTextSignatureCanvasDims(dimensions) {
      this.textSignatureCanvasDims = dimensions;
    },
    resetTextSignature() {
      this.textSignatureInput = '';
      this.signatureUrl = '';
    },
  },
  getters: {
    isTextSignatureInputValid: (state) => {
      return state.textSignatureInput.length >= 2 && state.textSignatureInput.length < 50;
    },
    getSignatureDataUrl: (state) => {
      return state.signatureUrl;
    },
    getTextSignatureInput: (state) => {
      return state.textSignatureInput;
    },
    getTextSignatureCanvasWidth: (state) => {
      return state.textSignatureCanvasDims.width;
    },
    getTextSignatureCanvasHeight: (state) => {
      return state.textSignatureCanvasDims.height;
    },
  },
});
